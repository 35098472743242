import {
    Dispatch,
    FC,
    SetStateAction,
    Suspense,
    memo,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
    Popover,
} from 'antd';

import AudioOutlined from '@ant-design/icons/AudioOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import AuditOutlinedIcon from 'shared/assets/icons/auditOutlined.svg';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { CustomUploadFile } from 'shared/types/file';
import { EPostTypeMessage } from 'shared/types/message';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Flex } from 'shared/ui/flex/Flex';
import { FullscreenContainer } from 'shared/ui/fullscreen-container/FullscreenContainer';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { Text } from 'shared/ui/text/Text';

import { getEditMessagePostType } from '../model/selectors/edit-message.selectors';

import { EditMessageContentAsync } from './EditMessageContent.async';
import { HintTypePost } from './hint-type-post/HintTypePost';
import { PreviewMessageWrapper } from './preview-message-wrapper/PreviewMessageWrapper';
import { SaveMessage } from './save-message/SaveMessage';

import cls from './EditMessage.module.scss';

import './EditMessage.scss';

interface IEditMessageProps {
    open: boolean;
    toggleModal: () => void;
    handleReloadChannels: () => void;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
}

export const EditMessage: FC<IEditMessageProps> = memo((props) => {
    const {
        handleReloadChannels, open, setIsUpdateChannels, toggleModal,
    } = props;
    const postType = useSelector(getEditMessagePostType);

    const { isTablet } = useScreenType();

    const [localFileList, setLocalFileList] = useState<CustomUploadFile[]>([]);

    const hintIcon = useMemo(() => {
        if (postType === EPostTypeMessage.VOICE_MESSAGE) {
            return <AudioOutlined className={cls.hintIcon} />;
        }

        if (postType === EPostTypeMessage.ROUND_VIDEO) {
            return <SmileOutlined className={cls.hintIcon} />;
        }

        if (postType === EPostTypeMessage.POLL) {
            return <UnorderedListOutlined className={cls.hintIcon} />;
        }

        return <AuditOutlinedIcon className={cls.hintIconCustom} />;
    }, [postType]);

    const handleCancelModal = useCallback(() => {
        toggleModal();
        setLocalFileList([]);
    }, [toggleModal]);

    if (!open) {
        return null;
    }

    if (isTablet && open) {
        return (
            <FullscreenContainer>
                <DynamicHeader
                    className={cls.header}
                    leftBlock={(
                        <LeftOutlined
                            className={cls.backIcon}
                            onClick={handleCancelModal}
                        />
                    )}
                    title="Редактирование сообщения"
                />
                <Flex
                    className={cls.contentWrapper}
                    gaps="16"
                    max
                    direction="column"
                    align="start"
                >
                    <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                        <EditMessageContentAsync setLocalFileList={setLocalFileList} localFileList={localFileList} />

                        <Flex className={cls.btnsModalControl} max gaps="8">
                            <Button size="large" block key="back" onClick={handleCancelModal}>
                                Отменить
                            </Button>

                            <SaveMessage
                                key="save"
                                cancelModal={toggleModal}
                                handleReloadChannels={handleReloadChannels}
                                setIsUpdateChannels={setIsUpdateChannels}
                                isTablet={isTablet}
                            />

                        </Flex>
                    </Suspense>
                </Flex>
            </FullscreenContainer>
        );
    }

    return (
        <FullscreenContainer className={cls.container}>
            <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                <div className={cls.wrapper}>
                    <div className={cls.holderContent}>
                        <div className={cls.holderHeader}>
                            <div className={cls.holderHeaderTitle}>
                                <Text text="Редактирование публикации" as="h1" weight="600" size="xl" />
                                <Popover
                                    content={<HintTypePost type={postType} />}
                                    placement="bottomLeft"
                                    trigger="hover"
                                    rootClassName={cls.popover}
                                >
                                    <Button icon={hintIcon} />
                                </Popover>
                            </div>
                            <CloseOutlined className={cls.closeIcon} onClick={handleCancelModal} />
                        </div>

                        <EditMessageContentAsync localFileList={localFileList} setLocalFileList={setLocalFileList} />
                        <div className={cls.holderButton}>
                            <Button key="back" onClick={handleCancelModal} size="large">
                                Отменить
                            </Button>
                            <SaveMessage
                                key="save"
                                cancelModal={toggleModal}
                                handleReloadChannels={handleReloadChannels}
                                setIsUpdateChannels={setIsUpdateChannels}
                            />
                        </div>
                    </div>
                    <div className={cls.holderPreview}>
                        <PreviewMessageWrapper />
                    </div>
                </div>
            </Suspense>
        </FullscreenContainer>
    );
});
