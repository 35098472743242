import {
    FC,
    useCallback,
    useMemo,
    useRef,
} from 'react';

import { IArgsContentViewer } from 'features/content-viewer';
import { useThrottle } from 'shared/lib/hooks/useThrottle/useThrottle';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { CustomUploadFile } from 'shared/types/file';
import { IFile } from 'shared/types/message';
import { ImageWithLoader } from 'shared/ui/image-loader/ImageWithLoader';

import cls from './ImageMessage.module.scss';

interface IImageMessageProps {
    file: CustomUploadFile | IFile;
    webPreviewClass?: string;
    openPreviewVideoOrPhoto?: (props: IArgsContentViewer) => void;
    isPreview?: boolean;
}

const THROTTLE_DELAY = 750;

export const ImageMessage: FC<IImageMessageProps> = (props) => {
    const {
        file, isPreview, openPreviewVideoOrPhoto, webPreviewClass,
    } = props;

    const imageRef = useRef<HTMLImageElement>(null);
    const fileObj = (file as CustomUploadFile)?.originFileObj;

    const imageSrc = useMemo(() => {
        let src = '';

        if (file?.file) {
            src = file?.file;
        }

        if (fileObj) {
            src = URL.createObjectURL(fileObj);
        }

        return src;
    }, [file]);

    const openImage = useThrottle(
        useCallback(
            (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                e.stopPropagation();

                if (imageRef.current && file.id && file.file && !isPreview) {
                    const rect = imageRef.current.getBoundingClientRect();
                    openPreviewVideoOrPhoto?.({
                        contentType: 'photo',
                        coordinates: {
                            height: rect.height,
                            width: rect.width,
                            x: rect.left,
                            y: rect.top,
                        },
                        fileId: file.id,
                        fileSrc: file.file,
                    });
                }
            },
            [imageRef, file, isPreview],
        ),
        THROTTLE_DELAY,
    );

    return (
        <ImageWithLoader
            ref={imageRef}
            data-id={file.id}
            className={classNames(cls.imageWrapper, {}, [webPreviewClass])}
            classNameImage={cls.imageItem}
            onContextMenu={(e) => e.preventDefault()}
            onClick={openImage}
            classNameSkeleton={cls.skeletonImage}
            src={imageSrc}
            alt={file?.name || 'image'}
        />
    );
};
