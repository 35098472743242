import {
    FC,
    memo,
} from 'react';

import {
    EPostTypeMessage,
    IEditMessage,
    TMessage,
} from 'shared/types/message';

import { HolderFileLoader } from '../holder-file-loader/HolderFileLoader';
import { AnimationStickerMessage } from '../variants/animation-sticker/AnimationStickerMessage';
import { PollMessage } from '../variants/poll-message/PollMessage';
import { RoundVideo } from '../variants/round-video/RoundVideo';
import { StaticSticker } from '../variants/static-sticker/StaticSticker';
import { VideoSticker } from '../variants/video-sticker/VideoSticker';
import { VoiceMessage } from '../variants/voice-message/VoiceMessage';

import cls from './ContentTypeRenderer.module.scss';

interface IContentTypeRendererProps {
    message: TMessage | IEditMessage;
    timezone?: string;
    isPreview?: boolean;
}

export const ContentTypeRenderer: FC<IContentTypeRendererProps> = memo((props) => {
    const {
        isPreview,
        message,
        timezone,
    } = props;
    const postFile = message?.post?.file;
    const postPoll = message.postType === EPostTypeMessage.POLL && message?.post?.poll;
    const type = message.postType;

    return (
        <>
            {type === EPostTypeMessage.CUSTOM_STICKER && postFile && <StaticSticker message={message} file={postFile} />}
            {type === EPostTypeMessage.VOICE_MESSAGE && postFile
            && (
                <HolderFileLoader file={postFile} isPreview={isPreview}>
                    <div className={cls.wrapperAudio}>
                        <VoiceMessage
                            file={postFile}
                            messageId={message.id}
                            date={message.publishAt}
                            timezone={timezone}
                            isPreview={isPreview}
                        />
                    </div>
                </HolderFileLoader>
            )}
            {type === EPostTypeMessage.VIDEO_STICKER && postFile && <VideoSticker message={message} file={postFile} />}
            {type === EPostTypeMessage.STATIC_STICKER && postFile && <StaticSticker message={message} file={postFile} />}
            {type === EPostTypeMessage.ANIMATED_STICKER && postFile && <AnimationStickerMessage file={postFile} />}
            {type === EPostTypeMessage.ROUND_VIDEO && postFile && (
                <RoundVideo
                    file={postFile}
                    messageId={message.id}
                    isPreview={isPreview}
                />
            )}
            {postPoll && <PollMessage postPoll={postPoll} />}
        </>
    );
});
