import dayjs, { Dayjs } from 'dayjs';

import 'dayjs/locale/ru';
import { INVALID_DATE } from 'shared/constants/date';
import {
    capitalizeMonth,
    formatDate,
} from 'shared/lib/utils/date/date';
import { TMessage } from 'shared/types/message';

export const formatMessageDate = (publishAt: Dayjs, timezone?: string) => {
    const date = formatDate(publishAt, 'DD MMMM YYYY', timezone);

    return capitalizeMonth(date);
};

export const checkSubsequentIndexNextDay = (
    publishAt: Dayjs,
    index: number,
    array: TMessage[],
    timezone?: string,
): boolean => {
    const nextMessage = array?.[index + 1];

    if (!nextMessage) return false;

    const currentPublishAt = dayjs(publishAt).tz(timezone).format('DD MMMM YYYY');
    const nextPublishAt = dayjs(nextMessage.publishAt).tz(timezone).format('DD MMMM YYYY');

    if (currentPublishAt === INVALID_DATE && nextPublishAt === INVALID_DATE) {
        return false;
    }

    return !dayjs(currentPublishAt).isSame(dayjs(nextPublishAt), 'day');
};
