import {
    Dispatch,
    FC,
    SetStateAction,
    Suspense,
    memo,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
    Popover,
} from 'antd';

import AudioOutlined from '@ant-design/icons/AudioOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import AuditOutlinedIcon from 'shared/assets/icons/auditOutlined.svg';
import { IChannel } from 'shared/types/channels';
import { CustomUploadFile } from 'shared/types/file';
import { EPostTypeMessage } from 'shared/types/message';
import { FullscreenContainer } from 'shared/ui/fullscreen-container/FullscreenContainer';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { Text } from 'shared/ui/text/Text';
import { HintTypePost } from 'widgets/edit-message/ui/hint-type-post/HintTypePost';

import { getCreateMessagePostType } from '../../../model/selectors/create-message.selectors';
import { CreateMessageContentAsync } from '../../content/create-message/CreateMessageContent.async';
import { PreviewMessageWrapper } from '../../preview-message-wrapper/PreviewMessageWrapper';
import { SaveMessage } from '../../save-message/SaveMessage';

import cls from './CreateMessageModal.module.scss';

interface ICreateMessageModalProps {
    open: boolean;
    handleReloadChannels: () => void;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
    localFileList: CustomUploadFile[];
    setLocalFileList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    channel: IChannel;
    handleCancelModal: () => void;
}

export const CreateMessageModal: FC<ICreateMessageModalProps> = memo((props) => {
    const {
        channel,
        handleCancelModal,
        handleReloadChannels,
        localFileList,
        setIsUpdateChannels,
        setLocalFileList,
    } = props;

    const postType = useSelector(getCreateMessagePostType);

    const hintIcon = useMemo(() => {
        if (postType === EPostTypeMessage.VOICE_MESSAGE) {
            return <AudioOutlined className={cls.hintIcon} />;
        }

        if (postType === EPostTypeMessage.ROUND_VIDEO) {
            return <SmileOutlined className={cls.hintIcon} />;
        }

        if (postType === EPostTypeMessage.POLL) {
            return <UnorderedListOutlined className={cls.hintIcon} />;
        }

        return <AuditOutlinedIcon className={cls.hintIconCustom} />;
    }, [postType]);

    return (
        <FullscreenContainer className={cls.container}>
            <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                <div className={cls.wrapper}>
                    <div className={cls.holderContent}>
                        <div className={cls.holderHeader}>
                            <div className={cls.holderHeaderTitle}>
                                <Text text="Создание публикации" as="h1" weight="600" size="xl" />
                                {postType && (
                                    <Popover
                                        content={<HintTypePost type={postType} />}
                                        placement="bottomLeft"
                                        trigger="hover"
                                        rootClassName={cls.popover}
                                    >
                                        <Button icon={hintIcon} />
                                    </Popover>
                                )}
                            </div>
                            <CloseOutlined className={cls.closeIcon} onClick={handleCancelModal} />
                        </div>

                        <CreateMessageContentAsync
                            localFileList={localFileList}
                            setLocalFileList={setLocalFileList}
                        />
                        <div className={cls.holderButton}>
                            <Button key="back" onClick={handleCancelModal} size="large">
                                Отменить
                            </Button>
                            <SaveMessage
                                key="save"
                                cancelModal={handleCancelModal}
                                handleReloadChannels={handleReloadChannels}
                                setIsUpdateChannels={setIsUpdateChannels}
                                channel={channel}
                            />
                        </div>
                    </div>
                    <div className={cls.holderPreview}>
                        <PreviewMessageWrapper />
                    </div>
                </div>
            </Suspense>
        </FullscreenContainer>
    );
});
