import {
    HTMLAttributes,
    ReactNode,
    forwardRef,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';
import { HeadingType } from 'shared/types/types';

import {
    FlexAlignType,
    FlexDirectionType,
    FlexGapsType,
    FlexJustifyType,
} from './types/flex.types';

import cls from './Flex.module.scss';

const flexJustifyClasses: Record<FlexJustifyType, string> = {
    center: cls.justifyCenter,
    end: cls.justifyEnd,
    'space-between': cls.justifySpaceBetween,
    start: cls.justifyStart,
};
const flexAlignClasses: Record<FlexAlignType, string> = {
    center: cls.alignCenter,
    end: cls.alignEnd,
    initial: cls.alignInitial,
    start: cls.alignStart,
};
const flexDirectionClasses: Record<FlexDirectionType, string> = {
    column: cls.directionColumn,
    'column-reverse': cls.columnReverse,
    row: cls.directionRow,
};

const flexGapsClasses: Record<FlexGapsType, string> = {
    12: cls.gap12,
    16: cls.gap16,
    20: cls.gap20,
    24: cls.gap24,
    32: cls.gap32,
    4: cls.gap4,
    40: cls.gap40,
    6: cls.gap6,
    8: cls.gap8,
};

export interface IFlexProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    children: ReactNode;
    justify?: FlexJustifyType;
    align?: FlexAlignType;
    direction?: FlexDirectionType;
    max?: boolean;
    gaps?: FlexGapsType;
    as?: HeadingType;
}

export const Flex = forwardRef((props: IFlexProps, ref) => {
    const {
        align = 'center', as = 'div', children, className, direction, gaps,
        justify = 'start', max, ...otherProps
    } = props;

    const classes = [
        className,
        flexJustifyClasses[justify],
        flexAlignClasses[align],
        direction && flexDirectionClasses[direction],
        gaps && flexGapsClasses[gaps],
    ];

    const Tag = as;

    return (
        <Tag
            ref={ref as any}
            className={classNames(cls.Flex, { [cls.max]: max }, classes)}
            {...otherProps}
        >
            {children}
        </Tag>
    );
});
