import { FC } from 'react';

import { CustomUploadFile } from 'shared/types/file';
import { IFile } from 'shared/types/message';
import { DocumentFile } from 'shared/ui/document/DocumentFile';

interface IDocumentMessageProps {
    file: IFile | CustomUploadFile;
    isPreview?: boolean;
}

export const DocumentMessage: FC<IDocumentMessageProps> = (props) => {
    const { file, isPreview } = props;

    const isNotLoaded = !!file?.file;
    const size = ('size' in file) ? file.size : file.fileSize;

    const handleClick = () => {
        if (isPreview) {
            return;
        }

        window.open(file?.file, '_blank');
    };

    return (
        <DocumentFile
            isLoaded={isNotLoaded}
            fileName={file.name}
            size={size}
            onClick={handleClick}
            isPreview={isPreview}
        />
    );
};
