// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qdRXTQzo{padding:var(--p-1) var(--p-1-5);max-width:480px;width:100%;user-select:text}.dm08qROD{margin-top:var(--p-1)}.Z4uCUama{font:var(--font-md);overflow-wrap:break-word}.Z4uCUama p,.Z4uCUama span,.Z4uCUama b,.Z4uCUama em,.Z4uCUama a,.Z4uCUama del,.Z4uCUama u,.Z4uCUama pre,.Z4uCUama code{font-size:var(--font-size-md);line-height:var(--font-line-md)}.VJPG6JqI{padding-bottom:var(--p-1-5)}.EBdZnRwn{padding-top:0px}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/markup/Markup.module.scss"],"names":[],"mappings":"AAAA,UACI,+BAAA,CACA,eAAA,CACA,UAAA,CACA,gBAAA,CAGJ,UACI,qBAAA,CAGJ,UACI,mBAAA,CAEA,wBAAA,CAEA,uHASI,6BAAA,CACA,+BAAA,CAIR,UACI,2BAAA,CAGJ,UACI,eAAA","sourcesContent":[".holderText {\n    padding: var(--p-1) var(--p-1-5);\n    max-width: 480px;\n    width: 100%;\n    user-select: text;  \n}\n\n.holderTextWithMediaPhotoVideo {\n    margin-top: var(--p-1);\n}\n\n.text {\n    font: var(--font-md);\n\n    overflow-wrap: break-word;\n\n    p,\n    span,\n    b,\n    em,\n    a,\n    del,\n    u,\n    pre,\n    code {\n        font-size: var(--font-size-md);\n        line-height: var(--font-line-md);\n    }\n}\n\n.textWebPreview {\n    padding-bottom: var(--p-1-5);\n}\n\n.holderTextWithVoiceOrAudio {\n    padding-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderText": `qdRXTQzo`,
	"holderTextWithMediaPhotoVideo": `dm08qROD`,
	"text": `Z4uCUama`,
	"textWebPreview": `VJPG6JqI`,
	"holderTextWithVoiceOrAudio": `EBdZnRwn`
};
export default ___CSS_LOADER_EXPORT___;
