import {
    Dispatch,
    FC,
    SetStateAction,
    memo,
    useEffect,
    useState,
} from 'react';

import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { useScrollMessage } from 'shared/lib/hooks/useScrollMessage/useScrollMessage';
import { IChannel } from 'shared/types/channels';

import { ChannelPageHeader } from './variants/channel-page-header/ChannelPageHeader';
import { ChannelPageMobileHeader } from './variants/channel-page-mobile-header/ChannelPageMobileHeader';

interface IChannelPageHeaderProps {
    className?: string;
    channel?: IChannel;
    channelId: IChannel['id'];
    isLoading: boolean;
    channelName: IChannel['name'];
    handleOpenNotification: () => void;
    setIsShowFooterMobile: Dispatch<SetStateAction<boolean>>;
    toggleCreateModal: () => void;
}

export const ChannelPageHeaderWrapper: FC<IChannelPageHeaderProps> = memo((props) => {
    const {
        channel,
        channelId,
        channelName,
        className,
        handleOpenNotification,
        isLoading,
        setIsShowFooterMobile,
        toggleCreateModal,
    } = props;

    const {
        isMobile, isTablet,
    } = useScreenType();

    const { handleShowMessage } = useScrollMessage({
        channelId,
    });

    const [isShowSearchContent, setIsShowSearchContent] = useState(false);

    useEffect(() => {
        setIsShowFooterMobile(isMobile && isShowSearchContent);
    }, [isMobile, isShowSearchContent]);

    useEffect(() => {
        if (channelId) {
            setIsShowSearchContent(false);
        }
    }, [channelId]);

    if (isTablet) {
        return (
            <ChannelPageMobileHeader
                channelName={channelName}
                channel={channel}
                channelId={channelId}
                isLoading={isLoading}
                handleOpenNotification={handleOpenNotification}
                isShowSearchContent={isShowSearchContent}
                setIsShowSearchContent={setIsShowSearchContent}
                handleShowMessage={handleShowMessage}
                toggleCreateModal={toggleCreateModal}
            />
        );
    }

    return (
        <ChannelPageHeader
            channel={channel}
            channelId={channelId}
            channelName={channelName}
            className={className}
            isLoading={isLoading}
            isShowSearchContent={isShowSearchContent}
            setIsShowSearchContent={setIsShowSearchContent}
            handleShowMessage={handleShowMessage}
            toggleCreateModal={toggleCreateModal}
        />
    );
});
