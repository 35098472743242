import {
    FC,
    memo,
    useCallback,
    useRef,
    useEffect,
    ReactNode,
    RefObject,
    SetStateAction,
    Dispatch,
} from 'react';

import { Dropdown } from 'antd';

import useLongPress from 'shared/lib/hooks/useLongPress/useLongPress';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { TMessage } from 'shared/types/message';

import { ControlsMessage } from '../controls/ControlsMessage';

import cls from './HolderContentMessage.module.scss';

interface IHolderContentMessageProps {
    children: ReactNode;
    handleToggleModalOnDelete: () => void;
    holderContentRef: RefObject<Element>;
    message: TMessage;
    onEditMessage: (mes: TMessage) => void;
    setVisiblePopover: Dispatch<SetStateAction<boolean>>;
    timezone?: string;
    visiblePopover: boolean;
    handleTogglePopover: () => void;
    isDisabledPopover: boolean;
    setIsResend: Dispatch<SetStateAction<boolean>>;
    isSent: boolean;
    hasErrors: boolean;
}

export const HolderContentMessage: FC<IHolderContentMessageProps> = memo((props) => {
    const {
        children,
        handleToggleModalOnDelete,
        handleTogglePopover,
        hasErrors,
        holderContentRef,
        isDisabledPopover,
        isSent,
        message,
        onEditMessage,
        setIsResend,
        setVisiblePopover,
        timezone,
        visiblePopover,
    } = props;

    const emptyBlockRef = useRef<HTMLButtonElement>(null);

    const handleOpenPopover = useCallback(() => {
        if (isDisabledPopover) {
            return;
        }

        setVisiblePopover(true);
    }, [isDisabledPopover]);

    useLongPress({ onLongPress: handleOpenPopover, ref: emptyBlockRef });
    useLongPress({ onLongPress: handleOpenPopover, ref: holderContentRef });

    const handleClickOutside = useCallback((e: MouseEvent) => {
        if (
            emptyBlockRef.current
            && !emptyBlockRef.current.contains(e.target as Node)
            && holderContentRef.current
            && !holderContentRef.current.contains(e.target as Node)
        ) {
            setVisiblePopover(false);
        }
    }, []);

    const handleCancelPopover = useCallback(() => {
        setVisiblePopover(false);
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        document.addEventListener('contextmenu', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
            document.removeEventListener('contextmenu', handleClickOutside, false);
        };
    }, []);

    const Controls = useCallback(
        () => (
            <ControlsMessage
                onEditMessage={onEditMessage}
                handleToggleModalOnDelete={handleToggleModalOnDelete}
                message={message}
                timezone={timezone}
                handleCancelPopover={handleCancelPopover}
                className={cls.mobileControls}
                setIsResend={setIsResend}
                isSent={isSent}
                hasErrors={hasErrors}
            />
        ),
        [
            onEditMessage,
            handleToggleModalOnDelete,
            handleCancelPopover,
            message,
            timezone,
            isSent,
            hasErrors,
        ],
    );

    useEffect(() => {
        const handleTouchMove = (event: TouchEvent) => {
            event.preventDefault();
        };

        if (visiblePopover) {
            document.addEventListener('touchmove', handleTouchMove, { passive: false });
        } else {
            document.removeEventListener('touchmove', handleTouchMove);
        }

        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [visiblePopover]);

    return (
        <>
            {visiblePopover && <div className={cls.overlay} />}
            <Dropdown
                open={visiblePopover}
                dropdownRender={Controls}
                trigger={['contextMenu', 'click']}
            >
                <div>
                    <button
                        type="button"
                        className={classNames(cls.emptyBlock, { [cls.selectMessage]: visiblePopover })}
                        onClick={handleTogglePopover}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            handleTogglePopover();
                        }}
                        aria-label="Empty Block"
                        ref={emptyBlockRef}
                    />
                    {children}
                </div>
            </Dropdown>
        </>
    );
});
