import {
    transformBodyGenerateEmployeeLink,
    transformGenerateEmployeeLink,
    transformGetEmployees,
    transformGetEmployeesList,
} from 'entities/employee';
import { IRole } from 'entities/role';
import { api } from 'shared/config/rtk-query-api/api.services';
import { getQueryParam } from 'shared/lib/utils/validUrl/validUrl';
import {
    DeepPartial,
    IBackendListResponse,
} from 'shared/types/types';

import {
    IEmployee,
    IGenerateEmployeeLink,
} from '../types/employee.types';

interface IGetEmployeesArgs {
    offset?: number;
    limit?: number;
    search?: string;
}

interface IUpdateEmployeeRoleArgs {
    role?: DeepPartial<IRole>;
    id?: IRole['id'];
}

const employeeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        generateEmployeeLink: builder.mutation<IGenerateEmployeeLink, IRole['id']>({
            query: (id) => ({
                body: transformBodyGenerateEmployeeLink(id),
                method: 'POST',
                url: '/api/v1/roles/get-invite-link/',
            }),
            transformResponse: transformGenerateEmployeeLink,
        }),
        getEmployees: builder.query<IBackendListResponse<IEmployee>, IGetEmployeesArgs>({
            providesTags: () => (['Employees']),
            query: ({ limit, offset, search }) => {
                const query = getQueryParam({ limit, offset, search });
                return { url: `/api/v1/users/${query}` };
            },
            transformResponse: transformGetEmployeesList,
        }),
        updateEmployeeRole: builder.mutation<IEmployee, IUpdateEmployeeRoleArgs>({
            invalidatesTags: ['Employees', 'Roles', 'Role', 'UserData', 'Channels'],
            query: ({ id, role }) => ({ body: { role: role?.id }, method: 'PATCH', url: `/api/v1/users/${id}/` }),
            transformResponse: transformGetEmployees,
        }),
    }),
});

export const {
    useGenerateEmployeeLinkMutation,
    useGetEmployeesQuery,
    useUpdateEmployeeRoleMutation,
} = employeeApi;
