import { api } from 'shared/config/rtk-query-api/api.services';
import { getQueryParam } from 'shared/lib/utils/validUrl/validUrl';
import { TMessage } from 'shared/types/message';
import { IListResponse } from 'shared/types/types';

import {
    transformGetMessage,
    transformGetMessages,
    transformGetMessagesSearch,
} from '../../adapters/serverData';
import {
    IResponseResendPost,
    ISearchMessage,
    IGetMessagesSearchArgs,
} from '../types/message.types';

interface IGetMessagesArgs {
    channelId: TMessage['id'];
    query: string;
    sessionId?: number;
}

interface IGetCheckPostPublishStatusArgs {
    id: TMessage['id'];
}

interface IResendPostArgs {
    id: TMessage['id'];
}

const messagesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        checkPostPublishStatus: builder.query<void, IGetCheckPostPublishStatusArgs>({
            query: ({ id }) => ({ method: 'GET', url: `/api/v1/posts/${id}/resend/` }),
        }),
        getMessage: builder.query<TMessage, { messageId: TMessage['id'] }>({
            query: ({ messageId }) => ({
                method: 'GET',
                url: `/api/v1/posts/${messageId}/`,
            }),
            transformResponse: transformGetMessage,
        }),
        getMessages: builder.query<IListResponse<TMessage>, IGetMessagesArgs>({
            providesTags: () => [{ type: 'Messages' }],
            query: ({ channelId, query }) => ({ url: `/api/v1/posts/?${query || `channel__id=${channelId}`}` }),
            transformResponse: transformGetMessages,
        }),
        getMessagesSearch: builder.query<IListResponse<ISearchMessage>, IGetMessagesSearchArgs>({
            query: ({
                channelId, cursor, date, search,
            }) => {
                const query = channelId ? getQueryParam({
                    channel__id: channelId, cursor, date, search,
                }) : '';

                return { url: `/api/v1/posts_search/${query}` };
            },
            transformResponse: transformGetMessagesSearch,
        }),
        removeMessage: builder.mutation<void, { messageId: TMessage['id'] }>({
            query: ({ messageId }) => ({
                method: 'DELETE',
                url: `/api/v1/posts/${messageId}/`,
            }),
        }),
        resendPost: builder.mutation<IResponseResendPost, IResendPostArgs>({
            query: ({ id }) => ({ method: 'POST', url: `/api/v1/posts/${id}/resend/` }),
        }),
    }),
});

export const {
    useGetMessagesQuery,
    useLazyCheckPostPublishStatusQuery,
    useLazyGetMessageQuery,
    useLazyGetMessagesQuery,
    useLazyGetMessagesSearchQuery,
    useRemoveMessageMutation,
    useResendPostMutation,
} = messagesApi;
