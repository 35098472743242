import {
    FC,
    MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import PauseOutlined from '@ant-design/icons/PauseOutlined';
import {
    audioFileActions,
    getAudioFileIsPlaying,
    getAudioIsEnded,
    getAudioMessageId,
    getAudioName,
    getAudioTimeRemaining,
} from 'features/global-audio-channel';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getNearestAbbreviatedAudioDate,
    getTimeMmSSFromNumber,
} from 'shared/lib/utils/date/date';
import { CustomUploadFile } from 'shared/types/file';
import {
    IDefaultMessage,
    IFile,
} from 'shared/types/message';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import cls from './AudioMessage.module.scss';

interface IAudioMessageProps {
    className?: string;
    file: IFile | CustomUploadFile;
    messageId: string;
    date: IDefaultMessage['publishAt'];
    timezone?: string;
    isPreview?: boolean;
}

export const AudioMessage: FC<IAudioMessageProps> = (props) => {
    const {
        className,
        date,
        file,
        isPreview,
        messageId,
        timezone,
    } = props;

    const dispatch = useAppDispatch();

    const isPlaying = useSelector(getAudioFileIsPlaying);
    const globalAudioMessageId = useSelector(getAudioMessageId);
    const isEnded = useSelector(getAudioIsEnded);
    const timeRemaining = useSelector(getAudioTimeRemaining);
    const audioName = useSelector(getAudioName);

    const [duration, setDuration] = useState(0);
    const [isLoad, setIsLoad] = useState(true);

    const audioFile = file?.file || (file as CustomUploadFile)?.originFileObj;

    const isPlayingCurrentAudio = useMemo(
        () => globalAudioMessageId === messageId && file.name === audioName,
        [
            globalAudioMessageId,
            messageId,
            file.name,
            audioName,
        ],
    );

    const timeRender = useMemo(() => {
        if (!audioFile) {
            return 'Загрузка...';
        }

        if (isLoad || duration === 0 || !Number.isFinite(duration)) {
            return <Skeleton width="36px" height="11px" className={cls.skeleton} />;
        }

        if (!isPlayingCurrentAudio || isEnded || !timeRemaining) {
            return getTimeMmSSFromNumber(duration);
        }

        return timeRemaining;
    }, [
        isLoad,
        duration,
        isPlayingCurrentAudio,
        isEnded,
        timeRemaining,
        audioFile,
    ]);

    const playPauseOrClockIcon = useMemo(() => {
        if (isPlaying && isPlayingCurrentAudio) {
            return <PauseOutlined className={cls.playPause} />;
        }

        return <CaretRightOutlined className={cls.playPause} />;
    }, [file, isPlaying, isPlayingCurrentAudio]);

    const classesBtn = classNames(cls.playBtn, {
        [cls.playing]: isPlayingCurrentAudio,
        [cls.withoutCursor]: !file?.file || isPreview,
    });

    const handleOnAudioClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        if (!file?.file || isPreview) {
            return;
        }

        e.stopPropagation();

        if (!messageId || messageId !== globalAudioMessageId || file.name !== audioName) {
            dispatch(audioFileActions.setChangedTime(null));
            dispatch(audioFileActions.setAudioFile(file));
            dispatch(audioFileActions.setMessageId(messageId));
            dispatch(audioFileActions.setIsEnded(false));
            dispatch(audioFileActions.setWidthWave(0));
            dispatch(audioFileActions.updateName(file.name));
            dispatch(audioFileActions.setTimeRemaining(''));
            dispatch(audioFileActions.setDate(getNearestAbbreviatedAudioDate(date || '', timezone)));
            return;
        }

        if (isEnded) {
            dispatch(audioFileActions.setIsEnded(false));
            dispatch(audioFileActions.setWidthWave(0));
        }

        dispatch(audioFileActions.togglePlayingAudio(!isPlaying));
    }, [messageId, globalAudioMessageId, file, isPlaying, isEnded, isPreview, audioName]);

    useEffect(() => {
        let fileUrl = '';
        let audio: HTMLAudioElement;
        const fileObj = (file as CustomUploadFile).originFileObj;
        if (fileObj) {
            fileUrl = URL.createObjectURL(fileObj);
            audio = new Audio(fileUrl);
        } else {
            audio = new Audio(file.file);
        }

        const setAudioDuration = () => {
            setIsLoad(false);
            setDuration(audio.duration);
        };

        audio.addEventListener('loadedmetadata', setAudioDuration);
        audio.addEventListener('loadeddata', setAudioDuration);

        return () => {
            audio.removeEventListener('loadedmetadata', setAudioDuration);
            audio.removeEventListener('loadeddata', setAudioDuration);
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [file]);

    return (
        <div className={classNames(cls.audioMessage, {}, [className])}>
            <button
                onClick={handleOnAudioClick}
                type="button"
                className={classesBtn}
            >
                {playPauseOrClockIcon}
            </button>

            <div className={cls.audioRoad}>
                <p className={cls.name}>
                    {file.name || <Skeleton width="173px" height="22px" />}
                </p>
                <p className={cls.time}>
                    {timeRender}
                </p>
            </div>
        </div>
    );
};
