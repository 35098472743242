import {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'antd';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import {
    ChannelsList,
    channelActions,
    getChannelsFromSlice,
    useGetChannelsQuery,
    getNextUrlChannel,
    getPreviousUrlChannel,
    getSearchName,
} from 'entities/channels';
import {
    getIsWebappAuth,
    getStateIsChannelEdition,
} from 'entities/user';
import { AddChannelButton } from 'features/add-channel-btn';
import { HeaderMenuTablet } from 'features/header-menu-tablet';
import { SearchChannels } from 'features/search-channels';
import { CREATE_CHANNEL_URL } from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getQueryParam,
    getQueryVariable,
} from 'shared/lib/utils/validUrl/validUrl';
import { EScrollTypes } from 'shared/types/message';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';

import cls from './Sidebar.module.scss';

interface ISidebarProps {
    className?: string;
    onScrollMessage?: () => void;
}

export const Sidebar: FC<ISidebarProps> = memo((props) => {
    const { className = '', onScrollMessage } = props;

    const { isMobile, isTablet } = useScreenType();
    const isWebappAuth = useSelector(getIsWebappAuth);

    const [isEmptyChannels, setIsEmptyChannels] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(false);
    const [queryCursor, setQueryCursor] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [isClearSearchValue, setIsClearSearchValue] = useState(false);
    const [typeScroll, setTypeScroll] = useState<EScrollTypes>(EScrollTypes.TOP);

    const searchValueRef = useRef('');

    const {
        data: channelsData,
        isFetching,
        isLoading: isLoadingChannels,
        status,
    } = useGetChannelsQuery(getQueryParam({ cursor: queryCursor, search: searchValue }));

    const channels = useSelector(getChannelsFromSlice.selectAll);
    const nextUrlChannel = useSelector(getNextUrlChannel);
    const prevUrlChannel = useSelector(getPreviousUrlChannel);
    const searchName = useSelector(getSearchName);
    const isChannelEditionRight = useSelector(getStateIsChannelEdition);

    const dispatch = useAppDispatch();

    const isLoading = useMemo(() => status === QueryStatus.pending || isLoadingChannels, [isLoadingChannels, status]);

    const addNewNextChannels = useCallback((isBottom: boolean) => {
        const isShouldAddNextChannels = isBottom && !isLoading && !isFetching && nextUrlChannel;
        if (isShouldAddNextChannels) {
            const nextChannelsParams = nextUrlChannel?.replace(/.*\?/gi, '');
            setTypeScroll(EScrollTypes.TOP);
            const cursor = getQueryVariable(nextChannelsParams)?.cursor;
            setQueryCursor(cursor);
        }
    }, [isLoading, isFetching, nextUrlChannel]);

    const addPrevNextChannels = useCallback((isTop: boolean) => {
        const isShouldAddPrevChannels = isTop && !isLoading && !isFetching && prevUrlChannel;
        if (isShouldAddPrevChannels) {
            const prevChannelsParams = prevUrlChannel?.replace(/.*\?/gi, '');
            setTypeScroll(EScrollTypes.BOTTOM);
            const cursor = getQueryVariable(prevChannelsParams)?.cursor;
            setQueryCursor(cursor);
        }
    }, [isLoading, isFetching, prevUrlChannel]);

    const onChangeSearchValue = useCallback((value: string) => {
        if (searchValueRef.current && !value) {
            setIsClearSearchValue(true);
        }
        dispatch(channelActions.setSearchName(value));
        searchValueRef.current = value;
        setSearchValue(value);
        setQueryCursor('');
    }, []);

    const isLoadingSearch = useMemo(
        () => (isLoading || isFetching) && (!!searchValue || isClearSearchValue),
        [isLoading, isFetching, searchValue, isClearSearchValue],
    );

    const rightBlock = useMemo(() => {
        if (isWebappAuth) return <div />;

        if (!isEmptyChannels && isChannelEditionRight) {
            return (
                <Link to={CREATE_CHANNEL_URL}>
                    <Button
                        block
                        type="default"
                        className={classNames('', { [cls.buttonPlusSM]: isMobile })}
                        onClick={() => setIsEmptyChannels(false)}
                        size="large"
                    >
                        {!isMobile ? 'Добавить канал' : <PlusOutlined className={cls.iconPlus} />}
                    </Button>
                </Link>
            );
        }

        return null;
    }, [isEmptyChannels, isChannelEditionRight, isWebappAuth, isMobile]);

    const SearchChannelsElement = <SearchChannels onChange={onChangeSearchValue} isLoading={isLoadingSearch} />;

    useEffect(() => {
        if (channelsData && channels.length === 0 && !searchValue) {
            dispatch(channelActions.setChannelsList(channelsData.results));

            if (channelsData.results.length === 0) {
                setIsEmptyChannels(true);
            }

            dispatch(channelActions.updateNextUrlChannel(channelsData.next));
            dispatch(channelActions.updatePreviousUrlChannel(channelsData.previous));
        }
    }, [channelsData]);

    useEffect(() => {
        if (channelsData && !isLoading && !isFetching && queryCursor) {
            dispatch(channelActions.addChannels(channelsData.results));

            if (typeScroll === EScrollTypes.TOP) {
                dispatch(channelActions.updateNextUrlChannel(channelsData.next));
            } else {
                dispatch(channelActions.updatePreviousUrlChannel(channelsData.previous));
            }
        }
    }, [channelsData, isLoading, isFetching, queryCursor]);

    useEffect(() => {
        const isUpdateChannels = !isLoading && !isFetching && !queryCursor && channelsData && (searchValue || isClearSearchValue);

        if (isUpdateChannels) {
            dispatch(channelActions.setChannelsList(channelsData.results));
            dispatch(channelActions.updateNextUrlChannel(channelsData.next));
            dispatch(channelActions.updatePreviousUrlChannel(channelsData.previous));
            setIsClearSearchValue(false);

            setIsEmptySearch(channelsData?.results.length === 0);
        }
    }, [queryCursor, searchValue, channelsData, isLoading, isFetching, isClearSearchValue]);

    useEffect(() => {
        setSearchValue(searchName);
        searchValueRef.current = searchName;
    }, []);

    if (isTablet) {
        return (
            <div className={cls.containerMobile}>
                <DynamicHeader
                    leftBlock={isWebappAuth ? <div /> : <HeaderMenuTablet />}
                    rightBlock={rightBlock}
                />
                <div className={cls.contentMobile}>
                    {SearchChannelsElement}
                    <ChannelsList
                        isLoading={isLoading}
                        channels={channels}
                        isEmptyChannels={isEmptyChannels}
                        addNewNextChannels={addNewNextChannels}
                        addPrevNextChannels={addPrevNextChannels}
                        isEmptySearch={isEmptySearch}
                    />
                </div>
                {isEmptyChannels && (
                    <AddChannelButton
                        isLoadingChannels={isLoading}
                        channels={channels}
                        className={cls.addBtnTablet}
                    />
                )}
            </div>
        );
    }

    return (
        <aside className={classNames(cls.sidebar, {}, [className])}>
            {SearchChannelsElement}
            <ChannelsList
                isLoading={isLoading}
                channels={channels}
                addNewNextChannels={addNewNextChannels}
                addPrevNextChannels={addPrevNextChannels}
                isEmptySearch={isEmptySearch}
                onScrollMessage={onScrollMessage}
            />
            <AddChannelButton
                isLoadingChannels={isLoading}
                channels={channels}
                searchValue={searchValue}
            />
        </aside>
    );
});
