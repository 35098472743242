import {
    Dispatch,
    FC,
    SetStateAction,
} from 'react';
import { Virtuoso } from 'react-virtuoso';

import { useThrottle } from 'shared/lib/hooks/useThrottle/useThrottle';
import {
    formatDate,
    getDayjsWithTimezonePlugin,
} from 'shared/lib/utils/date/date';
import { IChannel } from 'shared/types/channels';
import { TMessage } from 'shared/types/message';
import { INotification } from 'shared/types/notification';
import { IBackendListResponse } from 'shared/types/types';
import { Text } from 'shared/ui/text/Text';

import { useNotificationHandler } from '../../hooks/useNotificationHandler';
import { NotificationItem } from '../item/NotificationItem';

import cls from './NotificationList.module.scss';

const THROTTLE_DELAY = 750;

interface INotificationListProps {
    channelId: IChannel['id'];
    isFetching: boolean;
    isLoading: boolean;
    notificationFromServer?: IBackendListResponse<INotification>;
    setQueryCursor: Dispatch<SetStateAction<string>>;
    queryCursor: string;
    setEmptyNotifications: Dispatch<SetStateAction<boolean>>;
    emptyNotifications: boolean;
    handleShowMessage: (id: TMessage['id'], page?: string) => void;
    handleCloseNotificationsModal: () => void;
}

export const NotificationList: FC<INotificationListProps> = (props) => {
    const {
        channelId,
        emptyNotifications,
        handleCloseNotificationsModal,
        handleShowMessage,
        isFetching,
        isLoading,
        notificationFromServer,
        queryCursor,
        setEmptyNotifications,
        setQueryCursor,
    } = props;

    const { addNewNextNotifications, addNewPrevNotifications, notifications } = useNotificationHandler({
        channelId,
        isFetching,
        isLoading,
        notificationFromServer,
        queryCursor,
        setEmptyNotifications,
        setQueryCursor,
    });

    const isLoadingOrFetchingNotifications = isLoading || isFetching;
    const isEmptyNotificationsFromServer = notificationFromServer?.results.length === 0;

    const onScrollMessage = useThrottle((postId, page) => {
        handleCloseNotificationsModal();
        handleShowMessage(postId, page);
    }, THROTTLE_DELAY);

    const renderNotifications = (index: number, item: INotification) => {
        getDayjsWithTimezonePlugin();
        const date = formatDate(item.createdAt, 'HH:mm', undefined, true);
        return (
            <NotificationItem
                date={date}
                description={item.description}
                title={item.title}
                page={item.postPageUrl}
                postId={item.postId}
                handleShowMessage={onScrollMessage}
                index={index}
                channelId={channelId}
                isSettingRedirect={item.settingsRequired}
            />
        );
    };

    if (isLoadingOrFetchingNotifications && emptyNotifications) {
        return null;
    }

    if (emptyNotifications && isEmptyNotificationsFromServer) {
        return (
            <div className={cls.holderEmpty}>
                <Text
                    text="На выбранном канале нет активных уведомлений"
                    size="md"
                    className={cls.emptyText}
                />
            </div>
        );
    }

    return (
        <div className={cls.holderList}>
            <Virtuoso
                data={notifications}
                itemContent={renderNotifications}
                overscan={200}
                atTopStateChange={addNewPrevNotifications}
                atBottomStateChange={addNewNextNotifications}
            />
        </div>
    );
};
