import {
    FC,
    memo,
} from 'react';
import { useSelector } from 'react-redux';

import { PreviewMessage } from 'widgets/message-preview';

import {
    getCreateMessageTime,
    getCreateMessageDate,
    getCreateMessageTimezone,
    getCreateMessagePostType,
    getCreateMessageButtons,
    getCreateMessageIsWebPreview,
    getCreateMessageIsNotifyPin,
    getCreateMessageIsPinIndefinitely,
    getCreateMessagePinDuration,
    getCreateMessageText,
    getCreateMessageMedia,
    getCreateMessageDocuments,
    getCreateMessageRoundVideo,
    getCreateMessageVoice,
    getCreateMessageAudio,
    getCreateMessageVariables,
    getCreateMessageAnswersPoll,
    getCreateMessageMultipleChoicePoll,
    getCreateMessageQuestionPoll,
    getCreateMessageQuizPoll,
    getCreateMessageSolutionIndexPoll,
    getCreateMessageSolutionMessage,
    getCreateMessageAlbumType,
} from '../../model/selectors/create-message.selectors';

export const PreviewMessageWrapper: FC = memo(() => {
    const timeMinutes = useSelector(getCreateMessageTime);
    const date = useSelector(getCreateMessageDate);
    const timezone = useSelector(getCreateMessageTimezone);
    const postType = useSelector(getCreateMessagePostType);
    const buttons = useSelector(getCreateMessageButtons);
    const isWebPreview = useSelector(getCreateMessageIsWebPreview);
    const isNotifyPin = useSelector(getCreateMessageIsNotifyPin);
    const isPinIndefinitely = useSelector(getCreateMessageIsPinIndefinitely);
    const pinDuration = useSelector(getCreateMessagePinDuration);
    const text = useSelector(getCreateMessageText);
    const media = useSelector(getCreateMessageMedia);
    const docs = useSelector(getCreateMessageDocuments);
    const roundVideo = useSelector(getCreateMessageRoundVideo);
    const voice = useSelector(getCreateMessageVoice);
    const audio = useSelector(getCreateMessageAudio);
    const variables = useSelector(getCreateMessageVariables);
    const answersPoll = useSelector(getCreateMessageAnswersPoll);
    const multipleChoicePoll = useSelector(getCreateMessageMultipleChoicePoll);
    const questionPoll = useSelector(getCreateMessageQuestionPoll);
    const quizPoll = useSelector(getCreateMessageQuizPoll);
    const solutionIndexPoll = useSelector(getCreateMessageSolutionIndexPoll);
    const solutionMessage = useSelector(getCreateMessageSolutionMessage);
    const albumType = useSelector(getCreateMessageAlbumType);

    return (
        <PreviewMessage
            timeMinutes={timeMinutes}
            date={date}
            timezone={timezone}
            postType={postType}
            buttons={buttons}
            isWebPreview={isWebPreview}
            isNotifyPin={isNotifyPin}
            isPinIndefinitely={isPinIndefinitely}
            pinDuration={pinDuration}
            text={text}
            media={media}
            docs={docs}
            roundVideo={roundVideo}
            voice={voice}
            audio={audio}
            variables={variables}
            answersPoll={answersPoll}
            multipleChoicePoll={multipleChoicePoll}
            questionPoll={questionPoll}
            quizPoll={quizPoll}
            solutionIndexPoll={solutionIndexPoll}
            solutionMessage={solutionMessage}
            albumType={albumType}
        />
    );
});
