import {
    FC,
    memo,
    useMemo,
} from 'react';

import { Tooltip } from 'antd';

import { MAX_BUTTON_NAME_LENGTH } from 'shared/constants/message';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getHttpUrl,
    getValidUrl,
    isValidHttpUrl,
    validTGUrl,
} from 'shared/lib/utils/validUrl/validUrl';
import { IVariable } from 'shared/types/channels';
import { IMessageButton } from 'shared/types/message';
import { VariableTheme } from 'shared/ui/text/constants/variables';

import {
    EValueBtnType,
} from '../../model/types/message.types';

import cls from './MessageButtonList.module.scss';

interface IMessageButtonItemProps {
    buttonItem: IMessageButton;
    variables: IVariable[];
}

export const MessageButtonItem: FC<IMessageButtonItemProps> = memo(({ buttonItem, variables }) => {
    const { id, name, value } = buttonItem;

    if (value.type === EValueBtnType.URL) {
        const isError = name && name.length > MAX_BUTTON_NAME_LENGTH;

        return (
            <Tooltip title={isError && 'Превышен лимит текста'} color={isError ? VariableTheme.ERROR : ''} key={id}>
                <a
                    key={id}
                    href={getHttpUrl(value.data)}
                    target="_blank"
                    className={classNames(cls.button, { [cls.errorText]: isError })}
                    rel="noreferrer"
                >
                    {name}
                </a>
            </Tooltip>
        );
    }

    const dataTooltip = useMemo(() => {
        const currentVariable = variables?.find(({ name: varName }) => varName === value.data);

        if (currentVariable) {
            const isValidUrl = isValidHttpUrl(currentVariable.value) || validTGUrl(currentVariable.value);

            if (!isValidUrl) {
                return {
                    color: VariableTheme.ERROR,
                    href: '',
                    isError: true,
                    title: 'Некорректная ссылка',
                };
            }

            return {
                color: VariableTheme.PRIMARY,
                href: currentVariable.value,
                isError: false,
                title: currentVariable.value,
            };
        }

        return {
            color: VariableTheme.ERROR,
            href: '',
            isError: true,
            title: 'Несуществующая переменная',
        };
    }, [variables, value, name]);

    return (
        <Tooltip title={dataTooltip.title} color={dataTooltip.color} key={id}>
            <a
                href={getValidUrl(dataTooltip.href)}
                onClick={(e) => dataTooltip.isError && e.preventDefault()}
                target="_blank"
                className={classNames(
                    cls.button,
                    { [cls.errorText]: dataTooltip.isError },
                    [cls.buttonVariable, 'fontWithStickers'],
                )}
                rel="noreferrer"
            >
                {name}
            </a>
        </Tooltip>
    );
});
