import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {
    IArgsContentViewer,
    IContentViewerCoordinates,
    IContentViewerSchema,
} from '../types/content-viewer.types';

export const initialState: IContentViewerSchema = {
    contentType: null,
    coordinates: null,
    fileId: '',
    fileSrc: '',
    isOnLoad: false,
    isOpen: false,
};

export const contentViewerSlice = createSlice({
    initialState,
    name: 'contentViewer',
    reducers: {
        resetContentViewer(state) {
            state.contentType = null;
            state.coordinates = null;
            state.fileSrc = '';
            state.fileId = '';
        },
        setContentViewer(state, { payload }: PayloadAction<IArgsContentViewer>) {
            const {
                contentType, coordinates, fileId, fileSrc,
                isOnLoad = false,
            } = payload;

            state.isOpen = true;
            state.fileSrc = fileSrc;
            state.fileId = fileId;
            state.contentType = contentType;
            state.coordinates = coordinates;
            state.isOnLoad = isOnLoad;
        },
        toggleView(state, { payload: value }: PayloadAction<IContentViewerSchema['isOpen'] | undefined>) {
            state.isOpen = !!value ?? !state.isOpen;
        },
        updateCoordinates(state, { payload }: PayloadAction<IContentViewerCoordinates>) {
            state.coordinates = payload;
        },
    },
});

export const { reducer: contentViewerReducer } = contentViewerSlice;
export const { actions: contentViewerActions } = contentViewerSlice;
