import {
    FC,
    useMemo,
} from 'react';

import { POST_TYPES_STICKER } from 'shared/constants/message';
import { EPostTypeMessage } from 'shared/types/message';
import { Text } from 'shared/ui/text/Text';

import cls from './HintTypePost.module.scss';

interface IHintTypePostProps {
    type: EPostTypeMessage;
}

export const HintTypePost: FC<IHintTypePostProps> = (props) => {
    const { type } = props;

    const typeText = useMemo(() => {
        if (type === EPostTypeMessage.VOICE_MESSAGE) {
            return '«Голосовое сообщение»';
        }

        if (type === EPostTypeMessage.ROUND_VIDEO) {
            return '«Видео в кружочке»';
        }

        if (type === EPostTypeMessage.POLL) {
            return '«Опрос»';
        }

        if (POST_TYPES_STICKER.includes(type)) {
            return '«Стикер»';
        }

        return '«Текстовое сообщение»';
    }, [type]);

    const listText = useMemo(() => {
        if (type === EPostTypeMessage.VOICE_MESSAGE) {
            return [
                'Видеосообщения',
                'Вложения',
            ];
        }

        if (type === EPostTypeMessage.ROUND_VIDEO) {
            return [
                'Голосовое',
                'Вложения',
                'Кнопки',
            ];
        }

        if (type === EPostTypeMessage.POLL) {
            return [
                'Голосовое',
                'Видеосообщения',
                'Вложения',
                'Кнопки',
            ];
        }

        if (POST_TYPES_STICKER.includes(type)) {
            return [
                'Голосовое',
                'Видеосообщения',
                'Вложения',
                'Кнопки',
            ];
        }

        return [
            'Голосовое',
            'Видеосообщения',
        ];
    }, [type]);

    const listRender = useMemo(() => listText.map((item) => (
        <li>
            <Text text={item} theme="black" />
        </li>
    )), [listText]);

    return (
        <div className={cls.container}>
            <Text
                text={(
                    <>
                        В типе публикации
                        {' '}
                        <Text
                            text={typeText}
                            theme="blue"
                            weight="600"
                            as="span"
                            isTitle
                        />
                        {' '}
                        вам недоступны:
                    </>
                )}
                theme="black"
            />

            <ul className={cls.list}>
                {listRender}
            </ul>
        </div>
    );
};
