import { api } from 'shared/config/rtk-query-api/api.services';
import { IChannel } from 'shared/types/channels';

import { IResponseImportingStatus } from '../types/import.types';

const importApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // eslint-disable-next-line max-len
        getImportStatus: builder.query<IResponseImportingStatus, { channelId: IChannel['id']; sessionId?: number }>({
            query: ({ channelId }) => ({
                method: 'GET',
                url: `/api/v1/channels/${channelId}/refresh/`,
            }),
        }),
        requestImport: builder.mutation<null, string>({
            query: (channelId: IChannel['id']) => ({
                method: 'POST',
                url: `/api/v1/channels/${channelId}/refresh/`,
            }),
        }),
    }),
});

export const {
    useGetImportStatusQuery,
    useRequestImportMutation,
} = importApi;
