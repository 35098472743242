import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {
    IAudioFileSchema,
} from '../types/global-audio-channel.types';

export const initialState: IAudioFileSchema = {
    changedTime: null,
    date: '',
    file: null,
    isChangingWidthWave: false,
    isClearIntervalWidth: false,
    isEnded: true,
    isPlaying: false,
    messageId: null,
    name: '',
    timeRemaining: '',
    totalTime: 0,
    volume: 100,
    widthWave: 0,
};

export const audioFileSlice = createSlice({
    initialState,
    name: 'audioFile',
    reducers: {
        clearData(state) {
            state.changedTime = null;
            state.file = null;
            state.isChangingWidthWave = false;
            state.isEnded = true;
            state.isPlaying = false;
            state.messageId = null;
            state.timeRemaining = '';
            state.totalTime = 0;
            state.widthWave = 0;
            state.name = '';
            state.date = '';
        },
        setAudioFile(state, { payload: file }: PayloadAction<IAudioFileSchema['file']>) {
            state.file = file;
        },
        setChangedTime(state, { payload: time }: PayloadAction<IAudioFileSchema['changedTime']>) {
            state.changedTime = time;
        },
        setDate(state, { payload: value }: PayloadAction<IAudioFileSchema['date']>) {
            state.date = value;
        },
        setIsChangingWidthWave(state, { payload: value }: PayloadAction<IAudioFileSchema['isChangingWidthWave']>) {
            state.isChangingWidthWave = value;
        },
        setIsClearIntervalWidth(state, { payload: value }: PayloadAction<IAudioFileSchema['isClearIntervalWidth']>) {
            state.isClearIntervalWidth = value;
        },
        setIsEnded(state, { payload: value }: PayloadAction<IAudioFileSchema['isEnded']>) {
            state.isEnded = value;
        },
        setMessageId(state, { payload: messageId }: PayloadAction<IAudioFileSchema['messageId']>) {
            state.messageId = messageId;
        },
        setTimeRemaining(state, { payload: value }: PayloadAction<IAudioFileSchema['timeRemaining']>) {
            state.timeRemaining = value;
        },
        setVolume(state, { payload: value }: PayloadAction<IAudioFileSchema['volume']>) {
            state.volume = value;
        },
        setWidthWave(state, { payload: value }: PayloadAction<IAudioFileSchema['widthWave']>) {
            state.widthWave = value;
        },
        togglePlayingAudio(state, { payload: isPlaying }: PayloadAction<IAudioFileSchema['isPlaying'] | undefined>) {
            state.isPlaying = !!isPlaying;
        },
        updateName(state, { payload: name }: PayloadAction<IAudioFileSchema['name']>) {
            state.name = name;
        },
        updateTotalTime(state, { payload: totalTime }: PayloadAction<IAudioFileSchema['totalTime']>) {
            state.totalTime = totalTime;
        },
    },
});

export const { reducer: audioFileReducer } = audioFileSlice;
export const { actions: audioFileActions } = audioFileSlice;
