import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IStateSchema } from 'app/providers/store';
import { INotification } from 'shared/types/notification';

import {
    INotificationSchema,
} from '../types/notification.types';

const notificationsAdapter = createEntityAdapter<INotification>({
    selectId: (notification) => notification.id,
    sortComparer:
    (notificationOne, notificationTwo) => notificationTwo.createdAt
        .localeCompare(notificationOne.createdAt),
});

export const getNotificationsFromSlice = notificationsAdapter.getSelectors<IStateSchema>(
    (state) => state?.notifications || notificationsAdapter.getInitialState(),
);

export const notificationsSlice = createSlice({
    initialState: notificationsAdapter.getInitialState<INotificationSchema>({
        data: [],
        entities: {},
        ids: [],
        nextUrlNotification: null,
        previousUrlNotification: null,
    }),
    name: 'notifications',
    reducers: {
        addNotifications(state, { payload: notifications }: PayloadAction<INotification[]>) {
            notificationsAdapter.addMany(state, notifications);
        },
        clearDataNotifications(state) {
            notificationsAdapter.removeAll(state);
        },
        setNotifications(state, { payload: notifications }: PayloadAction<INotification[]>) {
            notificationsAdapter.setAll(state, notifications);
        },
        updateNextUrlNotification(state, { payload: nextUrl }: PayloadAction<INotificationSchema['nextUrlNotification']>) {
            state.nextUrlNotification = nextUrl;
        },
        updatePreviousUrlNotification(state, { payload: previousUrl }:
        PayloadAction<INotificationSchema['previousUrlNotification']>) {
            state.previousUrlNotification = previousUrl;
        },
    },
});

export const { reducer: notificationsReducer } = notificationsSlice;
export const { actions: notificationsActions } = notificationsSlice;
