import {
    FC,
    memo,
    useMemo,
} from 'react';

import {
    Tooltip,
} from 'antd';

import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IDefaultMessage } from 'shared/types/message';

import cls from './ControlError.module.scss';

interface IControlErrorProps {
    className?: string;
    errorsList: IDefaultMessage['errors'];
}

export const ControlError: FC<IControlErrorProps> = memo((props) => {
    const {
        className, errorsList,
    } = props;

    const { isTablet } = useScreenType();

    const lastErrorText = useMemo(() => {
        const lastIndex = errorsList.length - 1;
        return errorsList[lastIndex].description;
    }, [errorsList]);

    return (
        <div className={classNames('', {}, [className])}>
            <Tooltip
                title={lastErrorText}
                color="#FF7875"
                placement="right"
                zIndex={isTablet ? -1 : 1}
            >
                <ExclamationCircleFilled className={cls.errorIcon} />
            </Tooltip>
        </div>
    );
});
