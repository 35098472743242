import {
    Dispatch,
    FC,
    SetStateAction,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    getOpenCreateMessageModal,
} from 'entities/channels';
import { DUMMY_FUNCTION } from 'shared/constants/function';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { IChannel } from 'shared/types/channels';
import { CustomUploadFile } from 'shared/types/file';

import { createMessageActions } from '../model/slice/create-message.slice';

import './CreateMessage.scss';
import { CreateMessageModal } from './modal/create-message/CreateMessageModal';
import { CreateTableMessage } from './modal/create-table-message/CreateTableMessage';

interface ICreateMessageProps {
    handleReloadChannels?: () => void;
    setIsUpdateChannels?: Dispatch<SetStateAction<boolean>>;
    channel?: IChannel;
    toggleModal: () => void;
}

export const CreateMessage: FC<ICreateMessageProps> = memo((props) => {
    const {
        channel,
        handleReloadChannels = DUMMY_FUNCTION,
        setIsUpdateChannels = DUMMY_FUNCTION,
        toggleModal,
    } = props;
    const dispatch = useAppDispatch();

    const open = useSelector(getOpenCreateMessageModal);
    const [localFileList, setLocalFileList] = useState<CustomUploadFile[]>([]);

    const { isTablet } = useScreenType();

    const handleCancelModal = useCallback(() => {
        toggleModal();
        setLocalFileList([]);
        dispatch(createMessageActions.clearData());
    }, [toggleModal]);

    useEffect(() => {
        if (channel && open) {
            dispatch(createMessageActions.clearData());
            dispatch(createMessageActions.setTimezone(channel?.timezone ?? ''));
            dispatch(createMessageActions.setVariables(channel?.variables || []));
        }
    }, [channel, open]);

    if (!channel || !open) {
        return null;
    }

    if (isTablet && open) {
        return (
            <CreateTableMessage
                toggleModal={toggleModal}
                handleCancelModal={handleCancelModal}
                localFileList={localFileList}
                setLocalFileList={setLocalFileList}
                handleReloadChannels={handleReloadChannels}
                setIsUpdateChannels={setIsUpdateChannels}
                channel={channel}
            />
        );
    }

    return (
        <CreateMessageModal
            open={open}
            handleReloadChannels={handleReloadChannels}
            setIsUpdateChannels={setIsUpdateChannels}
            localFileList={localFileList}
            setLocalFileList={setLocalFileList}
            channel={channel}
            handleCancelModal={handleCancelModal}
        />
    );
});
