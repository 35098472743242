import {
    FC,
    memo,
    useMemo,
} from 'react';

import { Dayjs } from 'dayjs';

import {
    Node,
    serialize,
    slateNodesToString,
} from 'features/rich-text-editor';
import { POST_TYPES_STICKER } from 'shared/constants/message';
import {
    checkIsMimeType,
    EMimeType,
} from 'shared/lib/utils/mime-type-checker/checkIsMimeType';
import { isDisablePoll } from 'shared/lib/utils/poll/poll';
import { IVariable } from 'shared/types/channels';
import { CustomUploadFile } from 'shared/types/file';
import {
    EAlbumType,
    EMediaMessageTypes,
    EPostTypeMessage,
    IEditMessage,
    IMessageButtonList,
    IPostMessage,
    IPostPollAnswers,
    TMessage,
} from 'shared/types/message';
import { Text } from 'shared/ui/text/Text';
import { TMediaFile } from 'widgets/create-message';

import { Message } from '../message/Message';

import cls from './PreviewMessage.module.scss';

interface IPreviewMessageProps {
    timeMinutes: Dayjs | null;
    date: Dayjs | null;
    timezone: string;
    message?: TMessage | null;
    postType: EPostTypeMessage | null;
    buttons: IMessageButtonList[];
    isWebPreview: boolean;
    isNotifyPin: boolean;
    isPinIndefinitely: boolean;
    pinDuration: number | null;
    isSent?: boolean;
    text: Node[] | null;
    media: TMediaFile | null;
    docs: TMediaFile | null;
    roundVideo: CustomUploadFile | null;
    voice: CustomUploadFile | null;
    audio: TMediaFile | null;
    variables?: IVariable[];
    answersPoll: IPostPollAnswers[];
    multipleChoicePoll: boolean;
    questionPoll: string;
    quizPoll: boolean;
    solutionIndexPoll: number | null;
    solutionMessage: string;
    postFileData?: IPostMessage | null;
    albumType: EAlbumType | null;
}

export const PreviewMessage: FC<IPreviewMessageProps> = memo((props) => {
    const {
        albumType,
        answersPoll,
        audio,
        buttons,
        date,
        docs,
        isNotifyPin,
        isPinIndefinitely,
        isSent = false,
        isWebPreview,
        media,
        message,
        multipleChoicePoll,
        pinDuration,
        postFileData,
        postType,
        questionPoll,
        quizPoll,
        roundVideo,
        solutionIndexPoll,
        solutionMessage,
        text,
        timeMinutes,
        timezone,
        variables,
        voice,
    } = props;

    const isAlbum = postType === EPostTypeMessage.ALBUM;
    const isVoice = postType === EPostTypeMessage.VOICE_MESSAGE;
    const isRoundVideo = postType === EPostTypeMessage.ROUND_VIDEO;
    const isPoll = postType === EPostTypeMessage.POLL;
    const isText = postType === EPostTypeMessage.TEXT;

    const getFileData = (typePost?: EPostTypeMessage) => {
        if (docs !== null && docs.length > 0) {
            return {
                media: docs.map((item) => ({
                    file: item?.file ? item : { ...item, file: item },
                    type: EMediaMessageTypes.DOCUMENT,
                })),
            };
        }

        if (media !== null && media.length > 0) {
            return {
                media: media.map((item) => {
                    const isTypeVideo = checkIsMimeType(item?.type ?? '', EMimeType.VIDEO)
                    || item?.type === EMediaMessageTypes.VIDEO;

                    return {
                        file: { file: item?.file, originFileObj: item?.originFileObj },
                        type: isTypeVideo ? EMediaMessageTypes.VIDEO : EMediaMessageTypes.PHOTO,
                    };
                }),
            };
        }

        if (audio !== null && audio.length > 0) {
            return {
                media: audio.map((item) => ({
                    file: item?.file ? item : { ...item, file: item },
                    type: EMediaMessageTypes.AUDIO,
                })),
            };
        }

        if (typePost === EPostTypeMessage.ROUND_VIDEO && roundVideo) {
            return { file: roundVideo };
        }

        if (typePost === EPostTypeMessage.VOICE_MESSAGE && voice) {
            return { file: voice };
        }

        if (typePost && POST_TYPES_STICKER.includes(typePost) && postFileData) {
            return { ...postFileData };
        }

        return {};
    };

    const messageData = useMemo(() => {
        let htmlConvertedString: string | undefined;
        const isTextareaEmpty = text ? !slateNodesToString(text).trim().length : false;
        const isEmptyAlbum = (!media || media?.length === 0) && (!docs || docs?.length === 0) && (!audio || audio?.length === 0);

        if (text !== null && !isTextareaEmpty) {
            htmlConvertedString = serialize(text).trim();
        }

        if (!postType || !timezone || (isAlbum && isEmptyAlbum)
            || (isVoice && !voice)
            || (isRoundVideo && !roundVideo)
            || (!htmlConvertedString && isText)
        ) {
            return null;
        }

        const textData = htmlConvertedString ? { text: htmlConvertedString ?? '' } : {};

        const fileData = getFileData(postType);

        const isHideButtons = (media && media.length > 1) || (docs && docs.length > 1) || (audio && audio.length > 1);

        const pollData = isPoll ? {
            poll: {
                answers: answersPoll,
                multipleChoice: multipleChoicePoll,
                question: questionPoll,
                quiz: quizPoll,
                solutionIndex: solutionIndexPoll,
                solutionMessage,
            },
        } : {};

        const publishAt = !timeMinutes || !date ? null : timeMinutes;

        const post = {
            ...fileData,
            ...textData,
            ...pollData,
        };

        return {
            albumType,
            buttons: isHideButtons ? [[]] : buttons,
            donorMessageId: message?.donorMessageId,
            id: message?.id,
            importedFrom: message?.importedFrom,
            isEdited: message?.isEdited,
            isNotifyPin,
            isPinIndefinitely,
            isSent,
            pinDuration,
            post,
            postType,
            publishAt,
            text: htmlConvertedString ?? '',
            webPreviewLimitsBypassEnabled: isWebPreview,
        } as IEditMessage;
    }, [
        albumType,
        timeMinutes,
        date,
        timezone,
        message,
        postType,
        buttons,
        isWebPreview,
        isNotifyPin,
        isPinIndefinitely,
        pinDuration,
        isSent,
        text,
        media,
        docs,
        roundVideo,
        voice,
        audio,
        variables,
        isPoll,
        answersPoll,
        multipleChoicePoll,
        questionPoll,
        quizPoll,
        solutionIndexPoll,
        solutionMessage,
    ]);

    const additionalPollPost = useMemo(() => {
        if (isSent || postType === EPostTypeMessage.POLL) {
            return null;
        }

        if (isDisablePoll({
            answersPoll,
            isPoll: true,
            questionPoll,
            quizPoll,
            solutionIndexPoll: 1,
        })) {
            return null;
        }

        const publishAt = !timeMinutes || !date ? null : timeMinutes;

        const pollData = {
            poll: {
                answers: answersPoll,
                multipleChoice: multipleChoicePoll,
                question: questionPoll,
                quiz: quizPoll,
                solutionIndex: solutionIndexPoll,
                solutionMessage,
            },
        };

        const post = {
            ...pollData,
        };

        return {
            albumType: null,
            buttons: [[]],
            donorMessageId: message?.donorMessageId,
            id: message?.id,
            importedFrom: message?.importedFrom,
            isEdited: message?.isEdited,
            isNotifyPin: false,
            isPinIndefinitely: false,
            isSent: false,
            pinDuration: null,
            post,
            postType: EPostTypeMessage.POLL,
            publishAt,
            text: '',
            webPreviewLimitsBypassEnabled: false,
        } as IEditMessage;
    }, [
        isSent,
        answersPoll,
        multipleChoicePoll,
        questionPoll,
        quizPoll,
        solutionIndexPoll,
        solutionMessage,
        isPoll,
        message,
        date,
        timezone,
        timeMinutes,
    ]);

    if (!messageData) {
        return (
            <div className={cls.container}>
                <div className={cls.holderTitle}>
                    <Text text="Предпросмотр" size="xl" weight="600" theme="black" />
                </div>
                <div className={cls.emptyContent}>
                    <Text text="Пока-что пусто" weight="600" theme="black-0" size="lg" />
                    <Text
                        text={(
                            <>
                                Тут будет отображаться ваш будущий пост
                                <br />
                                в формате предпросмотра
                            </>
                        )}
                        theme="black-45"
                        size="md"
                        align="center"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={cls.container}>
            <div className={cls.holderTitle}>
                <Text text="Предпросмотр" size="xl" weight="600" theme="black" />
            </div>
            <div>
                <Message
                    message={messageData}
                    timezone={timezone}
                    variables={variables || []}
                />
            </div>
            {additionalPollPost && (
                <Message
                    message={additionalPollPost}
                    timezone={timezone}
                    variables={variables || []}
                />
            )}
        </div>
    );
});
