import {
    FC,
    memo,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
    IMessageOptions,
    Album,
    MessageButtonList,
    ContentTypeRenderer,
    Markup,
    renderHTMLString,
} from 'entities/message';
import { getNotificationsFromSlice } from 'entities/notification';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { getAlbumType } from 'shared/lib/utils/file/file';
import { IVariable } from 'shared/types/channels';
import {
    EAlbumType,
    EPostTypeMessage,
    IEditMessage,
} from 'shared/types/message';
import { Flex } from 'shared/ui/flex/Flex';

import cls from './Message.module.scss';

interface IMessageProps {
    className?: string;
    message: IEditMessage;
    timezone?: string;
    variables: IVariable[];
}

export const Message: FC<IMessageProps> = memo((props) => {
    const {
        className,
        message,
        timezone,
        variables,
    } = props;

    const text = message?.text;

    const notifications = useSelector(getNotificationsFromSlice.selectAll);

    const hasNotifications = notifications.length > 0;

    const markup = useMemo(
        () => (text ? (
            <div className={classNames(cls.textMes, {}, ['fontWithStickers'])}>
                {
                    renderHTMLString(text, variables)
                }
            </div>
        ) : null),
        [text, variables],
    );

    const options = useMemo((): IMessageOptions => {
        const albumType = getAlbumType(message.albumType, message.post?.media);

        const withDocument = albumType === EAlbumType.DOCUMENT;
        const withMediaPhotoVideo = albumType === EAlbumType.PHOTO_TYPE;
        const isAudioMessage = message.postType === EPostTypeMessage.AUDIO_MESSAGE || albumType === EAlbumType.AUDIO;

        const isAnimSticker = message.postType === EPostTypeMessage.ANIMATED_STICKER;
        const isVoiceMessage = message.postType === EPostTypeMessage.VOICE_MESSAGE;
        const isStaticSticker = message.postType === EPostTypeMessage.STATIC_STICKER;
        const isCustomSticker = message.postType === EPostTypeMessage.CUSTOM_STICKER;
        const isVideoSticker = message.postType === EPostTypeMessage.VIDEO_STICKER;
        const isRoundedVideo = message.postType === EPostTypeMessage.ROUND_VIDEO;
        const isAlbum = message.postType === EPostTypeMessage.ALBUM;
        const isPoll = message.postType === EPostTypeMessage.POLL;

        const withMediaWithoutText = withMediaPhotoVideo && !text;

        const isWebPreview = message?.post?.media?.length === 1
            && !!withMediaPhotoVideo
            && message.webPreviewLimitsBypassEnabled
            && !!text;

        const isOnlySticker = isAnimSticker || isStaticSticker || isVideoSticker || isCustomSticker;

        return {
            isAlbum,
            isAnimSticker,
            isAudioMessage,
            isOnlySticker,
            isPoll,
            isRoundedVideo,
            isStaticSticker,
            isVideoSticker,
            isVoiceMessage,
            isWebPreview,
            withDocument,
            withMediaPhotoVideo,
            withMediaWithoutText,
        };
    }, [text, message]);

    const isWithoutTextAndOnlyMedia = useMemo(
        () => (!markup || options.isRoundedVideo)
            && (options.withMediaPhotoVideo || options.isOnlySticker || options.isRoundedVideo),
        [markup, options],
    );

    const messageClasses = {
        [cls.withoutText]: !markup || options.isRoundedVideo,
        [cls.onlyMedia]: options.withMediaPhotoVideo || options.isOnlySticker || options.isRoundedVideo,
        [cls.withoutBg]: options.isOnlySticker || options.withMediaWithoutText || options.isRoundedVideo,
        [cls.webPreview]: options.isWebPreview,
        [cls.withoutPaddingB]: options.isOnlySticker || options.isVoiceMessage || options.isAudioMessage,
    };

    return (
        <li
            className={classNames(cls.messageWrapper, {
                [cls.messageWrapperAlbum]: options.isAlbum,
                [cls.messageWrapperWithError]: hasNotifications,
            })}
        >
            <Flex
                align="start"
                direction="column"
                className={classNames(cls.message, messageClasses, [className])}
            >
                <Album
                    message={message}
                    options={options}
                    isWithoutTextAndOnlyMedia={isWithoutTextAndOnlyMedia}
                    isPreview
                />
                <ContentTypeRenderer
                    message={message}
                    timezone={timezone}
                    isPreview
                />
                <Markup
                    message={message}
                    options={options}
                    timezone={timezone}
                    markup={markup}
                    isWithoutTextAndOnlyMedia={isWithoutTextAndOnlyMedia}
                    isPreview
                />
            </Flex>
            <MessageButtonList buttonList={message?.buttons} variables={variables} />
        </li>
    );
});
