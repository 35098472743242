import { useCallback } from 'react';

import {
    EMimeType,
    checkIsMimeType,
} from 'shared/lib/utils/mime-type-checker/checkIsMimeType';
import { CustomUploadFile } from 'shared/types/file';
import {
    EAlbumType,
    EMediaMessageTypes,
    IFile,
} from 'shared/types/message';

import { useUploadFileMutation } from '../model/services/edit-message.service';
import {
    TMediaFile,
} from '../model/types/edit-message.types';

type TFuncLoadData = (files: TMediaFile,
    albumType?: EAlbumType) => Promise<(IFile | CustomUploadFile)[]>;

export const useLoadFileData = (): TFuncLoadData => {
    const [loadFile] = useUploadFileMutation();

    // Here we determine the content type of requested file.
    // It can be document or photo or video
    const getFileType = (requestFile: File, albumType?: EAlbumType): EMediaMessageTypes => {
        if (albumType === EAlbumType.AUDIO) {
            return EMediaMessageTypes.AUDIO;
        }

        if (albumType === EAlbumType.DOCUMENT) {
            return EMediaMessageTypes.DOCUMENT;
        }

        const isPhoto = checkIsMimeType(requestFile.type ?? '', EMimeType.IMAGE);
        return isPhoto
            ? EMediaMessageTypes.PHOTO
            : EMediaMessageTypes.VIDEO;
    };
    // This function works into two methods:
    // - when we want to download documents
    // - when we want to download photos or videos
    // - when we want to download audio
    return useCallback(async (
        // Variable files contain local downloaded files and
        // files from server (the original data of editing message)
        files: TMediaFile,
        albumType?: EAlbumType,
    ): Promise<(IFile | CustomUploadFile)[]> => {
        const requests: any[] = [];
        // Define array which will be contain files data which were downloaded from server not local
        const withoutRequestsData: CustomUploadFile[] = [];

        // We iterates through each file
        files.forEach((file: CustomUploadFile) => {
            // If files from server (the original data of editing message)
            // we add it to array withoutRequests
            // because we don`t need to get these data twice
            if (file?.file || file?.thumbnail || !file?.originFileObj) {
                withoutRequestsData.push(file);
                return;
            }

            // Generate form data with file to send it to server
            const requestFile = file.originFileObj as File;
            const formData = new FormData();
            formData.append('document', requestFile || file);

            // If files were downloaded local we should load them on server
            // so we form requests to send them
            requests.push(loadFile(formData)
                // We add additional type to response because server don`t return type of file
                .then((res) => ({ ...res, type: getFileType(requestFile, albumType) }
                )));
        });
        // Calls requests
        const responses = await Promise.all(requests);
        // Files data from server
        const responseData = responses.map(
            (response: any): IFile => ({ ...response.data, type: response?.type }),
        );
        // Join original files in editing message from server with
        //  requestedData from server by local downloaded files
        return [...withoutRequestsData, ...responseData];
    }, []);
};
