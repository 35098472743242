import {
    FC,
    Suspense,
} from 'react';

import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { Sidebar } from 'widgets/sidebar';

import { CreateChannelPageContentAsync as CreateChannelPageContent } from './CreateChannelPage.async';

export const CreateChannelPage: FC = () => {
    const { isTablet } = useScreenType();

    return (
        <MainLayouts Sidebar={isTablet ? null : <Sidebar />}>
            <Suspense fallback={<PageLoader isLoading withoutPortal />}>
                <CreateChannelPageContent />
            </Suspense>
        </MainLayouts>
    );
};
