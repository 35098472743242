import {
    FC,
    MouseEvent,
    memo,
    useCallback,
    useMemo,
} from 'react';
import {
    NavLink,
    useParams,
} from 'react-router-dom';

import { Tooltip } from 'antd';

import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { messagesActions } from 'entities/message';
import { notificationsActions } from 'entities/notification';
import { audioFileActions } from 'features/global-audio-channel';
import { CHANNEL_URL } from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getNearestAbbreviatedDate,
} from 'shared/lib/utils/date/date';
import { IChannel } from 'shared/types/channels';
import { Text } from 'shared/ui/text/Text';

import { channelActions } from '../../model/slice/channels.slice';
import { ChannelAvatar } from '../avatar/ChannelAvatar';
import { SkeletonChannel } from '../skeleton/SkeletonChannel';

import cls from './ChannelItem.module.scss';

interface IChannelItemProps {
    className?: string;
    channel: IChannel;
    index: number;
    onScrollMessage?: () => void;
    isLoading: boolean;
}

export const ChannelItem: FC<IChannelItemProps> = memo((props) => {
    const { id: channelId } = useParams();

    const {
        channel, className, index, isLoading,
        onScrollMessage,
    } = props;

    const { isTablet } = useScreenType();

    const isFirstChannel = index === 0;
    const isError = !!channel.errorsCount && channel.errorsCount > 0;

    const dispatch = useAppDispatch();

    const date = useMemo(
        () => (channel.lastPublishedPostAt && channel.timezone ? (
            getNearestAbbreviatedDate(channel.lastPublishedPostAt, channel.timezone)
        ) : (
            <span>{'\u2014'}</span>
        )),
        [channel.lastPublishedPostAt, channel.timezone],
    );

    const handleClick = useCallback(() => {
        if (channelId === channel.id && onScrollMessage) {
            onScrollMessage();
            return;
        }

        dispatch(messagesActions.updateErrorUpdateMessage(false));
        dispatch(audioFileActions.clearData());
        dispatch(channelActions.setSelectChannelName(channel.name));
        dispatch(messagesActions.clearCacheAnimationSticker());
        dispatch(messagesActions.updateOpenFromChannelList(true));
        dispatch(notificationsActions.clearDataNotifications());
        dispatch(messagesActions.updateIndexStartMes(0));
    }, [channelId, channel, onScrollMessage]);

    const handleClickTooltip = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
    }, []);

    if (isLoading) {
        return <SkeletonChannel />;
    }

    return (
        <NavLink
            to={`${CHANNEL_URL}/${channel.id}`}
            onClick={handleClick}
            className={({ isActive }) => classNames(
                cls.channel,
                { [cls.active]: isActive, [cls.channelError]: isError },
                [className],
            )}
        >
            <ChannelAvatar channel={channel} />

            <div className={classNames(cls.channelInfo, { [cls.borderNone]: isFirstChannel })}>
                <div className={classNames(cls.channelInfoHolder, { [cls.channelInfoHolderError]: isError })}>
                    <p className={cls.channelText}>
                        <span className={classNames(cls.name, { [cls.nameError]: isError })}>{channel.name}</span>
                    </p>
                    <p className={cls.date}>{date}</p>
                </div>
                {isError && (
                    <Tooltip
                        title={(
                            <button
                                type="button"
                                onClick={handleClickTooltip}
                                className={cls.errorTooltipHolderTitle}
                            >
                                <Text text="Исправьте ошибки в канале" className={cls.errorTooltipTitle} />
                            </button>
                        )}
                        color="#FF4D4F"
                        placement="right"
                        zIndex={isTablet ? -1 : 5}
                        rootClassName={cls.errorTooltip}
                    >
                        <div className={cls.holderIcon}>
                            <WarningOutlined className={cls.errorIcon} />
                        </div>
                    </Tooltip>
                )}
            </div>
        </NavLink>
    );
});
