import { FC } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getFileIcon,
    splitFileName,
} from 'shared/lib/utils/file/file';
import { CustomUploadFile } from 'shared/types/file';

import { DndItemWrapper } from '../dnd/DndItemWrapper';
import { Text } from '../text/Text';

import cls from './DocumentFile.module.scss';

interface IDocumentFileProps {
    className?: string;
    fileName: string;
    file?: CustomUploadFile;
    size?: number | string;
    onClick?: () => void;
    onDelete?: () => void;
    isLoaded?: boolean;
    isPreview?: boolean;
    isEdit?: boolean;
}

export const DocumentFile: FC<IDocumentFileProps> = (props) => {
    const {
        className,
        file,
        fileName,
        isEdit,
        isLoaded = true,
        isPreview,
        onClick,
        onDelete,
        size,
    } = props;
    const fileSize = `${((Number(size) || 0) / 1024).toFixed(1)}KB`;

    const handleClickDoc = () => {
        if (isLoaded) {
            onClick?.();
        }
    };

    if (isEdit) {
        const [startName, endName] = splitFileName(fileName);

        return (
            <DndItemWrapper
                id={file?.uid || ''}
                className={classNames(cls.documentEdit, {}, [className])}
            >
                {getFileIcon(fileName, cls.docIconEdit)}
                <div className={cls.docInfoEdit}>
                    <Text text={startName} size="md" theme="black" className={cls.docName} />
                    <Text text={endName} size="md" theme="black" />
                </div>

                {onDelete
              && (
                  <button
                      type="button"
                      className={cls.holderDeleteIcon}
                      onClick={onDelete}
                  >
                      <DeleteOutlined />
                  </button>
              )}
            </DndItemWrapper>
        );
    }

    return (
        <button
            onClick={handleClickDoc}
            type="button"
            className={classNames(cls.document, { [cls.withoutCursor]: !isLoaded || isPreview }, [className])}
        >
            <FileTextOutlined className={cls.docIcon} />
            <div className={cls.docInfo}>
                <p className={cls.docTitle}>{fileName}</p>
                <p className={cls.docSize}>
                    {size ? fileSize : 'Загрузка...'}
                </p>
            </div>
            {onDelete ? <DeleteOutlined onClick={onDelete} className={cls.deleteIcon} /> : null}
        </button>
    );
};
