import { IChannel } from 'shared/types/channels';

const colors: [string, string][] = [['#94E046', '#55AF35'], ['#FF6ACE', '#D03CF0'], ['#FD8E70', '#F63C3F'],
    ['#A96BFC', '#5C3BEC'], ['#82B5F7', '#3E54C1'], ['#F6EF52', '#D1C913'],
    ['#6FA8FF', '#3687FD'], ['#FFCE54', '#F85E34'],
];

export const getUserColorByChannelId = (channelId: IChannel['id']): [string, string] => {
    const index = parseInt(channelId, 16) % colors.length;
    return colors[index];
};
