import {
    FC,
    memo,
} from 'react';

import { IMessageOptions } from 'entities/message';
import { IArgsContentViewer } from 'features/content-viewer';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { CustomUploadFile } from 'shared/types/file';
import {
    IEditMessage,
    IFile,
    IPostMedia,
    TMessage,
} from 'shared/types/message';

import { AlbumItem } from './AlbumItem';

import cls from './Album.module.scss';

interface IAlbumProps {
    message: TMessage | IEditMessage;
    options: IMessageOptions;
    isWithoutTextAndOnlyMedia: boolean;
    openPreviewVideoOrPhoto?: (props: IArgsContentViewer) => void;
    isPreview?: boolean;
    timezone?: string;
}

export const Album: FC<IAlbumProps> = memo((props) => {
    const {
        isPreview = false,
        isWithoutTextAndOnlyMedia,
        message,
        openPreviewVideoOrPhoto,
        options,
        timezone,
    } = props;

    const media = message?.post?.media;

    if (!media || media?.length === 0) {
        return null;
    }

    const wrapperClasses = {
        [cls.withoutTextAndOnlyMedia]: isWithoutTextAndOnlyMedia,
        [cls.documents]: options.withDocument,
        [cls.mediaImage]: options.withMediaPhotoVideo,
        [cls.webPreview]: options.isWebPreview,
        [cls.audio]: options.isAudioMessage,
    };

    return (
        <div className={classNames(cls.grid, wrapperClasses, [cls[`items${media?.length}`]])}>
            {media?.map((mediaItem: IPostMedia, i, array) => {
                const serverFile = (mediaItem?.file as IFile)?.file;
                const localUploadFileUid = (mediaItem?.file as CustomUploadFile)?.originFileObj?.uid;

                return (
                    <AlbumItem
                        array={array}
                        key={`${serverFile || localUploadFileUid}`}
                        index={i}
                        media={mediaItem}
                        message={message}
                        openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                        isPreview={isPreview}
                        timezone={timezone}
                    />
                );
            })}
        </div>
    );
});
