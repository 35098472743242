import {
    ICreateMessage,
    IMessageFromServer,
    mapMessage,
    transformBodyCreateMessage,
} from 'entities/message';
import { api } from 'shared/config/rtk-query-api/api.services';
import { TMessage } from 'shared/types/message';

const importApi = api.injectEndpoints({
    endpoints: (builder) => ({
        editMessage: builder.mutation<TMessage, ICreateMessage>({
            query: (data) => ({
                body: transformBodyCreateMessage(data),
                headers: {},
                method: 'PATCH',
                url: `/api/v1/posts/${data.id}/`,
            }),
            transformResponse: (response:
            IMessageFromServer) => mapMessage(response),
        }),
        uploadFile: builder.mutation<any, FormData>({
            query: (formData: FormData) => ({
                body: formData,
                headers: {},
                method: 'POST',
                url: '/api/v1/uploads/',
            }),
        }),
    }),
});

export const {
    useEditMessageMutation,
    useUploadFileMutation,
} = importApi;
