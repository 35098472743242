/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    CSSProperties,
    HTMLProps,
    MouseEvent,
    forwardRef,
    useState,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import cls from './ImageWithLoader.module.scss';

interface IImageWithLoaderProps extends HTMLProps<HTMLImageElement> {
    className?: string;
    classNameImage?: string;
    classNameSkeleton?: string;
    skeletonWidth?: string;
    skeletonHeight?: string;
    src: string;
    alt: string;
    onClick?: (e: MouseEvent<HTMLImageElement>) => void;
    onLoad?: () => void;
    styles?: CSSProperties;
}

export const ImageWithLoader = forwardRef<HTMLImageElement, IImageWithLoaderProps>((props, ref) => {
    const {
        alt, className, classNameImage, classNameSkeleton,
        onClick, onLoad, skeletonHeight, skeletonWidth, src, styles,
        ...otherProps
    } = props;

    const [isLoading, setIsLoading] = useState(true);

    const onLoadImage = () => {
        setIsLoading(false);
        onLoad?.();
    };

    return (
        <div className={classNames(cls.imageContainer, {}, [className])}>
            {isLoading && (
                <Skeleton
                    className={classNames(cls.skeleton, {}, [classNameSkeleton])}
                    styles={{ height: skeletonHeight, width: skeletonWidth }}
                    border="16px"
                    width={skeletonWidth ?? '100%'}
                    height={skeletonHeight ?? ''}
                />
            ) }

            <img
                style={{ display: isLoading ? 'none' : 'block', ...styles }}
                src={src}
                ref={ref}
                decoding="async"
                onLoad={onLoadImage}
                alt={alt}
                onClick={onClick}
                className={classNameImage}
                {...otherProps}
            />
        </div>
    );
});
