import { KeyboardEvent } from 'react';

import {
    Editor,
    Text,
    Transforms,
    Range,
    Point,
    createEditor,
} from 'slate';

import { IVariable } from 'shared/types/channels';

import { HTML_TYPES } from '../constants/constants';
import {
    CustomEditor,
    IBaseHtmlTypes,
    Node,
} from '../types/editor';

type BaseTypes = keyof IBaseHtmlTypes;

export const formatTextWithNodeProperties = (text: string, node: Node) => {
    const tags: BaseTypes[] = ['bold', 'italic', 'u', 'del', 'code', 'pre'];
    const mappings = {
        bold: 'b',
        code: 'code',
        del: 'del',
        italic: 'em',
        pre: 'pre',
        u: 'u',
    };

    return tags.reduce(
        (formattedText, tag) => (node[tag] ? `<${mappings[tag]}>${formattedText}</${mappings[tag]}>` : formattedText),
        text,
    );
};

export const sanitizeAndFormatText = (text: string) => text.replace(/</gi, '&lt;').replace(/>/gi, '&gt;');

export const isMarkActive = (editor: CustomEditor, format: string) => {
    const marks = Editor.marks(editor);

    return marks ? marks[format as keyof Omit<Text, 'text'>] === true : false;
};

export const toggleMark = (editor: CustomEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
        return;
    }

    Editor.addMark(editor, format, true);
};

export const insertVariable = (editor: CustomEditor, variableName: string) => {
    const { selection } = editor;

    if (!selection || !Range.isCollapsed(selection)) return;

    const variable = {
        children: [{ text: variableName }],
        type: HTML_TYPES.VARIABLE,
    };

    Transforms.insertNodes(editor, variable);
    Transforms.collapse(editor, { edge: 'end' });
};
export const insertEmoji = (editor: CustomEditor, emoji: string) => {
    const { selection } = editor;

    if (!selection || !Range.isCollapsed(selection)) return;

    Transforms.insertText(editor, emoji);
    Transforms.collapse(editor, { edge: 'end' });
};

export const checkIsLinkActive = (editor: CustomEditor) => {
    const [a] = Editor.nodes(editor, { match: (n: Node) => n.type === HTML_TYPES.LINK });
    return !!a;
};

export const removeLink = (editor: CustomEditor) => {
    Transforms.unwrapNodes(editor, { match: (n: Node) => n.type === HTML_TYPES.LINK });
};

export const removeLinkIfCollapsedAtEnd = (
    editor: CustomEditor,
    event: KeyboardEvent<HTMLInputElement>,
) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
        const [linkNodeEntry] = Editor.nodes(
            editor,
            { match: (n: Node) => n.type === HTML_TYPES.LINK },
        );
        if (linkNodeEntry) {
            const [, path] = linkNodeEntry;
            const end = Editor.end(editor, path);

            // If the selection is at the end of the link, remove the link
            if (Point.equals(selection.anchor, end)) {
                event.preventDefault();

                Transforms.insertFragment(editor, [{ text: ' ' }]);
                removeLink(editor);
            }
        }
    }
};

const mapSlateNodes = (variables: IVariable[]) => (item: any) => {
    if (item.type === HTML_TYPES.VARIABLE) {
        const variableName = item?.children?.[0]?.text;
        const variableValue = variables.find(({ name }) => name === variableName)?.value || variableName;
        return {
            text: variableValue,
        };
    }

    return item;
};

const getChildrenNodeWithVariableValue = (state: any, variables?: IVariable[]) => {
    if (!variables || variables?.length === 0) {
        return state;
    }

    const isWithChildrenAndTypeParagraph = state?.[0]?.children && state?.[0]?.type === 'paragraph';

    return state.map((el: any, i: number, array: any[]) => {
        if (isWithChildrenAndTypeParagraph) {
            const additionalElement = array.length - 1 === i ? [] : [{ text: ' ' }];
            return { ...el, children: [...el.children.map(mapSlateNodes(variables)), ...additionalElement] };
        }

        return mapSlateNodes(variables)(el);
    });
};

export function slateNodesToString(state: any, variables?: IVariable[]) {
    const value = getChildrenNodeWithVariableValue(state, variables);
    const editor = createEditor();
    editor.children = value;

    return Editor.string(editor, []);
}
