import {
    useCallback,
    memo,
    useState,
    useEffect,
    useMemo,
    FC,
} from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'antd';

import SyncOutlined from '@ant-design/icons/SyncOutlined';
import {
    getMessagesFromSlice,
    getMessagesShouldUpdate,
    getShouldUpdateMessage,
    messagesActions,
} from 'entities/message';
import { getStateIsEditPost } from 'entities/user';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IChannel } from 'shared/types/channels';
import {
    EErrors,
    IInternetConnectionError,
} from 'shared/types/types';
import { Text } from 'shared/ui/text/Text';

import {
    useGetImportStatusQuery,
    useRequestImportMutation,
} from '../model/services/import.service';
import { EImportingStatuses } from '../model/types/import.types';

import cls from './ImportMessages.module.scss';

interface IImportMessagesProps {
    channel?: IChannel;
    isDisabled: boolean;
    className?: string;
}

export const ImportMessages: FC<IImportMessagesProps> = memo((props) => {
    const { channel, className, isDisabled } = props;

    const dispatch = useAppDispatch();

    const { isDesktop, isTablet } = useScreenType();

    const messages = useSelector(getMessagesFromSlice.selectAll);
    const shouldUpdateMessage = useSelector(getShouldUpdateMessage);

    const [isLoading, setIsLoading] = useState(false);
    const [prevChannelId, setPrevChannelId] = useState('');
    const [sessionId, setSessionId] = useState(2000);
    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);
    const isEditPostRight = useSelector(getStateIsEditPost);

    const [importPosts, { isLoading: isRequestImportLoading }] = useRequestImportMutation();
    const {
        data: statusImporting,
        error,
        isFetching,
    } = useGetImportStatusQuery(
        { channelId: String(channel?.id), sessionId },
        {
            pollingInterval: 2000,
            skip: !isLoading || isRequestImportLoading,
        },
    );

    const typeButton = useMemo(() => (messages.length === 0 ? 'primary' : 'default'), [messages]);
    const textButton = useMemo(() => {
        if (messages.length === 0 || isDesktop || isTablet) {
            return 'Обновить посты';
        }

        return '';
    }, [messages, isDesktop, isTablet]);

    const handleImportMessages = useCallback(() => {
        if (!channel) return;

        importPosts(channel.id);
        setIsLoading(true);
        dispatch(messagesActions.setMessagesImportingStatus('pending'));
    }, [channel]);

    useEffect(() => {
        if (isFetching) return;
        if ((error as IInternetConnectionError)?.status === EErrors.FETCH_ERROR) return;
        if (statusImporting && statusImporting.status === EImportingStatuses.FINISHED) {
            if (messageUpdatingStatus === 'pending' && prevChannelId === channel?.id) {
                dispatch(messagesActions.setMessagesImportingStatus('shouldUpdate'));
            }
            setIsLoading(false);
        }

        if (statusImporting && statusImporting.status === EImportingStatuses.FAILURE) {
            if (messageUpdatingStatus === 'pending' && prevChannelId === channel?.id) {
                dispatch(messagesActions.setMessagesImportingStatus('updated'));
                dispatch(messagesActions.updateErrorUpdateMessage(true));
            }
            setIsLoading(false);
        }
    }, [statusImporting, error, isFetching]);

    useEffect(() => {
        if (channel) {
            if (prevChannelId !== channel.id) {
                dispatch(messagesActions.setMessagesImportingStatus('updated'));
                setIsLoading(true);
                setSessionId(Date.now());
            }
            setPrevChannelId(channel.id);
        }
    }, [channel]);

    useEffect(() => {
        if (statusImporting?.status === EImportingStatuses.PENDING) {
            dispatch(messagesActions.setMessagesImportingStatus('pending'));
        }
    }, [statusImporting]);

    useEffect(() => {
        if (shouldUpdateMessage) {
            handleImportMessages();
            dispatch(messagesActions.updateShouldUpdateMessage(false));
        }
    }, [shouldUpdateMessage]);

    if (!isEditPostRight) {
        return null;
    }

    if (isTablet) {
        return (
            <button
                type="button"
                className={classNames(cls.holderButtonMobile, {}, [className])}
                onClick={handleImportMessages}
                disabled={messageUpdatingStatus === 'pending'}
            >
                <div className={cls.contentMobile}>
                    <Text text={textButton} theme="black" />
                    <SyncOutlined className={cls.tabletIcon} />
                </div>
            </button>
        );
    }

    if (isDisabled || !channel) {
        return (
            <Button
                type="default"
                className={classNames(cls.importMessages, {}, [className])}
                icon={<SyncOutlined />}
                disabled={isDisabled}
            >
                {textButton}
            </Button>
        );
    }

    return (
        <Button
            type={typeButton}
            onClick={handleImportMessages}
            disabled={messageUpdatingStatus === 'pending'}
            icon={<SyncOutlined spin={messageUpdatingStatus === 'pending'} />}
            className={classNames('', {}, [className])}
        >
            {textButton}
        </Button>
    );
});
