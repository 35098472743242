import { transformGetNotificationList } from 'entities/notification';
import { api } from 'shared/config/rtk-query-api/api.services';
import { getQueryParam } from 'shared/lib/utils/validUrl/validUrl';
import { IChannel } from 'shared/types/channels';
import { INotification } from 'shared/types/notification';
import { IBackendListResponse } from 'shared/types/types';

interface IGetMessagesArgs {
    channelId?: IChannel['id'];
    cursor?: string;
}

const notificationsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query<IBackendListResponse<INotification>, IGetMessagesArgs>({
            providesTags: () => [{ type: 'Notifications' }],
            query: ({ channelId, cursor }) => {
                const query = getQueryParam({ channel__id: channelId, cursor });
                return { url: `/api/v1/notifications/${query}` };
            },
            transformResponse: transformGetNotificationList,
        }),
    }),
});

export const {
    useGetNotificationsQuery,
} = notificationsApi;
