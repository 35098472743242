import {
    Suspense,
} from 'react';

import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';

import { AuthPageContentAsync as AuthPageContent } from './AuthPage.async';

export const AuthPage = () => (
    <MainLayouts isAuthPage>
        <Suspense fallback={<PageLoader withoutBg isLoading withoutPortal />}>
            <AuthPageContent />
        </Suspense>
    </MainLayouts>
);
