import { UploadFile } from 'antd';

import DocFileIcon from 'shared/assets/icons/docFile.svg';
import ExcelFileIcon from 'shared/assets/icons/excelFile.svg';
import Mp3FileIcon from 'shared/assets/icons/mp3File.svg';
import OtherFileIcon from 'shared/assets/icons/otherFile.svg';
import PdfFileIcon from 'shared/assets/icons/pdfFile.svg';
import { VOICE_FILE_TYPE } from 'shared/constants/docs';
import { POST_TYPES_STICKER } from 'shared/constants/message';
import {
    CustomUploadFile,
    EFileType,
} from 'shared/types/file';
import {
    EAlbumType,
    EMediaMessageTypes,
    EPostTypeMessage,
    ICreateMessageFile,
    IDefaultMessage,
    IFile,
    IPostMedia,
    TMessage,
} from 'shared/types/message';

import {
    EMimeType,
    checkIsMimeType,
} from '../mime-type-checker/checkIsMimeType';

export const isFileLess10MB = (size?: number) => Number(size || 100_000) / 1024 / 1024 < 10;

export const checkIsVoiceFileSafari = (file: UploadFile) => {
    const fileName = file?.name || '';
    const fileNameArray = fileName.split('.');
    const fileType = fileNameArray[fileNameArray.length - 1];
    return fileType === VOICE_FILE_TYPE && file?.type === '';
};

export const checkIsMediaFile = (file: CustomUploadFile) => {
    const isMediaFile = checkIsMimeType(file?.type ?? '', EMimeType.VIDEO)
        || checkIsMimeType(file?.type ?? '', EMimeType.IMAGE)
        || file?.type === EMimeType.IMAGE
        || file?.type === EMimeType.VIDEO;

    const isGif = checkIsMimeType(file?.type ?? '', EMimeType.GIF);

    return isMediaFile && !isGif;
};

export const checkFileType = (file: UploadFile): EMimeType | null => {
    if (checkIsMediaFile(file)) {
        return EMimeType.IMAGE;
    }

    const isGif = checkIsMimeType(file?.type ?? '', EMimeType.GIF);

    if (isGif) {
        return EMimeType.GIF;
    }

    const isFile = checkIsMimeType(file?.type ?? '', EMimeType.APPLICATION);

    if (isFile) {
        return EMimeType.APPLICATION;
    }

    const isVoiceType = checkIsMimeType(file?.type ?? '', EMimeType.VOICE) || checkIsVoiceFileSafari(file);

    if (isVoiceType) {
        return EMimeType.VOICE;
    }

    const isAudio = checkIsMimeType(file?.type ?? '', EMimeType.AUDIO);

    if (isAudio) {
        return EMimeType.AUDIO;
    }

    return null;
};

export const checkSameTypeFiles = (
    firstFile: UploadFile,
    secondFile: UploadFile,
) => checkFileType(firstFile) === checkFileType(secondFile);

export const handleVideoUpload = (file: File) => new Promise((resolve) => {
    // Данная функция подгружает видео файл
    // и проверяте, может ли видео быть видео кружочком
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const width = video.videoWidth;
        const height = video.videoHeight;
        const { duration } = video;

        const isPossibleRoundVideoType = width === height && duration < 60;

        if (video.parentNode) {
            video.parentNode.removeChild(video);
        }

        resolve(isPossibleRoundVideoType);
    };
});

export const checkRoundVideo = async (filesData: UploadFile[]) => {
    const file = filesData[0].originFileObj as File;
    const check = await handleVideoUpload(file);
    return check;
};

export const getValidFilesForSave = (loadedData: (IFile | CustomUploadFile)[], typePost?: EPostTypeMessage, message?: TMessage)
: ICreateMessageFile | { media: ICreateMessageFile[] } => {
    if (typePost === EPostTypeMessage.ALBUM) {
        return {
            media: loadedData.map((el) => ({ fileId: el.id, type: el?.type })),
        };
    }
    if (
        typePost === EPostTypeMessage.ROUND_VIDEO
        || typePost === EPostTypeMessage.VOICE_MESSAGE
        || typePost === EPostTypeMessage.AUDIO_MESSAGE
    ) {
        return { fileId: loadedData[0].id };
    }
    if (typePost && POST_TYPES_STICKER.includes(typePost) && message) {
        return { fileId: message?.post?.file?.id };
    }

    return {};
};

export const getFileType = (name: string) => {
    const docType = ['doc', 'docx'];
    const excelType = ['xls', 'xlsx'];
    const pdfType = ['pdf'];
    const mp3Type = ['mp3'];

    const fileNameArray = name.split('.');
    const fileType = fileNameArray[fileNameArray.length - 1];

    if (docType.includes(fileType)) {
        return EFileType.DOC;
    }

    if (excelType.includes(fileType)) {
        return EFileType.EXCEL;
    }

    if (pdfType.includes(fileType)) {
        return EFileType.PDF;
    }

    if (mp3Type.includes(fileType)) {
        return EFileType.MP3;
    }

    return EFileType.FILE;
};

export const getFileIcon = (name: string, className:string = '') => {
    const typeFile = getFileType(name);

    if (typeFile === EFileType.DOC) {
        return <DocFileIcon className={className} />;
    }

    if (typeFile === EFileType.EXCEL) {
        return <ExcelFileIcon className={className} />;
    }

    if (typeFile === EFileType.PDF) {
        return <PdfFileIcon className={className} />;
    }

    if (typeFile === EFileType.MP3) {
        return <Mp3FileIcon className={className} />;
    }

    return <OtherFileIcon className={className} />;
};

export function splitFileName(filename: string): [string, string] {
    // Если длина имени файла <= 9, возвращаем имя файла и пустую строку
    if (filename.length <= 9) {
        return [filename, ''];
    }

    // Ищем индекс последней точки, чтобы отделить расширение
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex < 4) {
        // Если точки нет или имя файла слишком короткое для разделения
        return [filename, ''];
    }

    // Основная часть файла и последние 4 символа перед расширением + расширение
    const mainPart = filename.slice(0, lastDotIndex - 4);
    const lastFourWithExtension = filename.slice(lastDotIndex - 4);

    return [mainPart, lastFourWithExtension];
}

export const getAlbumType = (albumType: IDefaultMessage['albumType'], media?: IPostMedia[]) => {
    if (albumType) {
        return albumType;
    }

    const isDoc = media?.some((el) => el.type === EMediaMessageTypes.DOCUMENT);
    if (isDoc) {
        return EAlbumType.DOCUMENT;
    }

    const isMedia = media?.some(
        (el) => el.type === EMediaMessageTypes.PHOTO || el.type === EMediaMessageTypes.VIDEO,
    );

    if (isMedia) {
        return EAlbumType.PHOTO_TYPE;
    }

    const isAudio = media?.some((el) => el.type === EMediaMessageTypes.AUDIO);
    if (isAudio) {
        return EAlbumType.AUDIO;
    }

    return null;
};
