import { UploadFile } from 'antd';

export interface OriginFileObj extends File {
    uid: string;
    percent?: number;
    name: string;
    size: number;
    type: string;
    lastModified: number;
    lastModifiedDate: Date;
    webkitRelativePath: string;
}

export enum EFileType {
    DOC = 'doc',
    EXCEL = 'excel',
    PDF = 'pdf',
    MP3 = 'mp3',
    FILE = 'file',
}

export interface CustomUploadFileFromServer extends UploadFile<any> {
    file?: string;
    file_size?: number;
    id?: string;
}

export interface CustomUploadFile extends UploadFile<any> {
    file?: string;
    thumbnail?: string;
    fileSize?: number;
    id?: string;
    originFileObj?: OriginFileObj;
}
