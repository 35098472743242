import { IStateSchema } from 'app/providers/store';

export const getUserInit = (state: IStateSchema) => state.user.init;
export const getUserAuth = (state: IStateSchema) => state.user.isAuth;
export const getUserData = (state: IStateSchema) => state.user?.userData;
export const getAuthError = (state: IStateSchema) => state.user.authError;
export const getIsRoleAssignment = (state: IStateSchema) => state.user.isRoleAssignment;
export const getIsRoleEdition = (state: IStateSchema) => state.user.isRoleEdition;
export const getStateUser = (state: IStateSchema) => state.user;
export const getStateIsEditPost = (state: IStateSchema) => state.user.isEditPost;
export const getStateIsChannelEdition = (state: IStateSchema) => state.user.isChannelEdition;
export const getIsWebappAuth = (state: IStateSchema) => state.user.isWebappAuth;
