import {
    FC,
    memo,
    useRef,
    useEffect,
    Dispatch,
    SetStateAction,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    messagesActions,
} from 'entities/message';
import { getNotificationsFromSlice } from 'entities/notification';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { consoleDev } from 'shared/lib/utils/consoleDev/consoleDev';
import {
    EPublishStatus,
    TMessage,
} from 'shared/types/message';
import { GoogleLoader } from 'shared/ui/loader/google/GoogleLoader';
import { Text } from 'shared/ui/text/Text';

import {
    useLazyCheckPostPublishStatusQuery,
    useLazyGetMessageQuery,
    useResendPostMutation,
} from '../../model/services/messages.service';

import cls from './ResendLoader.module.scss';

interface IResendLoaderProps {
    message: TMessage;
    isResend: boolean;
    setIsResend: Dispatch<SetStateAction<boolean>>;
    reloadMessages: Function;
    reloadChannels: Function;
}

interface IResponseMessageResend {
    data: { status: string };
}

const TIME_POLLING_API = 3000;

export const ResendLoader: FC<IResendLoaderProps> = memo((props) => {
    const {
        isResend,
        message,
        reloadChannels,
        reloadMessages,
        setIsResend,
    } = props;

    const dispatch = useAppDispatch();

    const [isProgress, setIsProgress] = useState(false);

    const [resendMessage] = useResendPostMutation();
    const [checkMessagePublishStatus] = useLazyCheckPostPublishStatusQuery();
    const [getDataMessage] = useLazyGetMessageQuery();
    const { openNotification } = useNotification();

    const notifications = useSelector(getNotificationsFromSlice.selectAll);

    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const isMountedRef = useRef<boolean>(false);

    const handleSucessResend = async () => {
        try {
            const { data: dataMessage } = await getDataMessage({ messageId: message.id });

            if (dataMessage) {
                dispatch(
                    messagesActions.updateMessage({
                        changes: dataMessage,
                        id: dataMessage.id,
                    }),
                );
            }

            openNotification({ description: 'Успешная переотправка' });
            reloadMessages();
            reloadChannels();
        } catch (error) {
            consoleDev(error as Error);
        } finally {
            setIsResend(false);
        }
    };

    const handleCheckStatusResend = async () => {
        try {
            const { data } = await checkMessagePublishStatus({ id: message.id }) as IResponseMessageResend;

            if (data?.status === EPublishStatus.IN_PROGRESS) {
                if (isMountedRef.current) {
                    timeout.current = setTimeout(
                        handleCheckStatusResend,
                        TIME_POLLING_API,
                    );
                }
            } else if (data?.status === EPublishStatus.SEND_SUCCEEDED) {
                handleSucessResend();
            } else {
                openNotification({ description: 'Ошибка при переотправке', type: 'error' });
                setIsResend(false);
            }
        } catch (error) {
            consoleDev(error as Error);
            setIsResend(false);
        }
    };

    const handleResendMessage = async () => {
        try {
            await resendMessage({ id: message.id });
            handleCheckStatusResend();
        } catch (error) {
            consoleDev(error as Error);
            setIsResend(false);
        }
    };

    useEffect(() => {
        isMountedRef.current = true;

        if (isResend) {
            if (isProgress) {
                handleCheckStatusResend();
            } else {
                handleResendMessage();
            }
        }

        return () => {
            isMountedRef.current = false;
            clearTimeout(timeout.current);
        };
    }, [isResend, isProgress]);

    useEffect(() => {
        if (message.postPublishStatus === EPublishStatus.IN_PROGRESS) {
            setIsProgress(true);
            setIsResend(true);
        }
    }, [message.postPublishStatus]);

    if (!isResend) {
        return null;
    }

    return (
        <div className={cls.resendHolder}>
            <GoogleLoader className={cls.resendLoader} />
            <Text
                text="Отправка"
                className={classNames(cls.resendText, { [cls.resendTextWithNotifications]: notifications.length > 0 })}
            />
        </div>

    );
});
