/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    FC,
    Suspense,
    memo,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import CloseOutlined from '@ant-design/icons/CloseOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Flex } from 'shared/ui/flex/Flex';

import { ANIMATION_TIME } from '../constants/content-viewer.constant';
import {
    getContentViewerContentType,
    getContentViewerFileSrc,
    getContentViewerIsOnLoad,
    getContentViewerIsOpen,
} from '../model/selectors/content-viewer.selectors';
import { contentViewerActions } from '../model/slice/content-viewer.slice';

import { ImageViewerAsync } from './viewers/image-viewer/ImageViewer.async';
import { VideoViewerAsync } from './viewers/video-viewer/VideoViewer.async';

import cls from './ContentViewer.module.scss';

interface IContentViewerProps {
    className?: string;
}

export const ContentViewer: FC<IContentViewerProps> = memo((props) => {
    const { className } = props;

    const contentSrc = useSelector(getContentViewerFileSrc);
    const contentType = useSelector(getContentViewerContentType);
    const isOnLoad = useSelector(getContentViewerIsOnLoad);
    const isOpenContentViewer = useSelector(getContentViewerIsOpen);
    const [isContentSet, setIsContentSet] = useState(false);
    const [shouldClose, setShouldClose] = useState(false);

    const isVideo = contentType === 'video';
    const isPhoto = contentType === 'photo';

    const dispatch = useAppDispatch();

    const handleClose = () => {
        if (isOpenContentViewer) {
            dispatch(contentViewerActions.toggleView(false));
            setShouldClose(true);

            setTimeout(() => {
                dispatch(contentViewerActions.resetContentViewer());
                setIsContentSet(false);
                setShouldClose(false);
            }, ANIMATION_TIME);
        }
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (isOpenContentViewer) {
            e.preventDefault();
        }

        if (e.key === 'Escape') {
            handleClose();
        }
    };

    const closeOnClickOutsideOnThumbnail = () => {
        if (isOnLoad) {
            handleClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener('keydown', onKeyDown);

        return () => {
            document.body.removeEventListener('keydown', onKeyDown);
        };
    }, [isOpenContentViewer]);

    return (
        <Flex
            justify="center"
            className={classNames(
                cls.contentViewer,
                {
                    [cls.active]: isContentSet,
                    [cls.background]: (isContentSet || isOpenContentViewer) && !shouldClose,
                },
                [className],
            )}
            onClick={closeOnClickOutsideOnThumbnail}
        >
            <Suspense fallback={<LoadingOutlined className={cls.loader} />}>

                {isPhoto && <ImageViewerAsync isOnLoad={isOnLoad} imageSrc={contentSrc} setIsContentSet={setIsContentSet} /> }
                {isVideo && (
                    <VideoViewerAsync
                        videoSrc={contentSrc}
                        setIsContentSet={setIsContentSet}
                        handleClose={handleClose}
                    />
                )}
            </Suspense>

            {isPhoto && (
                <>
                    <Flex onClick={handleClose} className={cls.closeControls}>
                        <CloseOutlined />
                    </Flex>
                    {!isOnLoad && <div className={cls.overlay} onClick={handleClose} /> }
                </>
            ) }
        </Flex>
    );
});
