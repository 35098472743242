import {
    FC,
    useCallback,
    useRef,
    useState,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    IEditMessage,
    IFile,
    IVideoStickerMessage,
} from 'shared/types/message';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import { getStickersSizes } from '../../../utils/getStickersSizes';

import cls from './VideoSticker.module.scss';

interface IVideoStickerProps {
    className?: string;
    file: IFile;
    message: IVideoStickerMessage | IEditMessage;
}

export const VideoSticker: FC<IVideoStickerProps> = (props) => {
    const { className, file, message } = props;

    const [isLoading, setIsLoading] = useState(true);

    const videoRef = useRef<HTMLVideoElement>(null);

    const { height } = getStickersSizes(message);

    const handleLoadData = useCallback(() => {
        setIsLoading(false);
    }, []);

    return (
        <div className={classNames(cls.videoSticker, {}, [className])}>
            {isLoading && <Skeleton height={height} className={cls.skeleton} border="8px" />}
            <video
                src={file.file}
                muted
                style={{ display: isLoading ? 'none' : 'block', height }}
                ref={videoRef}
                controls={false}
                autoPlay
                loop
                playsInline
                onLoadedData={handleLoadData}
            />
        </div>
    );
};
