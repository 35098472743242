import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
} from 'antd';
import dayjs from 'dayjs';

import {
    messagesActions,
    ICreateMessagePost,
} from 'entities/message';
import {
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
} from 'entities/user';
import {
    serialize,
    slateNodesToString,
} from 'features/rich-text-editor';
import { EImportedFromMessage } from 'shared/constants/message';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import {
    validDate,
} from 'shared/lib/utils/date/date';
import { getValidFilesForSave } from 'shared/lib/utils/file/file';
import { isDisablePoll } from 'shared/lib/utils/poll/poll';
import { getUtcOffset } from 'shared/lib/utils/timezones/timezones';
import {
    EAlbumType,
    EPostTypeMessage,
} from 'shared/types/message';
import { useCreateMessageMutation } from 'widgets/create-message';
import { editMessageActions } from 'widgets/edit-message/model/slice/edit-message.slice';
import { DateValidVariant } from 'widgets/edit-message/model/types/edit-message.types';

import { useLoadFileData } from '../../hooks/useLoadFileData';
import {
    getEditMessageButtons,
    getEditMessageData,
    getEditMessageDate,
    getEditMessageDocuments,
    getEditMessageMaxSymbolsLength,
    getEditMessageIsNotifyPin,
    getEditMessageIsPinIndefinitely,
    getEditMessageMedia,
    getEditMessagePinDuration,
    getEditMessagePostType,
    getEditMessageText,
    getEditMessageTime,
    getEditMessageTimezone,
    getEditMessageVariables,
    getEditMessageRoundVideo,
    getEditMessageVoice,
    getEditMessageAudio,
    getEditMessageDateValidType,
    getEditMessageIsWebPreview,
    getEditMessageSolutionIndexPoll,
    getEditMessageQuizPoll,
    getEditMessageQuestionPoll,
    getEditMessageMultipleChoicePoll,
    getEditMessageAnswersPoll,
    getEditMessageSolutionMessage,
    getEditMessageIsSent,
    getEditMessageAlbumType,
} from '../../model/selectors/edit-message.selectors';
import { useEditMessageMutation } from '../../model/services/edit-message.service';
import { validateDate } from '../../utils/date';

import { MaxLengthSymbolsErrorModal } from './modal/MaxLengthSymbolsErrorModal';
import { SyncMessagesModal } from './sync-messages-modal/SyncMessagesModal';

interface ISaveMessageProps {
    cancelModal: () => void;
    handleReloadChannels: () => void;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
    isTablet?: boolean;
}

export const SaveMessage: FC<ISaveMessageProps> = (props) => {
    const {
        cancelModal, handleReloadChannels, isTablet, setIsUpdateChannels,
    } = props;

    const dispatch = useAppDispatch();
    const { openNotification } = useNotification();

    const [isErrorOpenModal, setIsErrorOpenModal] = useState(false);
    const [isOpenSyncMessagesModal, setIsOpenSyncMessagesModal] = useState(false);
    const [errorMaxLength, setErrorMaxLength] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    const { data } = useGetUserSettingsQuery();

    const loadFileData = useLoadFileData();
    const [editMessage, { data: newMessage, isSuccess }] = useEditMessageMutation();
    const [createMessage, { data: newMessageCreate, isSuccess: isSuccessCreate }] = useCreateMessageMutation();

    const [updateUserSettings] = useUpdateUserSettingsMutation();

    const text = useSelector(getEditMessageText);
    const media = useSelector(getEditMessageMedia);
    const docs = useSelector(getEditMessageDocuments);
    const message = useSelector(getEditMessageData);
    const timeMinutes = useSelector(getEditMessageTime);
    const date = useSelector(getEditMessageDate);
    const timezone = useSelector(getEditMessageTimezone);
    const postType = useSelector(getEditMessagePostType);
    const buttons = useSelector(getEditMessageButtons);
    const maxSymbolsLength = useSelector(getEditMessageMaxSymbolsLength);
    const isNotifyPin = useSelector(getEditMessageIsNotifyPin);
    const pinDuration = useSelector(getEditMessagePinDuration);
    const isPinIndefinitely = useSelector(getEditMessageIsPinIndefinitely);
    const variables = useSelector(getEditMessageVariables);
    const roundVideo = useSelector(getEditMessageRoundVideo);
    const voice = useSelector(getEditMessageVoice);
    const audio = useSelector(getEditMessageAudio);
    const validDateType = useSelector(getEditMessageDateValidType);
    const isWebPreview = useSelector(getEditMessageIsWebPreview);
    const answersPoll = useSelector(getEditMessageAnswersPoll);
    const multipleChoicePoll = useSelector(getEditMessageMultipleChoicePoll);
    const questionPoll = useSelector(getEditMessageQuestionPoll);
    const quizPoll = useSelector(getEditMessageQuizPoll);
    const solutionIndexPoll = useSelector(getEditMessageSolutionIndexPoll);
    const solutionMessage = useSelector(getEditMessageSolutionMessage);
    const isSent = useSelector(getEditMessageIsSent);
    const albumType = useSelector(getEditMessageAlbumType);

    const shouldOpenSyncModal = !data?.settings?.disableSyncAlert;

    const isAlbum = postType === EPostTypeMessage.ALBUM;
    const isAudio = postType === EPostTypeMessage.AUDIO_MESSAGE;
    const isVoice = postType === EPostTypeMessage.VOICE_MESSAGE;
    const isRoundVideo = postType === EPostTypeMessage.ROUND_VIDEO;
    const isPoll = postType === EPostTypeMessage.POLL;

    const isTextareaEmpty = text ? !slateNodesToString(text).trim().length : false;
    const mediaLength = media?.length;
    const docsLength = docs?.length;
    const audioLength = audio?.length;

    const isCreateAdditionalPost = useMemo(
        () => message?.postType !== EPostTypeMessage.POLL
    && !isDisablePoll({
        answersPoll,
        isPoll: true,
        questionPoll,
        quizPoll,
        solutionIndexPoll,
    }),
        [message,
            questionPoll,
            answersPoll,
            answersPoll,
            quizPoll,
            solutionIndexPoll,
        ],
    );

    const isDisabled = useMemo(
        () => {
            const isPollCheck = isDisablePoll({
                answersPoll,
                isPoll,
                questionPoll,
                quizPoll,
                solutionIndexPoll,
            });

            return isPollCheck
            || (isAlbum && (mediaLength === 0 || docsLength === 0 || audioLength === 0))
            || (isAudio && !audio)
            || (isVoice && !voice)
            || (isRoundVideo && !roundVideo)
            || (isWebPreview && isTextareaEmpty)
            || !validDate(timeMinutes)
            || !validDate(date)
            || !!validDateType;
        },
        [
            isAlbum,
            isAudio,
            isVoice,
            isRoundVideo,
            audio,
            voice,
            roundVideo,
            isTextareaEmpty,
            mediaLength,
            docsLength,
            audioLength,
            timeMinutes,
            date,
            validDateType,
            isPoll,
            questionPoll,
            answersPoll,
            quizPoll,
            solutionIndexPoll,
            isWebPreview,
        ],
    );

    const isDisabledAdditionalPost = useMemo(() => {
        if (isDisabled || postType === EPostTypeMessage.POLL) {
            return false;
        }

        if (questionPoll || answersPoll.length > 0) {
            return isDisablePoll({
                answersPoll,
                isPoll: true,
                questionPoll,
                quizPoll,
                solutionIndexPoll,
            });
        }

        return false;
    }, [isDisabled, postType, questionPoll, answersPoll, quizPoll, solutionIndexPoll]);

    const getFileData = async (typePost?: EPostTypeMessage) => {
        if (docs !== null && docs.length > 0) {
            return loadFileData(docs, EAlbumType.DOCUMENT);
        }

        if (media !== null && media.length > 0) {
            return loadFileData(media);
        }

        if (audio !== null && audio.length > 0) {
            return loadFileData(audio, EAlbumType.AUDIO);
        }

        if (typePost === EPostTypeMessage.ROUND_VIDEO && roundVideo) {
            return loadFileData([roundVideo]);
        }

        if (typePost === EPostTypeMessage.VOICE_MESSAGE && voice) {
            return loadFileData([voice]);
        }

        return [];
    };

    const getAlbumType = useCallback(() => {
        if (albumType) {
            return albumType;
        }

        if (media && media.length > 0) {
            return EAlbumType.PHOTO_TYPE;
        }

        if (docs && docs.length > 0) {
            return EAlbumType.DOCUMENT;
        }

        if (audio && audio.length > 0) {
            return EAlbumType.AUDIO;
        }

        return null;
    }, [albumType, media, docs, audio]);

    const handleSave = useCallback(
        async (isShowSyncModal?: boolean) => {
            if (!message) {
                return;
            }

            setIsLoading(true);
            const loadedData = await getFileData(message?.postType);
            const albumTypeData = getAlbumType();
            let htmlConvertedString: string | undefined;

            if (shouldOpenSyncModal && isShowSyncModal) {
                updateUserSettings({ settings: { disableSyncAlert: true } });
            }

            if (!isPoll) {
                const textLength = slateNodesToString(text, variables).length;
                if (textLength > maxSymbolsLength) {
                    setIsErrorOpenModal(true);
                    setErrorMaxLength(textLength - maxSymbolsLength);
                    setIsLoading(false);
                    return;
                }

                htmlConvertedString = text ? serialize(text).trim() : '';
            }

            const textData = !isPoll && !isRoundVideo ? { text: htmlConvertedString ?? '' } : {};

            const isHideButtons = (media && media.length > 1) || (docs && docs.length > 1);

            const buttonsData = buttons && !isHideButtons
                ? { buttons: buttons.map((row) => row?.map((btn) => ({ name: btn.name, value: btn.value }))) }
                : { buttons: [] };

            const fileDate = getValidFilesForSave(loadedData, message?.postType, message);

            const pollData = isPoll ? {
                poll: {
                    answers: answersPoll,
                    multipleChoice: multipleChoicePoll,
                    question: questionPoll,
                    quiz: quizPoll,
                    solutionIndex: solutionIndexPoll,
                    solutionMessage,
                },
            } : {};

            const post = {
                ...fileDate,
                ...textData,
                ...pollData,
            };

            const readyMinutes = getUtcOffset(timezone) === 0 ? timeMinutes.utcOffset(0) : timeMinutes;
            const readyDate = date && getUtcOffset(timezone) === 0 ? date.utcOffset(0) : date;
            const time = dayjs(readyMinutes).format('HH:mm:ss');
            const dateFinished = dayjs(readyDate).format('YYYY-MM-DD');

            const formattedDateByUTC = dayjs(`${dateFinished}T${time}Z`)
                .utc()
                .utcOffset(-dayjs().utc().tz(timezone).utcOffset())
                .format('YYYY-MM-DDTHH:mm:ss');

            setIsUpdateChannels(true);
            await editMessage({
                albumType: albumTypeData,
                channel: message.channel,
                id: message.id,
                notifyOnPin: isNotifyPin,
                pinDuration,
                pinIndefinitely: isPinIndefinitely,
                postType: message?.postType,
                publishAt: formattedDateByUTC,
                webPreviewLimitsBypassEnabled: isWebPreview, // TODO: добавлен полный функционал в ветке feat/PL-449
                ...buttonsData,
                ...(Object.keys(post).length > 0 ? { post } : {}),
            });

            if (isCreateAdditionalPost) {
                const postPoll = {
                    poll: {
                        answers: answersPoll,
                        multipleChoice: multipleChoicePoll,
                        question: questionPoll,
                        quiz: quizPoll,
                        solutionIndex: solutionIndexPoll,
                        solutionMessage,
                    },
                } as ICreateMessagePost;

                await createMessage({
                    albumType: null,
                    buttons: [],
                    channel: message.channel,
                    id: message.id,
                    notifyOnPin: isNotifyPin,
                    pinDuration,
                    pinIndefinitely: isPinIndefinitely,
                    post: postPoll,
                    postType: EPostTypeMessage.POLL,
                    publishAt: formattedDateByUTC,
                    webPreviewLimitsBypassEnabled: isWebPreview,
                });
            }

            handleReloadChannels();
            setIsLoading(false);
        },
        [
            docs,
            media,
            text,
            timeMinutes,
            date,
            buttons,
            maxSymbolsLength,
            isNotifyPin,
            pinDuration,
            isPinIndefinitely,
            variables,
            shouldOpenSyncModal,
            updateUserSettings,
            roundVideo,
            voice,
            audio,
            isWebPreview,
            isTextareaEmpty,
            answersPoll,
            multipleChoicePoll,
            questionPoll,
            quizPoll,
            solutionIndexPoll,
            isPoll,
            solutionMessage,
            isCreateAdditionalPost,
            getAlbumType,
        ],
    );

    const onShowDateError = useCallback((value: DateValidVariant) => {
        if (isSent) {
            return;
        }

        dispatch(editMessageActions.setValidDateType(value));
    }, [isSent]);

    const saveOrShowSyncMessageModal = useCallback(() => {
        const readyMinutes = getUtcOffset(timezone) === 0 ? timeMinutes.tz(timezone) : timeMinutes;
        const formattedDate = date && getUtcOffset(timezone) === 0 ? date.utcOffset(0) : dayjs(date);

        const error = validateDate(formattedDate, readyMinutes, timezone, onShowDateError);

        if (error && !isSent) {
            return;
        }

        if (shouldOpenSyncModal && message?.importedFrom !== EImportedFromMessage.WEB_INTERFACE) {
            setIsOpenSyncMessagesModal(true);
            return;
        }

        handleSave();
    }, [
        handleSave,
        onShowDateError,
        shouldOpenSyncModal,
        isSent,
        date,
        timezone,
        timeMinutes,
        isSent,
        postType,
    ]);

    useEffect(() => {
        /* Проверяем есть ли дополнительный дополнительный пост с опросом */
        if (isCreateAdditionalPost) {
            /* Проверяем что бы все запросы на редактирование сообщения и создание прошли успешно */
            if (isSuccess && newMessage && newMessageCreate && isSuccessCreate) {
                /* Обновляем в строрадже редактируемое сообщение */
                dispatch(
                    messagesActions.updateMessage({
                        changes: newMessage,
                        id: newMessage.id,
                    }),
                );
                /* Добавляем сторадж новое сообщение */
                dispatch(
                    messagesActions.addMessage(newMessageCreate),
                );
                /* Закрываем модальное окно */
                cancelModal();

                openNotification({ description: 'Сообщение изменено!' });
            }
        /* Проверяем запрос на редактирование сообщения прошел успешно */
        } else if (isSuccess && newMessage) {
            /* Обновляем в строрадже редактируемое сообщение */
            dispatch(
                messagesActions.updateMessage({
                    changes: newMessage,
                    id: newMessage.id,
                }),
            );
            /* Закрываем модальное окно */
            cancelModal();

            openNotification({ description: 'Сообщение изменено!' });
        }
    }, [isSuccess, isCreateAdditionalPost, isSuccessCreate]);

    return (
        <>
            <Button
                disabled={isDisabled || isDisabledAdditionalPost}
                loading={isLoading}
                key="submit"
                type="primary"
                onClick={saveOrShowSyncMessageModal}
                block={!!isTablet}
                size="large"
            >
                Сохранить
            </Button>
            <MaxLengthSymbolsErrorModal
                key="modal-max-length-error"
                isErrorOpenModal={isErrorOpenModal}
                errorMaxLength={errorMaxLength}
                setIsErrorOpenModal={setIsErrorOpenModal}
            />
            <SyncMessagesModal
                isOpen={isOpenSyncMessagesModal}
                onOk={handleSave}
                setIsOpen={setIsOpenSyncMessagesModal}
            />
        </>
    );
};
