import {
    FC,
    ReactNode,
} from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { classNames } from 'shared/lib/utils/classNames/classNames';

interface IDndItemWrapperProps {
    className?: string;
    id: string;
    children: ReactNode;

}

export const DndItemWrapper: FC<IDndItemWrapperProps> = (props) => {
    const { children, className, id } = props;

    const {
        attributes, listeners, setNodeRef, transform, transition,
    } = useSortable({
        id,
    });

    const style: React.CSSProperties = {
        cursor: 'move',
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={classNames('', {}, [className])}
        >
            {children}
        </div>
    );
};
