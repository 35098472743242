import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useNavigate,
    useSearchParams,
} from 'react-router-dom';

import { Modal } from 'antd';
import { Dayjs } from 'dayjs';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import {
    channelActions,
    getOpenCreateMessageModal,
    getSelectChannelName,
} from 'entities/channels';
import {
    getErrorUpdateMessage,
    getIsOpenFromChannelList,
    getMessageIdsList,
    getMessageList,
    getMessagesShouldUpdate,
    messagesActions,
    useLazyGetMessageQuery,
    useRemoveMessageMutation,
} from 'entities/message';
import {
    getNotificationsFromSlice,
    useGetNotificationsQuery,
} from 'entities/notification';
import { EHintUpdatePost } from 'features/import-messages';
import {
    ETextMaxSymbolsLength,
    deserialize,
} from 'features/rich-text-editor';
import { MAX_LENGTH_DOCS } from 'shared/constants/docs';
import { HOME_URL } from 'shared/constants/router';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import { useScrollMessage } from 'shared/lib/hooks/useScrollMessage/useScrollMessage';
import { consoleDev } from 'shared/lib/utils/consoleDev/consoleDev';
import { getAlbumType } from 'shared/lib/utils/file/file';
import { IChannel } from 'shared/types/channels';
import {
    EAlbumType,
    EPostTypeMessage,
    TMessage,
} from 'shared/types/message';
import { createMessageActions } from 'widgets/create-message';
import {
    editMessageActions,
    getEditMessageData,
} from 'widgets/edit-message';

const SHOW_LAST_PUBLISHED_POST_TIME = 100;

interface IUseChannelHandlerProps {
    channel?: IChannel;
    channelId: IChannel['id'];
    isLoadingChannel: boolean;
    refetch: () => void;
    reloadChannels: () => void;
    statusQueryChannel: QueryStatus;
    statusQueryMessages: QueryStatus;
    prevChannelId: string;
    isFetchingMessages: boolean;
}

interface IUseChannelHandlerReturn {
    channelName: IChannel['name'];
    handleClearLastPublishedLoad: (value: boolean) => void;
    handleEditMessage: (mes: TMessage) => void;
    handleOpenNotification: () => void;
    handleReloadChannels: () => void;
    handleRemoveMessage: (messageId: TMessage['id']) => void;
    isLoadingChangeChannel: boolean;
    isOpenNotifications: boolean;
    isUpdateNotifications: boolean;
    onScrollMessage: () => void;
    openModalToChangeMessage: boolean;
    reloadMessages: () => void;
    setIsOpenNotifications: Dispatch<SetStateAction<boolean>>;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
    setShouldAddMessages: Dispatch<SetStateAction<boolean>>;
    setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
    shouldAddMessages: boolean;
    toggleEditModal: () => void;
    isNotifications: boolean;
    statusUpdateChannel: EHintUpdatePost | null;
    toggleCreateModal: () => void;
    isLoadingCreateOrEditModal: boolean;
}

export const useChannelHandler = (props: IUseChannelHandlerProps): IUseChannelHandlerReturn => {
    const {
        channel,
        channelId,
        isFetchingMessages,
        isLoadingChannel,
        prevChannelId,
        refetch,
        reloadChannels,
        statusQueryChannel,
        statusQueryMessages,
    } = props;

    const { openNotification } = useNotification();

    const [openModalToChangeMessage, setOpenModalToChangeMessage] = useState(false);
    const [shouldAddMessages, setShouldAddMessages] = useState(false);
    const [isHideMessageList, setIsHideMessageList] = useState(false);
    const [channelName, setChannelName] = useState('');
    const [isUpdateChannels, setIsUpdateChannels] = useState(false);
    const [isOpenNotifications, setIsOpenNotifications] = useState(false);
    const [isUpdateNotifications, setUpdateNotifications] = useState(false);
    const [isScrollMessage, setIsScrollMessage] = useState(false);
    const [isCheckLastPublishedPost, setIsCheckLastPublishedPost] = useState(false);
    const [isLastPublishedPost, setIsLastPublishedPost] = useState(false);
    const [postIdDeleting, setPostIdDeleting] = useState('');
    const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);
    const [isLoadingCreateModal, setIsLoadingCreateModal] = useState(false);

    const selectChannelName = useSelector(getSelectChannelName);
    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);
    const isOpenFromChannelList = useSelector(getIsOpenFromChannelList);
    const notifications = useSelector(getNotificationsFromSlice.selectAll);
    const errorUpdateMessage = useSelector(getErrorUpdateMessage);
    const messageIdsList = useSelector(getMessageIdsList);
    const messageList = useSelector(getMessageList);
    const editMessageData = useSelector(getEditMessageData);
    const openCreateMessageModal = useSelector(getOpenCreateMessageModal);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const [removeMessage,
        {
            isError: isRemovedError,
            isLoading: isRemovedLoading,
            isSuccess: isRemovedSuccess,
        }] = useRemoveMessageMutation();

    const [getMessage,
        { isLoading: isLoadingPost },
    ] = useLazyGetMessageQuery();

    const { data: notificationsFromServer, refetch: refetchNotifications } = useGetNotificationsQuery({
        channelId,
    });

    const searchParamsString = new URLSearchParams(searchParams).toString();

    const editPostId = useMemo(() => {
        if (searchParamsString.startsWith('edit=')) {
            return searchParamsString.replace('edit=', '');
        }

        return '';
    }, [searchParamsString]);

    const isCreatePost = useMemo(() => {
        if (searchParamsString.startsWith('create=')) {
            return searchParamsString.replace('create=', '');
        }

        return '';
    }, [searchParamsString]);

    const handleClearLastPublishedLoad = (value: boolean) => {
        setIsLastPublishedPost(value);
        setIsCheckLastPublishedPost(value);
    };

    const { handleShowMessage } = useScrollMessage({
        channelId,
        handleToggleLoading: handleClearLastPublishedLoad,
        isAnimation: false,
    });

    const statusUpdateChannel = useMemo(() => {
        if (messageUpdatingStatus === QueryStatus.pending) {
            return EHintUpdatePost.UPDATED;
        }

        if (errorUpdateMessage) {
            return EHintUpdatePost.ERROR;
        }

        return null;
    }, [messageUpdatingStatus, errorUpdateMessage]);

    const isLoadingChangeChannel = useMemo(
        () => isLastPublishedPost
            || isCheckLastPublishedPost
            || isHideMessageList
            || channelId !== prevChannelId
            || isLoadingChannel
            || (!isUpdateChannels && statusQueryChannel === QueryStatus.pending)
            || !notificationsFromServer
            || (notificationsFromServer.results.length > 0 && notifications.length === 0 && isOpenFromChannelList),
        [
            isCheckLastPublishedPost,
            isHideMessageList,
            channelId,
            prevChannelId,
            isLoadingChannel,
            statusQueryChannel,
            isUpdateChannels,
            isLastPublishedPost,
            notificationsFromServer,
            notifications,
            isOpenFromChannelList,
        ],
    );

    const isLoadingCreateOrEditModal = useMemo(
        () => !!editPostId
            && (isLoadingEditModal
                || isLoadingPost
                || isLoadingCreateModal
                || (isLoadingChangeChannel && !statusUpdateChannel)),
        [
            editPostId,
            isLoadingEditModal,
            isLoadingPost,
            isLoadingCreateModal,
            isLoadingChangeChannel,
            statusUpdateChannel,
        ],
    );

    const isNotifications = useMemo(() => notifications.length > 0, [notifications]);

    const onScrollMessage = useCallback(() => {
        reloadChannels();
        refetch();
        setIsScrollMessage(true);
    }, []);

    const handleReloadChannels = useCallback(() => {
        reloadChannels();
        dispatch(messagesActions.setIsMessageListUpdate(true));
    }, [reloadChannels]);

    const toggleEditModal = useCallback(
        (postId?: TMessage['id']) => {
            const newParams = new URLSearchParams(searchParams);

            if (postId) {
                newParams.set('edit', postId);

                if (!editPostId) {
                    navigate(`?${newParams.toString()}`, { replace: false });
                }
                return;
            }

            newParams.delete('edit');
            navigate(`?${newParams.toString()}`, { replace: false });
        },
        [editPostId, searchParams, channelId],
    );

    const toggleCreateModal = useCallback(() => {
        const newParams = new URLSearchParams(searchParams);

        if (!openCreateMessageModal) {
            newParams.set('create', 'true');
            navigate(`?${newParams.toString()}`, { replace: false });
            dispatch(channelActions.setOpenCreateMessageModal(true));
            return;
        }

        newParams.delete('create');
        navigate(`?${newParams.toString()}`, { replace: false });
        dispatch(channelActions.setOpenCreateMessageModal(false));
    }, [searchParams, openCreateMessageModal, channelId]);

    const reloadMessages = useCallback(() => {
        setUpdateNotifications(true);
        refetchNotifications();
        refetch();
    }, []);

    const handleEditMessage = useCallback(
        (mes: TMessage) => {
            let newEditorState = null;

            const albumType = getAlbumType(mes.albumType, mes.post?.media);
            const isDocs = albumType === EAlbumType.DOCUMENT;
            const isMedia = albumType === EAlbumType.PHOTO_TYPE;
            const isRoundVideo = mes.postType === EPostTypeMessage.ROUND_VIDEO && !!mes.post?.file;
            const isVoice = mes.postType === EPostTypeMessage.VOICE_MESSAGE && !!mes.post?.file;
            const isAudio = (mes.postType === EPostTypeMessage.AUDIO_MESSAGE && !!mes.post?.file)
            || albumType === EAlbumType.AUDIO;
            const isPoll = mes.postType === EPostTypeMessage.POLL;

            const isMediaLengthEqualsOneAndWebPreviewEnabled = mes.webPreviewLimitsBypassEnabled && mes.post?.media?.length === 1;

            const isMax4094SymbolsLength = mes.postType === EPostTypeMessage.TEXT || isMediaLengthEqualsOneAndWebPreviewEnabled;
            const maxSymbolsLength = isMax4094SymbolsLength
                ? ETextMaxSymbolsLength.LONG_LENGTH
                : ETextMaxSymbolsLength.SHORT_LENGTH;

            const file = mes.post.file ? { ...mes.post.file, uid: mes.post.file.id || '' } : null;
            const media = mes?.post?.media?.map((el) => ({ ...el.file, type: el.type, uid: el.file.id || '' })) || [];
            const pollData = isPoll && mes.post.poll;

            if (!isPoll) {
                newEditorState = deserialize(mes?.text || '');
            }

            dispatch(
                editMessageActions.setData({
                    albumType,
                    answers: pollData ? pollData.answers : [],
                    audio: isAudio && media ? media : null,
                    buttons: mes?.buttons || [],
                    date: mes.publishAt as Dayjs,
                    dateValidType: '',
                    documents: isDocs && media ? media : null,
                    isNotifyPin: mes.isNotifyPin,
                    isPinIndefinitely: mes.isPinIndefinitely,
                    isSent: mes.isSent,
                    isWebPreview: mes.webPreviewLimitsBypassEnabled,
                    maxLengthAudio: isAudio && mes.isSent ? media.length : MAX_LENGTH_DOCS,
                    maxLengthDocs: isDocs && mes.isSent ? media.length : MAX_LENGTH_DOCS,
                    maxLengthMedia: isMedia && mes.isSent ? media.length : MAX_LENGTH_DOCS,
                    maxSymbolsLength,
                    media: isMedia && media ? media : null,
                    message: mes,
                    multipleChoice: pollData ? pollData.multipleChoice : false,
                    pinDuration: mes.pinDuration,
                    post: mes.post || null,
                    postType: mes.postType,
                    question: pollData ? pollData.question : '',
                    quiz: pollData ? pollData.quiz : false,
                    roundVideo: isRoundVideo && file ? file : null,
                    solutionIndex: pollData ? pollData.solutionIndex : null,
                    solutionMessage: pollData ? pollData.solutionMessage || '' : '',
                    text: newEditorState,
                    time: mes.publishAt as Dayjs,
                    timezone: channel?.timezone ?? '',
                    variables: channel?.variables,
                    voice: isVoice && file ? file : null,
                }),
            );

            toggleEditModal(mes.id);
        },
        [channel, toggleEditModal],
    );

    const loadMessage = useCallback(async (postEditId: TMessage['id']) => {
        try {
            const { data: dataMessage } = await getMessage({ messageId: postEditId });

            handleEditMessage(dataMessage as TMessage);
        } catch (error) {
            consoleDev(error as Error);
        }
    }, [handleEditMessage]);

    const handleRemoveMessage = useCallback((messageId: TMessage['id']) => {
        removeMessage({ messageId });
        setPostIdDeleting(messageId);
        setUpdateNotifications(true);
    }, []);

    const handleOpenNotification = useCallback(() => {
        setIsOpenNotifications(true);
    }, []);

    useEffect(() => {
        if (!channel && !isLoadingChannel) {
            navigate(HOME_URL);
        }
    }, [channel, isLoadingChannel]);

    useEffect(() => {
        if (statusQueryMessages === QueryStatus.pending) {
            setIsHideMessageList(true);
        }
        Modal.destroyAll();
        setOpenModalToChangeMessage(false);

        return () => {
            dispatch(channelActions.setOpenCreateMessageModal(false));
            dispatch(createMessageActions.clearData());
        };
    }, [channelId]);

    useEffect(() => {
        if (statusQueryMessages === QueryStatus.fulfilled) {
            setIsHideMessageList(false);
        }
    }, [statusQueryMessages]);

    useEffect(() => {
        if (statusQueryChannel === QueryStatus.fulfilled && isUpdateChannels) {
            setIsUpdateChannels(false);
        }
    }, [statusQueryChannel]);

    useEffect(() => {
        if (selectChannelName) {
            setChannelName(selectChannelName);
            dispatch(channelActions.setSelectChannelName(''));
        }

        if (channel && channel.name !== channelName) {
            setChannelName(channel.name);
        }
    }, [selectChannelName, channel]);

    useEffect(() => {
        if (channelId !== prevChannelId) {
            setIsCheckLastPublishedPost(true);
        }
    }, [channelId, prevChannelId]);

    useEffect(() => {
        refetchNotifications();
    }, [channelId]);

    useEffect(() => {
        if (postIdDeleting && isRemovedSuccess && !isRemovedLoading) {
            openNotification({
                description: 'Пост удален',
                icon: <DeleteOutlined />,
                type: 'info',
            });

            dispatch(messagesActions.deleteMessage(postIdDeleting));
            setPostIdDeleting('');
        }
    }, [isRemovedSuccess, isRemovedLoading, postIdDeleting]);

    useEffect(() => {
        if (postIdDeleting && isRemovedError && !isRemovedLoading) {
            setPostIdDeleting('');
        }
    }, [isRemovedError, isRemovedLoading, postIdDeleting]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        const hasScrollLastPublishedPost = isLastPublishedPost
            && !isLoadingChannel
            && !isFetchingMessages
            && channel
            && statusQueryChannel !== QueryStatus.pending;

        if (hasScrollLastPublishedPost) {
            timeout = setTimeout(() => {
                handleShowMessage(
                    channel.lastPublishedPostId,
                    channel.lastPublishedPostPageUrl,
                    EBehaviorScroll.AUTO,
                    true,
                );
            }, SHOW_LAST_PUBLISHED_POST_TIME);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isLastPublishedPost, isLoadingChannel, channel, statusQueryChannel, isFetchingMessages]);

    useEffect(() => {
        const hasScrollLastPublishedPost = isScrollMessage
            && !isLoadingChannel
            && !isFetchingMessages
            && channel
            && statusQueryChannel !== QueryStatus.pending;

        if (hasScrollLastPublishedPost) {
            handleShowMessage(channel.lastPublishedPostId, channel.lastPublishedPostPageUrl);
            setIsScrollMessage(false);
        }
    }, [isScrollMessage, isLoadingChannel, channel, statusQueryChannel, isFetchingMessages]);

    useEffect(() => {
        /* Ждем загрузку всех данных канала */
        if (isLoadingChangeChannel) {
            /* Если есть в url айди то показываем лоадер для редактирования */
            if (editPostId) {
                setIsLoadingEditModal(true);
            }
            return;
        }

        /* Если есть в url айди то открываем модальное окно */
        if (editPostId) {
            setOpenModalToChangeMessage(true);
            /* Проверяем, есть ли айдишник в сторадже */
            if (!messageIdsList.includes(editPostId)) {
                /* Если нет айдишника, то подгружаем сообщение */
                loadMessage(editPostId);
            }
            const currentPost = messageList[editPostId];

            /* Проверяем данные сообщения, если они есть, то добавляем данные в сторадж для редактирования */
            if (currentPost && (!editMessageData?.id || editMessageData?.id !== editPostId)) {
                handleEditMessage(currentPost);
            }

            /* Убираем скролл */
            setIsLoadingEditModal(false);
            return;
        }

        /* Убираем скролл */
        setIsLoadingEditModal(false);
        /* Закрываем модальное окно, если код выше не выполняется */
        setOpenModalToChangeMessage(false);
    }, [searchParams, isLoadingChangeChannel, editPostId, isCreatePost]);

    useEffect(() => {
        /* Ждем загрузку всех данных канала */
        if (isLoadingChangeChannel) {
            /* Если есть в url показатель создания сообщения то показываем лоадер для создания */
            if (isCreatePost) {
                setIsLoadingCreateModal(true);
            }
            return;
        }

        /* Если есть в url показатель создания сообщения то открываем модальное окно */
        if (isCreatePost) {
            /* Открываем модальное окно и убираем скролл */
            dispatch(channelActions.setOpenCreateMessageModal(true));
            setIsLoadingCreateModal(false);
            return;
        }

        /* Убираем скролл */
        setIsLoadingCreateModal(false);
        /* Закрываем модальное окно, если код выше не выполняется */
        dispatch(channelActions.setOpenCreateMessageModal(false));
    }, [isCreatePost, isLoadingChangeChannel]);

    return {
        channelName,
        handleClearLastPublishedLoad,
        handleEditMessage,
        handleOpenNotification,
        handleReloadChannels,
        handleRemoveMessage,
        isLoadingChangeChannel,
        isLoadingCreateOrEditModal,
        isNotifications,
        isOpenNotifications,
        isUpdateNotifications,
        onScrollMessage,
        openModalToChangeMessage,
        reloadMessages,
        setIsOpenNotifications,
        setIsUpdateChannels,
        setShouldAddMessages,
        setUpdateNotifications,
        shouldAddMessages,
        statusUpdateChannel,
        toggleCreateModal,
        toggleEditModal,
    };
};
