import {
    FC,
    Suspense,
} from 'react';

import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { Sidebar } from 'widgets/sidebar';

import { EditChannelPageContentAsync as EditChannelPageContent } from './EditChannelPage.async';

export const EditChannelPage: FC = () => {
    const { isTablet } = useScreenType();

    return (
        <MainLayouts Sidebar={isTablet ? null : <Sidebar />}>
            <Suspense fallback={<PageLoader isLoading withoutPortal />}>
                <EditChannelPageContent />
            </Suspense>
        </MainLayouts>
    );
};
