import {
    FC,
    Suspense,
} from 'react';

import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';

import { WebappAuthPageContentAsync as WebappAuthPageContent } from './WebappAuthPage.async';

interface IWebappAuthProps {
    className?: string;
}

export const WebappAuthPage: FC<IWebappAuthProps> = () => (
    <MainLayouts isAuthPage>
        <Suspense fallback={<PageLoader withoutBg isLoading withoutPortal />}>
            <WebappAuthPageContent />
        </Suspense>
    </MainLayouts>
);
