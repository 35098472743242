import {
    FC,
    memo,
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { getStateIsChannelEdition } from 'entities/user';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IChannel } from 'shared/types/channels';
import { Text } from 'shared/ui/text/Text';

import { ChannelItem } from './item/ChannelItem';
import { SkeletonChannel } from './skeleton/SkeletonChannel';

import cls from './ChannelsList.module.scss';

interface IChannelsListProps {
    className?: string;
    channels?: IChannel[];
    isLoading: boolean;
    isEmptyChannels?: boolean;
    isEmptySearch?:boolean;
    addNewNextChannels: (isBottom: boolean) => void;
    addPrevNextChannels: (isTop: boolean) => void;
    onScrollMessage?: () => void;
}

const CHANNEL_HEIGHT = 73;
const ANOTHER_BLOCK_HEIGHT = 190; // высота другие блоков
const ANOTHER_BLOCK_HEIGHT_MOBILE = 104; // высота других блоков - моблика

export const ChannelsList: FC<IChannelsListProps> = memo((props) => {
    const {
        addNewNextChannels,
        addPrevNextChannels,
        channels,
        className,
        isEmptyChannels,
        isEmptySearch,
        isLoading,
        onScrollMessage,
    } = props;

    const isChannelEditionRight = useSelector(getStateIsChannelEdition);
    const { isTablet } = useScreenType();

    const renderChannels = useCallback((index: number, channel: IChannel): JSX.Element => (
        <ChannelItem
            channel={channel}
            key={channel.id}
            index={index}
            onScrollMessage={onScrollMessage}
            isLoading={isLoading}
        />
    ), [channels, isLoading]);

    const countSkeletons: number[] = useMemo(
        () => {
            const anotherBlockHeight = isTablet
                ? ANOTHER_BLOCK_HEIGHT_MOBILE : ANOTHER_BLOCK_HEIGHT;
            const availableHeight = window.innerHeight - anotherBlockHeight;
            const countAvailableSkeletons = Math.max(Math.floor(availableHeight / CHANNEL_HEIGHT), 1);

            return Array(countAvailableSkeletons).fill(0);
        },
        [isTablet],
    );

    const emptyText = useMemo(() => {
        if (isEmptySearch) {
            return (
                <>
                    По вашему запросу
                    <br />
                    ничего не найдено
                </>
            );
        }

        if (!isChannelEditionRight) {
            return 'У вас нет доступных каналов';
        }

        return 'Добавьте ваш первый канал';
    }, [isEmptySearch, isChannelEditionRight]);

    const SkeletonComponent = useMemo(
        (() => countSkeletons.map((_, i) => (
            <SkeletonChannel key={`${i + 1}`} />
        ))
        ), [countSkeletons],
    );

    if (isLoading && channels?.length === 0) {
        return (
            <section className={cls.container}>
                {SkeletonComponent}
            </section>
        );
    }

    if (isEmptyChannels || isEmptySearch) {
        return (
            <div className={cls.emptyChannels}>
                <Text
                    className={cls.emptyChannelsTitle}
                    size="md"
                    text={emptyText}
                />
            </div>
        );
    }

    return (
        <section className={classNames(cls.container, { [cls.containerWithoutAddButton]: !isChannelEditionRight })}>
            <Virtuoso
                data={channels}
                className={classNames(cls.channelsList, {}, [className, 'scroll'])}
                itemContent={renderChannels}
                overscan={200}
                atTopStateChange={addPrevNextChannels}
                atBottomStateChange={addNewNextChannels}
            />
        </section>
    );
});
