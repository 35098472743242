import { memo } from 'react';
import { createPortal } from 'react-dom';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import { GoogleLoader } from '../loader/google/GoogleLoader';

import cls from './PageLoader.module.scss';

interface IPageLoaderProps {
    className?: string;
    classNameWithoutPortal?: string;
    classLoader?: string;
    isLoading: boolean;
    withoutPortal?: boolean;
    isAbsolute?: boolean;
    withoutBg?: boolean;
}

export const PageLoader = memo((props: IPageLoaderProps) => {
    const {
        classLoader, className, classNameWithoutPortal, isAbsolute,
        isLoading, withoutBg, withoutPortal,
    } = props;

    if (!isLoading) {
        return null;
    }

    if (withoutPortal || !document.getElementById('content')) {
        return (
            <div className={classNames(
                cls.pageLoaderWithoutPortal,
                { [cls.absolute]: isAbsolute, [cls.withoutBg]: withoutBg },
                [classNameWithoutPortal],
            )}
            >
                <GoogleLoader className={classNames(cls.loading, {}, [classLoader])} />
            </div>
        );
    }

    return createPortal(
        <div className={classNames(cls.pageLoader, {}, [className])}>
            <GoogleLoader className={cls.loading} />
        </div>,
        document.getElementById('content') as HTMLElement,
    );
});
