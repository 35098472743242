import {
    FC,
    memo,
} from 'react';
import { useSelector } from 'react-redux';

import { PreviewMessage } from 'widgets/message-preview';

import {
    getEditMessageData,
    getEditMessageDate,
    getEditMessagePostType,
    getEditMessageTime,
    getEditMessageTimezone,
    getEditMessageButtons,
    getEditMessageIsWebPreview,
    getEditMessageIsNotifyPin,
    getEditMessageIsPinIndefinitely,
    getEditMessagePinDuration,
    getEditMessageIsSent,
    getEditMessageText,
    getEditMessageMedia,
    getEditMessageDocuments,
    getEditMessageRoundVideo,
    getEditMessageVoice,
    getEditMessageAudio,
    getEditMessageVariables,
    getEditMessageAnswersPoll,
    getEditMessageMultipleChoicePoll,
    getEditMessageQuestionPoll,
    getEditMessageQuizPoll,
    getEditMessageSolutionIndexPoll,
    getEditMessageSolutionMessage,
    getEditMessagePost,
    getEditMessageAlbumType,
} from '../../model/selectors/edit-message.selectors';

export const PreviewMessageWrapper: FC = memo(() => {
    const timeMinutes = useSelector(getEditMessageTime);
    const date = useSelector(getEditMessageDate);
    const timezone = useSelector(getEditMessageTimezone);
    const message = useSelector(getEditMessageData);
    const postType = useSelector(getEditMessagePostType);
    const buttons = useSelector(getEditMessageButtons);
    const isWebPreview = useSelector(getEditMessageIsWebPreview);
    const isNotifyPin = useSelector(getEditMessageIsNotifyPin);
    const isPinIndefinitely = useSelector(getEditMessageIsPinIndefinitely);
    const pinDuration = useSelector(getEditMessagePinDuration);
    const isSent = useSelector(getEditMessageIsSent);
    const text = useSelector(getEditMessageText);
    const media = useSelector(getEditMessageMedia);
    const docs = useSelector(getEditMessageDocuments);
    const roundVideo = useSelector(getEditMessageRoundVideo);
    const voice = useSelector(getEditMessageVoice);
    const audio = useSelector(getEditMessageAudio);
    const variables = useSelector(getEditMessageVariables);
    const answersPoll = useSelector(getEditMessageAnswersPoll);
    const multipleChoicePoll = useSelector(getEditMessageMultipleChoicePoll);
    const questionPoll = useSelector(getEditMessageQuestionPoll);
    const quizPoll = useSelector(getEditMessageQuizPoll);
    const solutionIndexPoll = useSelector(getEditMessageSolutionIndexPoll);
    const solutionMessage = useSelector(getEditMessageSolutionMessage);
    const postFileData = useSelector(getEditMessagePost);
    const albumType = useSelector(getEditMessageAlbumType);

    return (
        <PreviewMessage
            timeMinutes={timeMinutes}
            date={date}
            timezone={timezone}
            message={message}
            postType={postType}
            buttons={buttons}
            isWebPreview={isWebPreview}
            isNotifyPin={isNotifyPin}
            isPinIndefinitely={isPinIndefinitely}
            pinDuration={pinDuration}
            isSent={isSent}
            text={text}
            media={media}
            docs={docs}
            roundVideo={roundVideo}
            voice={voice}
            audio={audio}
            variables={variables}
            answersPoll={answersPoll}
            multipleChoicePoll={multipleChoicePoll}
            questionPoll={questionPoll}
            quizPoll={quizPoll}
            solutionIndexPoll={solutionIndexPoll}
            solutionMessage={solutionMessage}
            postFileData={postFileData}
            albumType={albumType}
        />
    );
});
