import 'antd/dist/reset.css';
import './styles/index.scss';

import {
    FC,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import { IWebappInfo } from 'entities/auth';
import {
    getUserInit,
    useGetUserSettingsQuery,
    userActions,
    IUpdateUserState,
    IUserInfo,
} from 'entities/user';
import {
    AUTH_URL,
    HOME_URL,
} from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { isAuthURL } from 'shared/lib/utils/validUrl/validUrl';

import { AppRouter } from './providers/router';

const validUserData = (data: IUserInfo) : IUpdateUserState => {
    const adminModesRights = data?.role?.accessRights?.adminModes;
    const channelManagementRights = data?.role?.accessRights?.channelManagement;

    return {
        data: {
            channelCount: data.channelCount,
            disableSyncAlert: data.disableSyncAlert,
            firstName: data.firstName,
            hasRole: data.hasRole,
            id: data.id,
            lastName: data.lastName,
            photo: data.photo,
            role: data.role,
            settings: { disableSyncAlert: data.disableSyncAlert },
            userName: data.userName,
        },
        isChannelEdition: channelManagementRights?.channelSettingsEdition || false,
        isEditPost: channelManagementRights?.postEdition || false,
        isRoleAssignment: adminModesRights?.roleAssignment || false,
        isRoleEdition: adminModesRights?.roleEdition || false,
    };
};

const App: FC = () => {
    const { data: userData, error, isLoading } = useGetUserSettingsQuery();

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userInit = useSelector(getUserInit);

    useEffect(() => {
        if (isLoading) return;
        if ((!userData && !isAuthURL(location.pathname)) || error) {
            dispatch(userActions.setAuth({ isAuth: false }));

            if (!isAuthURL(location.pathname)) {
                navigate(AUTH_URL);
            }
            return;
        }
        if (userData) {
            const tg: IWebappInfo = window.Telegram.WebApp;
            tg?.ready();
            tg?.expand();
            const user = tg?.initDataUnsafe?.user;
            dispatch(userActions.setAuth({ ...validUserData(userData), isAuth: true, isWebappAuth: !!user }));

            if (isAuthURL(location.pathname)) {
                navigate(HOME_URL);
            }
        }
    }, [userData, isLoading, error]);

    useEffect(() => {
        const tg: IWebappInfo = window.Telegram.WebApp;
        const isWebapp = Object.keys(tg.initData || {}).length > 0;

        const setVh = () => {
            /**  TODO: исследовать вопрос, как мы можем отображать корректную высоту для
             * приложения webapp (так как сейчас из-за шапки телеги, отображается некорректная
             * высота, возможно попробовать что-то tg.viewportHeight - размерШапки px?
             * Но вопрос всегда ли она одинаковая? Или меняется от различного устройства?
            */

            const vh = isWebapp ? tg.viewportHeight * 0.01 : window.innerHeight * 0.01;
            const doc = document.documentElement;
            doc.style.setProperty('--vh', `${window.innerHeight}px`);
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVh();
        if (isWebapp) {
            tg.onEvent('viewportChanged', setVh);
        } else {
            window.addEventListener('load', setVh);
            window.addEventListener('resize', setVh);
            window.addEventListener('orientationchange', setVh);
        }

        return () => {
            if (isWebapp) {
                tg.offEvent('viewportChanged', setVh);
            } else {
                window.removeEventListener('load', setVh);
                window.removeEventListener('resize', setVh);
                window.removeEventListener('orientationchange', setVh);
            }
        };
    }, []);

    if (!userInit) {
        return null;
    }

    return (
        <div className="app app_light_theme">
            <AppRouter />
        </div>
    );
};

export default App;
