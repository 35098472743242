import { IStateSchema } from 'app/providers/store';

export const getMessagesShouldUpdate = (state: IStateSchema) => state.messages.messageUpdatingStatus;
export const getMessagesData = (state: IStateSchema) => state.messages;
export const getMessageIdsList = (state: IStateSchema) => state.messages.ids;
export const getScrollMessageId = (state: IStateSchema) => state.messages.scrollMessageId;
export const getIsAnimationScrollMessage = (state: IStateSchema) => state.messages.isAnimationScrollMessage;
export const getNextUrlMessage = (state: IStateSchema) => state.messages.nextUrlMessage;
export const getPreviousUrlMessage = (state: IStateSchema) => state.messages.previousUrlMessage;
export const getCacheAnimationSticker = (state: IStateSchema) => state.messages.cacheAnimationSticker;
export const getBehaviorScrollMessage = (state: IStateSchema) => state.messages.behaviorScrollMessage;
export const getIsOpenFromChannelList = (state: IStateSchema) => state.messages.openFromChannelList;
export const getDateSearch = (state: IStateSchema) => state.messages.dateSearch;
export const getShouldUpdateMessage = (state: IStateSchema) => state.messages.shouldUpdateMessage;
export const getErrorUpdateMessage = (state: IStateSchema) => state.messages.errorUpdateMessage;
export const getIsMessageListUpdate = (state: IStateSchema) => state.messages.isMessageListUpdate;
export const getPlayingFileHashId = (state: IStateSchema) => state.messages.playingFileHashId;
export const getIsDisabledScroll = (state: IStateSchema) => state.messages.isDisabledScroll;
export const getIndexStartMes = (state: IStateSchema) => state.messages.indexStartMes;
export const getMessageList = (state: IStateSchema) => state.messages.entities;
