// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fBOcMjvz{display:flex;flex-direction:column;row-gap:var(--p-1)}.jkpgGMsf{list-style:disc;margin-left:var(--p-2-5)}`, "",{"version":3,"sources":["webpack://./src/widgets/edit-message/ui/hint-type-post/HintTypePost.module.scss"],"names":[],"mappings":"AAAA,UACI,YAAA,CACA,qBAAA,CACA,kBAAA,CAGJ,UACI,eAAA,CACA,wBAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    row-gap: var(--p-1);\n}\n\n.list {\n    list-style: disc;\n    margin-left: var(--p-2-5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fBOcMjvz`,
	"list": `jkpgGMsf`
};
export default ___CSS_LOADER_EXPORT___;
