// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ml0GqtNN{width:560px;max-width:560px;background-color:var(--gray-7);height:100%;padding:0 40px 20px;overflow-y:auto}.rlBeYf2z{display:flex;align-items:center;height:72px;width:100%}.zOR3O3BE{height:calc(100% - 72px);display:flex;flex-direction:column;row-gap:var(--p-2-5);align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/widgets/message-preview/ui/preview/PreviewMessage.module.scss"],"names":[],"mappings":"AAEA,UACI,WAAA,CACA,eAAA,CACA,8BAAA,CACA,WAAA,CACA,mBAAA,CACA,eAAA,CAGJ,UACI,YAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CAGJ,UACI,wBAAA,CACA,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@use 'app/styles/mixins/mixins';\n\n.container {\n    width: 560px;\n    max-width: 560px;\n    background-color: var(--gray-7);\n    height: 100%;\n    padding: 0 40px 20px;\n    overflow-y: auto;\n}\n\n.holderTitle {\n    display: flex;\n    align-items: center;\n    height: 72px;\n    width: 100%;\n}\n\n.emptyContent {\n    height: calc(100% - 72px);\n    display: flex;\n    flex-direction: column;\n    row-gap: var(--p-2-5);\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ml0GqtNN`,
	"holderTitle": `rlBeYf2z`,
	"emptyContent": `zOR3O3BE`
};
export default ___CSS_LOADER_EXPORT___;
