import dayjs, { Dayjs } from 'dayjs';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { capitalizeFirstLetter } from '../string/string';
import { getUtcOffset } from '../timezones/timezones';

export const formatDate = (date: string | Dayjs, format: string, timezoneUser?: string, isOnlyTime?: boolean) => {
    let dateWithTimezone;

    if (timezoneUser && getUtcOffset(timezoneUser) === 0) {
        dateWithTimezone = dayjs(date).utcOffset(0).format(format);
    } else {
        dateWithTimezone = dayjs(date).tz(timezoneUser).format(format);
    }

    if (isOnlyTime) {
        return dateWithTimezone;
    }

    const dateWithLocale = dayjs(dateWithTimezone).locale('ru').format(format);
    return dateWithLocale;
};

export const getDayjsWithTimezonePlugin = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs;
};

export const getTimeMmSSFromNumber = (num: number) => {
    const seconds = Math.round(num);
    const date = new Date(seconds * 1000);
    return date.toLocaleTimeString('en-US', { minute: '2-digit', second: '2-digit' });
};

export const isDateToday = (date: Dayjs): boolean => {
    const now = dayjs();
    return now.isSame(date, 'day');
};

export const isDateTomorrow = (date: Dayjs): boolean => {
    const tomorrow = dayjs().add(1, 'day');
    return tomorrow.isSame(date, 'day');
};

export const isDateYesterday = (date: Dayjs): boolean => {
    const yesterday = dayjs().add(-1, 'day');
    return yesterday.isSame(date, 'day');
};

export const isDateThisYear = (date: Dayjs): boolean => {
    const now = dayjs();
    return date.year() === now.year();
};

export const formatForToday = (date: Dayjs, timezoneUser?: string) => formatDate(date, 'HH:mm', timezoneUser, true);

export const formatForYear = (date: Dayjs, timezoneUser?: string): string => {
    const fullDate = formatDate(date, 'DD MMMM YYYY', timezoneUser);
    return isDateThisYear(date) ? fullDate.replace(/(\d{2}) (\W*) \d{4}/, '$1 $2') : fullDate;
};

export const getNearestAbbreviatedDate = (dateString: string | Dayjs, timezoneUser?: string) => {
    if (!dateString) {
        return '';
    }
    getDayjsWithTimezonePlugin();

    const formattedDate = dayjs(dateString);

    return isDateToday(formattedDate)
        ? formatForToday(formattedDate, timezoneUser)
        : formatForYear(formattedDate, timezoneUser);
};

export const getNearestAbbreviatedAudioDate = (dateString: string | Dayjs, timezoneUser?: string) => {
    if (!dateString) {
        return '';
    }
    getDayjsWithTimezonePlugin();

    const formattedDate = dayjs(dateString);
    const time = formatForToday(formattedDate, timezoneUser);

    if (isDateToday(formattedDate)) {
        return `Сегодня, ${time}`;
    }

    if (isDateTomorrow(formattedDate)) {
        return `Завтра, ${time}`;
    }

    if (isDateYesterday(formattedDate)) {
        return `Вчера, ${time}`;
    }

    return `${formatForYear(formattedDate, timezoneUser)}, ${time}`;
};

export const capitalizeMonth = (dateString: string, separator: string = ' ') => {
    const parts = dateString.split(separator);
    parts[1] = capitalizeFirstLetter(parts[1]);
    return parts.join(separator);
};

export const validDate = (dateValue: dayjs.Dayjs | null) => {
    if (!dateValue) {
        return null;
    }
    return dayjs(dateValue).isValid() ? dateValue : null;
};

export const isDateAndTimeBeforeNow = (date: Dayjs, minutes: Dayjs, timezoneData: string) => {
    // Объединяем дату и время
    const combinedDateTime = date.hour(dayjs(minutes).hour()).minute(dayjs(minutes).minute());
    const now = dayjs().tz(timezoneData);
    // Сравниваем с текущим временем
    return combinedDateTime.isBefore(now);
};
