import { Options } from 'react-lottie';

import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IStateSchema } from 'app/providers/store';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { TMessage } from 'shared/types/message';

import {
    IMessagesSchema,
    TUpdatingMessageStatuses,
} from '../types/message.types';

function sortByPublishAtAndMessageId(messageOne: TMessage, messageTwo: TMessage) {
    const firstPublishAt = String(messageOne.publishAt);
    const secondPublishAt = String(messageTwo.publishAt);
    const res = firstPublishAt.localeCompare(secondPublishAt);

    if (res !== 0) {
        return res;
    }

    if (messageOne.donorMessageId && messageTwo.donorMessageId) {
        return messageOne.donorMessageId - messageTwo.donorMessageId;
    }

    const firstCreatedAt = String(messageOne.createdAt);
    const secondCreatedAt = String(messageTwo.createdAt);

    return firstCreatedAt.localeCompare(secondCreatedAt);
}

const messagesAdapter = createEntityAdapter<TMessage>({
    selectId: (message) => message.id,
    sortComparer: sortByPublishAtAndMessageId,
});

export const getMessagesFromSlice = messagesAdapter.getSelectors<IStateSchema>(
    (state) => state?.messages || messagesAdapter.getInitialState(),
);

export const messagesSlice = createSlice({
    initialState: messagesAdapter.getInitialState<IMessagesSchema>({
        behaviorScrollMessage: EBehaviorScroll.SMOOTH,
        cacheAnimationSticker: {},
        entities: {},
        errorUpdateMessage: false,
        ids: [],
        indexStartMes: 0,
        isAnimationScrollMessage: true,
        isDisabledScroll: false,
        isMessageListUpdate: false,
        messageUpdatingStatus: 'updated',
        nextUrlMessage: null,
        openFromChannelList: true,
        playingFileHashId: null,
        previousUrlMessage: null,
        scrollMessageId: null,
        shouldUpdateMessage: false,
    }),
    name: 'messages',
    reducers: {
        addMessage: messagesAdapter.addOne,
        addMessages(state, { payload: messages }: PayloadAction<TMessage[]>) {
            messagesAdapter.addMany(state, messages);
        },
        clearCacheAnimationSticker(state) {
            state.cacheAnimationSticker = {};
        },
        deleteMessage(state, { payload: messageId }: PayloadAction<TMessage['id']>) {
            messagesAdapter.removeOne(state, messageId);
        },
        setBehaviorScrollMessage(state, { payload: value }: PayloadAction<EBehaviorScroll>) {
            state.behaviorScrollMessage = value;
        },
        setIsAnimationScrollMessage(state, { payload: value }: PayloadAction<IMessagesSchema['isAnimationScrollMessage']>) {
            state.isAnimationScrollMessage = value;
        },
        setIsDisabledScroll(state, { payload: value }: PayloadAction<IMessagesSchema['isDisabledScroll']>) {
            state.isDisabledScroll = value;
        },
        setIsMessageListUpdate(state, { payload: value }: PayloadAction<IMessagesSchema['isMessageListUpdate'] | undefined>) {
            state.isMessageListUpdate = !!value;
        },
        setMessages(state, { payload: messages }: PayloadAction<TMessage[]>) {
            messagesAdapter.setAll(state, messages);
        },
        setMessagesImportingStatus(state, { payload: status }: PayloadAction<TUpdatingMessageStatuses>) {
            state.messageUpdatingStatus = status;
        },
        setScrollMessageId(state, { payload: id }: PayloadAction<IMessagesSchema['scrollMessageId']>) {
            state.scrollMessageId = id;
        },
        updateCacheAnimationSticker(state, { payload }: PayloadAction<{ name: string; file: Options }>) {
            const { file, name } = payload;
            state.cacheAnimationSticker = { ...state.cacheAnimationSticker, [name]: file };
        },
        updateDateSearch(state, { payload: date }: PayloadAction<IMessagesSchema['dateSearch']>) {
            state.dateSearch = date;
        },
        updateErrorUpdateMessage(state, { payload: value }: PayloadAction<IMessagesSchema['errorUpdateMessage'] | undefined>) {
            state.errorUpdateMessage = !!value;
        },
        updateIndexStartMes(state, { payload: index }: PayloadAction<IMessagesSchema['indexStartMes']>) {
            state.indexStartMes = index;
        },
        updateMessage: messagesAdapter.updateOne,
        updateNextUrlMessage(state, { payload: nextUrl }: PayloadAction<IMessagesSchema['nextUrlMessage']>) {
            state.nextUrlMessage = nextUrl;
        },
        updateOpenFromChannelList(state, { payload: isFromChannelList }: PayloadAction<IMessagesSchema['openFromChannelList']>) {
            state.openFromChannelList = isFromChannelList;
        },
        updatePlayingFileHashId(state, { payload: value }: PayloadAction<IMessagesSchema['playingFileHashId']>) {
            state.playingFileHashId = value;
        },
        updatePreviousUrlMessage(state, { payload: previousUrl }: PayloadAction<IMessagesSchema['previousUrlMessage']>) {
            state.previousUrlMessage = previousUrl;
        },
        updateShouldUpdateMessage(state, { payload: value }: PayloadAction<IMessagesSchema['shouldUpdateMessage'] | undefined>) {
            state.shouldUpdateMessage = !!value;
        },
    },
});

export const { reducer: messagesReducer } = messagesSlice;
export const { actions: messagesActions } = messagesSlice;
