import {
    FC,
    MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import PauseOutlined from '@ant-design/icons/PauseOutlined';
import {
    audioFileActions,
    getAudioFileIsPlaying,
    getAudioIsEnded,
    getAudioMessageId,
    getAudioTimeRemaining,
    getAudioWidthWave,
    getAudioСhangedTime,
} from 'features/global-audio-channel';
import FakeWave from 'shared/assets/icons/fakeWave.svg';
import RealWave from 'shared/assets/icons/realWave.svg';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useBrowserInfo from 'shared/lib/hooks/useBrowserInfo/useBrowserInfo';
import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getNearestAbbreviatedAudioDate,
    getTimeMmSSFromNumber,
} from 'shared/lib/utils/date/date';
import { CustomUploadFile } from 'shared/types/file';
import {
    IDefaultMessage,
    IFile,
} from 'shared/types/message';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import cls from './VoiceMessage.module.scss';

interface IVoiceMessageProps {
    className?: string;
    file: IFile | CustomUploadFile;
    messageId: string;
    date: IDefaultMessage['publishAt'];
    timezone?: string;
    isPreview?: boolean;
}

export const VoiceMessage: FC<IVoiceMessageProps> = (props) => {
    const dispatch = useAppDispatch();

    const {
        className,
        date,
        file,
        isPreview,
        messageId,
        timezone,
    } = props;

    const isPlaying = useSelector(getAudioFileIsPlaying);
    const globalAudioMessageId = useSelector(getAudioMessageId);
    const isEnded = useSelector(getAudioIsEnded);
    const timeRemaining = useSelector(getAudioTimeRemaining);
    const widthWave = useSelector(getAudioWidthWave);
    const changedTime = useSelector(getAudioСhangedTime);

    const { isIOS, isMacintosh } = useDeviceDetect();
    const { isSafari } = useBrowserInfo();

    const [duration, setDuration] = useState(0);
    const [isLoad, setIsLoad] = useState(true);

    const svgRef = useRef<HTMLButtonElement>(null);

    const voiceFile = file?.file || (file as CustomUploadFile)?.originFileObj;

    const isDisabled = useMemo(() => isIOS || (isMacintosh && isSafari), [isIOS, isMacintosh, isSafari]);
    const isPlayingCurrentAudio = useMemo(() => globalAudioMessageId === messageId, [globalAudioMessageId, messageId]);
    const widthWaveCurrentAudio = useMemo(() => (isPlayingCurrentAudio ? widthWave : 0), [isPlayingCurrentAudio, widthWave]);
    const timeRender = useMemo(() => {
        if (!voiceFile) {
            return 'Загрузка...';
        }

        if (isLoad || duration === 0 || !Number.isFinite(duration)) {
            return <Skeleton width="36px" height="11px" className={cls.skeleton} />;
        }

        if (!isPlayingCurrentAudio || isEnded || !timeRemaining) {
            return getTimeMmSSFromNumber(duration);
        }

        return timeRemaining;
    }, [
        isLoad,
        duration,
        isPlayingCurrentAudio,
        isEnded,
        timeRemaining,
        voiceFile,
    ]);

    const playPauseOrClockIcon = useMemo(() => {
        if (isPlaying && isPlayingCurrentAudio) {
            return <PauseOutlined className={cls.playPause} />;
        }

        return <CaretRightOutlined className={cls.playPause} />;
    }, [file, isPlaying, isPlayingCurrentAudio]);

    const classesBtn = classNames(cls.playBtn, {
        [cls.playing]: isPlayingCurrentAudio,
        [cls.withoutCursor]: !file?.file || isPreview,
    });

    const handleAddGlobalAudio = useCallback(() => {
        dispatch(audioFileActions.setChangedTime(null));
        dispatch(audioFileActions.setMessageId(messageId));
        dispatch(audioFileActions.setAudioFile(file));
        dispatch(audioFileActions.setIsEnded(false));
        dispatch(audioFileActions.setWidthWave(0));
        dispatch(audioFileActions.setIsClearIntervalWidth(true));
        dispatch(audioFileActions.updateName('Голосовое сообщение'));
        dispatch(audioFileActions.setTimeRemaining(''));
        dispatch(audioFileActions.setDate(getNearestAbbreviatedAudioDate(date || '', timezone)));
    }, [messageId, file, date, timezone]);

    const handleOnAudioClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        if (!file?.file || isPreview) {
            return;
        }

        e.stopPropagation();

        if (!messageId || messageId !== globalAudioMessageId) {
            handleAddGlobalAudio();
            return;
        }

        if (isEnded) {
            dispatch(audioFileActions.setIsEnded(false));
            dispatch(audioFileActions.setWidthWave(0));
        }

        dispatch(audioFileActions.togglePlayingAudio(!isPlaying));
    }, [
        messageId,
        globalAudioMessageId,
        file,
        isPlaying,
        isEnded,
        handleAddGlobalAudio,
        isPreview,
    ]);

    const handleSvgClick = useCallback((event: MouseEvent<SVGSVGElement>) => {
        if (!file?.file || isPreview) {
            return;
        }

        event.stopPropagation();

        const container = svgRef.current;
        if (!container || changedTime) return;

        const { left } = container.getBoundingClientRect();

        const xWithinBlock = event.clientX - left;
        const calculatedWidth = (xWithinBlock * 100) / 170;
        const calculatedCurrentTime = ((duration * calculatedWidth) / 100);

        if (!messageId || messageId !== globalAudioMessageId) {
            handleAddGlobalAudio();
        }

        dispatch(audioFileActions.setIsChangingWidthWave(true));
        dispatch(audioFileActions.setWidthWave(calculatedWidth));
        dispatch(audioFileActions.setChangedTime(calculatedCurrentTime));
    }, [
        duration,
        messageId,
        globalAudioMessageId,
        changedTime,
        handleAddGlobalAudio,
        isPreview,
    ]);

    useEffect(() => {
        let fileUrl = '';
        let audio: HTMLAudioElement;
        const fileObj = (file as CustomUploadFile).originFileObj;
        if (fileObj) {
            fileUrl = URL.createObjectURL(fileObj);
            audio = new Audio(fileUrl);
        } else {
            audio = new Audio(file.file);
        }

        const setAudioDuration = () => {
            setIsLoad(false);
            setDuration(audio.duration);
        };

        audio.addEventListener('loadedmetadata', setAudioDuration);
        audio.addEventListener('loadeddata', setAudioDuration);

        return () => {
            audio.removeEventListener('loadedmetadata', setAudioDuration);
            audio.removeEventListener('loadeddata', setAudioDuration);
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [file]);

    return (
        <div className={classNames(cls.voiceMessage, {}, [className])}>
            <button
                onClick={handleOnAudioClick}
                type="button"
                className={classesBtn}
                disabled={isDisabled}
            >
                {playPauseOrClockIcon}
            </button>

            <div className={cls.audioRoad}>
                <div className={cls.wrapperSvg}>
                    <button
                        type="button"
                        className={classNames(cls.realWave, { [cls.withoutCursor]: !file?.file || isPreview }, [])}
                        disabled={isDisabled}
                        ref={svgRef}
                    >
                        <RealWave onClick={handleSvgClick} />
                    </button>
                    <div style={{ width: `${widthWaveCurrentAudio}%` }} className={cls.fakeWave}>
                        <FakeWave />
                    </div>
                </div>
                <p className={cls.time}>
                    {timeRender}
                </p>
            </div>
        </div>
    );
};
