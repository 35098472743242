import {
    FC,
    ReactNode,
} from 'react';

import { IArgsContentViewer } from 'features/content-viewer';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { CustomUploadFile } from 'shared/types/file';
import {
    EMediaMessageTypes,
    IEditMessage,
    IPostMedia,
    TMessage,
} from 'shared/types/message';

import { HolderFileLoader } from '../holder-file-loader/HolderFileLoader';
import { AudioMessage } from '../variants/audio-message/AudioMessage';
import { DocumentMessage } from '../variants/document-message/DocumentMessage';
import { ImageMessage } from '../variants/image-message/ImageMessage';
import { VideoMessage } from '../variants/video-message/VideoMessage';

import cls from './Album.module.scss';

interface IAlbumItemProps {
    media: IPostMedia;
    index: number;
    array: IPostMedia[] | CustomUploadFile[];
    message: TMessage | IEditMessage;
    openPreviewVideoOrPhoto?: (props: IArgsContentViewer) => void;
    isPreview?: boolean;
    timezone?: string;
}

export const AlbumItem: FC<IAlbumItemProps> = (props) => {
    const {
        array,
        index,
        isPreview,
        media,
        message,
        openPreviewVideoOrPhoto,
        timezone,
    } = props;

    const { file, type } = media;

    const wrappedComponent = (jsx: ReactNode) => (
        <div className={classNames(cls.item, {}, [cls[`item-${index + 1}`]])} key={`${index} ${file.id}`}>
            {jsx}
        </div>
    );
    const isWebPreview = array.length === 1 && message.webPreviewLimitsBypassEnabled && message?.text;

    if (type === EMediaMessageTypes.VIDEO) {
        return wrappedComponent(
            <VideoMessage
                openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                isBigSizeVideo={array.length === 1 || array.length === 2}
                file={file}
                webPreviewClass={isWebPreview ? cls.webPreviewDisplay : ''}
                messageId={message.id}
            />,
        );
    }

    if (type === EMediaMessageTypes.PHOTO) {
        return wrappedComponent(
            <ImageMessage
                openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                file={file}
                webPreviewClass={isWebPreview ? cls.webPreviewDisplay : ''}
                isPreview={isPreview}
            />,
        );
    }

    if (type === EMediaMessageTypes.DOCUMENT) {
        if (isPreview) {
            return wrappedComponent(<DocumentMessage file={file} isPreview={isPreview} />);
        }

        return (
            <HolderFileLoader file={file}>
                {wrappedComponent(<DocumentMessage file={file} />)}
            </HolderFileLoader>
        );
    }

    if (type === EMediaMessageTypes.AUDIO) {
        return (
            wrappedComponent(
                <HolderFileLoader file={file} isPreview={isPreview}>
                    <div className={cls.wrapperAudio}>
                        <AudioMessage
                            file={file}
                            messageId={message.id}
                            date={message.publishAt}
                            timezone={timezone}
                            isPreview={isPreview}
                        />
                    </div>
                </HolderFileLoader>,
            )
        );
    }

    return null;
};
