import {
    useCallback,
    useState,
} from 'react';
import {
    useSelector,
} from 'react-redux';

import {
    messagesActions,
    getMessageIdsList,
    getPreviousUrlMessage,
    useLazyGetMessagesQuery,
} from 'entities/message';
import { DUMMY_FUNCTION } from 'shared/constants/function';
import {
    EBehaviorScroll,
} from 'shared/constants/scroll';
import {
    useAppDispatch,
} from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import {
    getQueryVariable,
} from 'shared/lib/utils/validUrl/validUrl';
import { IChannel } from 'shared/types/channels';
import { TMessage } from 'shared/types/message';

import { useThrottle } from '../useThrottle/useThrottle';

interface IGetMessagesArgs {
    channelId: IChannel['id'];
    query: string;
    messageId: TMessage['id'];
    behaviorScroll: EBehaviorScroll;
}

interface IUseScrollMessageProps {
    channelId: IChannel['id'];
    isAnimation?: boolean;
    handleToggleLoading?: (value: boolean) => void;
}

const TIME_THROTTLE_SCROLL_DOWN = 1200;

export const useScrollMessage = ({
    channelId,
    handleToggleLoading = DUMMY_FUNCTION,
    isAnimation = true,
}: IUseScrollMessageProps) => {
    const dispatch = useAppDispatch();
    const messageIdList = useSelector(getMessageIdsList);
    const previousUrlMessage = useSelector(getPreviousUrlMessage);

    const [getDataMessageList] = useLazyGetMessagesQuery();

    const [behaviorScrollState, setBehaviorScrollState] = useState<EBehaviorScroll>();
    const [isLoadMessage, setIsLoadMessage] = useState(false);

    const clearMessageState = useCallback(() => {
        dispatch(messagesActions.setScrollMessageId(null));
    }, []);

    const handleExistingMessage = useCallback((
        id: TMessage['id'],
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
    ) => {
        dispatch(messagesActions.setBehaviorScrollMessage(behaviorScrollState || behaviorScroll));
        dispatch(messagesActions.setIsAnimationScrollMessage(isAnimation));
        dispatch(messagesActions.setScrollMessageId(id));

        handleToggleLoading(false);
    }, [dispatch, isAnimation, handleToggleLoading]);

    const handleLoadDataMessageList = useCallback(async (params: IGetMessagesArgs, isFindLastMessage: boolean = false) => {
        try {
            const { data: dataMessageList } = await getDataMessageList({
                channelId,
                query: params.query || '',
            });

            if (dataMessageList && dataMessageList?.results && !isFindLastMessage) {
                dispatch(messagesActions.setMessages(dataMessageList.results));
                dispatch(messagesActions.updateNextUrlMessage(dataMessageList.next));
                dispatch(messagesActions.updatePreviousUrlMessage(dataMessageList.previous));

                const isMessageId = dataMessageList?.results.find((item) => item.id === params.messageId);

                if (isMessageId) {
                    handleExistingMessage(params.messageId, params.behaviorScroll);
                }
            }

            if (dataMessageList && dataMessageList?.results && isFindLastMessage) {
                dispatch(messagesActions.setMessages(dataMessageList.results));
                dispatch(messagesActions.updateNextUrlMessage(dataMessageList.next));
                dispatch(messagesActions.updatePreviousUrlMessage(dataMessageList.previous));

                const messageList = dataMessageList?.results || [];
                const lastMessageId = messageList?.[0]?.id;

                if (lastMessageId) {
                    handleExistingMessage(lastMessageId, params.behaviorScroll);
                }
            }

            handleToggleLoading(false);
            setIsLoadMessage(false);
        } catch (error) {
            handleToggleLoading(false);
            setIsLoadMessage(false);
        }
    }, [channelId]);

    const loadNewMessages = useCallback((
        id: TMessage['id'],
        page: string,
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
    ) => {
        const queryVariable = getQueryVariable(page.replace(/.*\?/gi, ''));
        const nextPage = `channel__id=${queryVariable.channel__id}&cursor=${queryVariable.cursor}`;

        setIsLoadMessage(true);
        dispatch(messagesActions.setIsMessageListUpdate(false));
        handleLoadDataMessageList({
            behaviorScroll,
            channelId,
            messageId: id,
            query: nextPage,
        });
        handleToggleLoading(true);
        setBehaviorScrollState(behaviorScroll);
    }, [channelId, handleToggleLoading]);

    const handleShowMessage = useCallback((
        id: TMessage['id'],
        page?: string,
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
        isShouldDownloadMessages: boolean = false,
    ) => {
        clearMessageState();
        dispatch(messagesActions.setIsDisabledScroll(true));

        const isMessageId = messageIdList.find((item) => item === id);
        const isMessageScroll = isMessageId && id;

        if (isMessageScroll && !isLoadMessage && !isShouldDownloadMessages) {
            handleExistingMessage(id, behaviorScroll);
            return;
        }

        if (page) {
            loadNewMessages(id, page, behaviorScroll);
        }
    }, [
        messageIdList,
        clearMessageState,
        handleExistingMessage,
        loadNewMessages,
        channelId,
        isLoadMessage,
    ]);

    const handleScrollDown = useThrottle(useCallback(async (
        behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH,
    ) => {
        await clearMessageState();

        if (previousUrlMessage) {
            handleLoadDataMessageList({
                behaviorScroll: EBehaviorScroll.SMOOTH,
                channelId,
                messageId: '',
                query: '',
            }, true);
            return;
        }

        if (messageIdList.length > 0) {
            const lastMessageId = messageIdList[messageIdList.length - 1];
            handleExistingMessage(lastMessageId?.toString(), behaviorScroll);
        }
    }, [
        channelId,
        messageIdList,
        previousUrlMessage,
        handleExistingMessage,
        clearMessageState,
    ]), TIME_THROTTLE_SCROLL_DOWN);

    return { handleScrollDown, handleShowMessage };
};
