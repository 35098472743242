import {
    FC,
    memo,
    MouseEvent,
    ReactNode,
    useCallback,
    useMemo,
} from 'react';

import {
    Dropdown,
    MenuProps,
} from 'antd';

import { IVariable } from 'shared/types/channels';
import { Text } from 'shared/ui/text/Text';

import cls from './VariablesDropdown.module.scss';

interface IVariablesDropdownProps {
    variables?: IVariable[];
    onCancel: () => void;
    isVisible: boolean;
    onClickVariable: (variable: IVariable) => void;
    children: ReactNode;
}

export const VariablesDropdown: FC<IVariablesDropdownProps> = memo((props) => {
    const {
        children, isVisible, onCancel, onClickVariable, variables,
    } = props;

    const handleVariableClick = useCallback((variable: IVariable) => (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClickVariable(variable);
        onCancel();
    }, [onClickVariable, onCancel]);

    const items: MenuProps['items'] = useMemo(() => {
        if (variables?.length === 0) {
            return [{
                disabled: true,
                key: 'empty',
                label: (
                    <button type="button" className={cls.item} disabled>
                        <Text text="Нет переменных" theme="black" className={cls.itemValue} />
                    </button>
                ),
            }];
        }

        return variables?.map((item, index) => ({
            key: index,
            label: (
                <button type="button" onClick={handleVariableClick(item)} className={cls.item}>
                    <Text text={item.value} theme="black" className={cls.itemValue} />
                    <Text text={item.name} className={cls.itemName} align="right" />
                </button>
            ),
        }));
    }, [variables, handleVariableClick]);

    return (
        <Dropdown
            open={isVisible}
            menu={{ items }}
            rootClassName={cls.dropdown}
            placement="bottomRight"
        >
            <div>{children}</div>
        </Dropdown>
    );
});
