// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JXQAQit8{width:30%;max-width:30%;color:var(--violet-primary)}._CKdEfDv{width:70%;max-width:70%}.q83PxGX_{width:352px;min-width:352px;max-width:352px;display:flex;column-gap:var(--p-1-5);padding:var(--p-1) var(--p-1-5);border:none;outline:none;background:none}.TzGqSRWG>ul{max-height:170px;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/entities/channels/ui/variables-dropdown/VariablesDropdown.module.scss"],"names":[],"mappings":"AAEA,UACI,SAAA,CACA,aAAA,CACA,2BAAA,CAGJ,UACI,SAAA,CACA,aAAA,CAGJ,UACI,WAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,uBAAA,CACA,+BAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CAIA,aACI,gBAAA,CACA,eAAA","sourcesContent":["@use 'app/styles/mixins/mixins';\n\n.itemName {\n    width: 30%;\n    max-width: 30%;\n    color: var(--violet-primary);\n}\n\n.itemValue {\n    width: 70%;\n    max-width: 70%;\n}\n\n.item {\n    width: 352px;\n    min-width: 352px;\n    max-width: 352px;\n    display: flex;\n    column-gap: var(--p-1-5);\n    padding: var(--p-1) var(--p-1-5);\n    border: none;\n    outline: none;\n    background: none;\n}\n\n.dropdown {\n    & > ul {\n        max-height: 170px;\n        overflow-y: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemName": `JXQAQit8`,
	"itemValue": `_CKdEfDv`,
	"item": `q83PxGX_`,
	"dropdown": `TzGqSRWG`
};
export default ___CSS_LOADER_EXPORT___;
