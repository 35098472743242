import { Dayjs } from 'dayjs';

import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {
    ETextMaxSymbolsLength,
    Node,
    deserialize,
} from 'features/rich-text-editor';
import { IVariable } from 'shared/types/channels';
import { IErrorsTabs } from 'shared/types/error';
import { CustomUploadFile } from 'shared/types/file';
import {
    IMessageButtonList,
    IPostPollAnswers,
} from 'shared/types/message';
import { ETabPostEdit } from 'shared/types/post';

import {
    ICreateMessageSchema,

    TMediaFile,
} from '../types/create-message.types';

const errorsInitial = {
    [ETabPostEdit.BUTTONS]: {
        noEntitiesVariable: false,
    },
    [ETabPostEdit.POLL]: {
        selectSolutionIndex: false,
    },
    [ETabPostEdit.PUBLICATION]: {
        maxCountSymbols: false,
        noEntitiesVariable: false,
    },
    [ETabPostEdit.ATTACHMENTS]: {},
};

const initialState: ICreateMessageSchema = {
    albumType: null,
    answers: [],
    audio: null,
    buttons: [],
    date: null,
    dateValidType: '',
    documents: null,
    errorsTabs: errorsInitial,
    isDirty: false,
    isNewAnswer: false,
    isNotifyPin: false,
    isPinIndefinitely: false,
    isWebPreview: false,
    maxSymbolsLength: ETextMaxSymbolsLength.LONG_LENGTH,
    media: null,
    multipleChoice: false,
    pinDuration: null,
    postType: null,
    question: '',
    quiz: false,
    roundVideo: null,
    solutionIndex: null,
    solutionMessage: '',
    text: deserialize(''),
    time: null,
    timezone: '',
    variables: [],
    voice: null,
};

export const createMessageSlice = createSlice({
    initialState,
    name: 'createMessage',
    reducers: {
        changeButton(state, { payload: button }: PayloadAction<IMessageButtonList[]>) {
            state.buttons = button;
            state.isDirty = true;
        },
        clearData(state) {
            state.date = null;
            state.documents = null;
            state.media = null;
            state.text = deserialize('');
            state.time = null;
            state.maxSymbolsLength = ETextMaxSymbolsLength.LONG_LENGTH;
            state.isDirty = false;
            state.buttons = [];
            state.roundVideo = null;
            state.voice = null;
            state.isNotifyPin = false;
            state.isPinIndefinitely = false;
            state.isWebPreview = false;
            state.pinDuration = null;
            state.audio = null;
            state.answers = [];
            state.multipleChoice = false;
            state.question = '';
            state.quiz = false;
            state.solutionIndex = null;
            state.solutionMessage = '';
            state.postType = null;
            state.albumType = null;
            state.errorsTabs = errorsInitial;
        },
        clearText(state) {
            state.text = deserialize('');
        },
        setAlbumType(state, { payload: albumType }: PayloadAction<ICreateMessageSchema['albumType']>) {
            state.albumType = albumType;
        },
        setAnswersPoll(state, { payload: value }: PayloadAction<IPostPollAnswers[]>) {
            state.answers = value;
        },
        setAudio(state, { payload: audio }: PayloadAction<TMediaFile | null>) {
            state.audio = audio;
            state.isDirty = true;
        },
        setData(state, { payload: data }: PayloadAction<Omit<ICreateMessageSchema, 'errorsTabs'>>) {
            state.date = data.date;
            state.documents = data.documents;
            state.media = data.media;
            state.text = data.text;
            state.time = data.time;
            state.timezone = data.timezone;
            state.variables = data.variables;
            state.buttons = data.buttons;
            state.maxSymbolsLength = data.maxSymbolsLength;
            state.pinDuration = data.pinDuration;
            state.isNotifyPin = data.isNotifyPin;
            state.isPinIndefinitely = data.isPinIndefinitely;
            state.isDirty = true;
            state.answers = data.answers;
            state.multipleChoice = data.multipleChoice;
            state.question = data.question;
            state.quiz = data.quiz;
            state.solutionIndex = data.solutionIndex;
            state.postType = data.postType || null;
            state.albumType = data.albumType;
            state.errorsTabs = errorsInitial;
        },
        setDate(state, { payload: date }: PayloadAction<Dayjs>) {
            state.date = date;
            state.isDirty = true;
        },
        setDocuments(state, { payload: documents }: PayloadAction<TMediaFile>) {
            state.documents = documents;
            state.isDirty = true;
        },
        setDurationPin(state, { payload: pinDuration }: PayloadAction<ICreateMessageSchema['pinDuration']>) {
            state.pinDuration = pinDuration;
            state.isPinIndefinitely = false;
            state.isDirty = true;
        },
        setErrorButtonTab(state, { payload: { key, value } }: PayloadAction<
        {
            key: keyof IErrorsTabs[ETabPostEdit.BUTTONS];
            value?: boolean;
        }>) {
            const tabErrors = state.errorsTabs[ETabPostEdit.BUTTONS] as Record<string, boolean>;
            if (key in tabErrors) {
                tabErrors[key] = !!value;
            }
        },
        setErrorPollTab(state, { payload: { key, value } }: PayloadAction<
        {
            key: keyof IErrorsTabs[ETabPostEdit.POLL];
            value?: boolean;
        }>) {
            const tabErrors = state.errorsTabs[ETabPostEdit.POLL] as Record<string, boolean>;
            if (key in tabErrors) {
                tabErrors[key] = !!value;
            }
        },
        setErrorPublicationTab(state, { payload: { key, value } }: PayloadAction<
        {
            key: keyof IErrorsTabs[ETabPostEdit.PUBLICATION];
            value?: boolean;
        }>) {
            const tabErrors = state.errorsTabs[ETabPostEdit.PUBLICATION] as Record<string, boolean>;
            if (key in tabErrors) {
                tabErrors[key] = !!value;
            }
        },
        setIsNewAnswer(state, { payload: value }: PayloadAction<ICreateMessageSchema['isNewAnswer']>) {
            state.isNewAnswer = value;
        },
        setIsNotifyPin(state, { payload: isNotify }: PayloadAction<ICreateMessageSchema['isNotifyPin']>) {
            state.isNotifyPin = isNotify;
            state.isDirty = true;
        },
        setIsPinIndefinitely(
            state,
            { payload: isPinIndefinitely }: PayloadAction<ICreateMessageSchema['isPinIndefinitely']>,
        ) {
            state.pinDuration = null;
            state.isPinIndefinitely = isPinIndefinitely;
            state.isDirty = true;
        },
        setMaxSymbolsLength(state, { payload: maxLength }: PayloadAction<ICreateMessageSchema['maxSymbolsLength']>) {
            state.maxSymbolsLength = maxLength;
        },
        setMedia(state, { payload: media }: PayloadAction<TMediaFile>) {
            state.media = media;
            state.isDirty = true;
        },
        setMultipleChoicePoll(state, { payload: value }: PayloadAction<ICreateMessageSchema['multipleChoice']>) {
            state.multipleChoice = value;
        },
        setPostType(state, { payload: postType }: PayloadAction<ICreateMessageSchema['postType']>) {
            state.postType = postType;
        },
        setQuestionPoll(state, { payload: value }: PayloadAction<ICreateMessageSchema['question']>) {
            state.question = value;
        },
        setQuizPoll(state, { payload: value }: PayloadAction<ICreateMessageSchema['quiz']>) {
            state.quiz = value;
        },
        setRoundVideo(state, { payload: roundVideo }: PayloadAction<CustomUploadFile | null>) {
            state.roundVideo = roundVideo;
            state.isDirty = true;
        },
        setSolutionIndexPoll(state, { payload: value }: PayloadAction<ICreateMessageSchema['solutionIndex']>) {
            state.solutionIndex = value;
        },
        setSolutionMessage(state, { payload: value }: PayloadAction<ICreateMessageSchema['solutionMessage']>) {
            state.solutionMessage = value;
        },
        setText(state, { payload: text }: PayloadAction<Node[]>) {
            state.text = text;
            state.isDirty = true;
        },
        setTime(state, { payload: time }: PayloadAction<Dayjs>) {
            state.time = time;
            state.isDirty = true;
        },
        setTimezone(state, { payload: timezone }: PayloadAction<ICreateMessageSchema['timezone']>) {
            state.timezone = timezone;
        },
        setValidDateType(state, { payload: dateValidType }: PayloadAction<ICreateMessageSchema['dateValidType']>) {
            state.dateValidType = dateValidType;
        },
        setVariables(state, { payload: variables }: PayloadAction<IVariable[]>) {
            state.variables = variables;
        },
        setVoice(state, { payload: voice }: PayloadAction<CustomUploadFile | null>) {
            state.voice = voice;
            state.isDirty = true;
        },
        setWebPreview(state, { payload: value }: PayloadAction<ICreateMessageSchema['isWebPreview']>) {
            state.isWebPreview = value;
            state.maxSymbolsLength = value ? ETextMaxSymbolsLength.LONG_LENGTH : ETextMaxSymbolsLength.SHORT_LENGTH;
        },
        unpinMessage(state) {
            state.pinDuration = null;
            state.isPinIndefinitely = false;
        },
    },
});

export const { reducer: createMessageReducer } = createMessageSlice;
export const { actions: createMessageActions } = createMessageSlice;
