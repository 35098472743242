import { IPostPollAnswers } from 'shared/types/message';

export const isDisablePoll = ({
    answersPoll,
    isPoll,
    questionPoll,
    quizPoll,
    solutionIndexPoll,
}: {
    isPoll: boolean;
    questionPoll: string;
    answersPoll: IPostPollAnswers[];
    quizPoll: boolean;
    solutionIndexPoll: number | null;
}) => {
    /* Кол-во ответов должно быть больше 2 */
    const answersNumberCorrect = answersPoll.length < 2;

    /* Каждый ответ должен быть заполнен  */
    const checkAnswerText = answersPoll.some((item: IPostPollAnswers) => !item.value.trim());

    // Если режим викторины, то обязательно должен быть ответ
    const checkQuizPollCorrect = quizPoll && typeof solutionIndexPoll !== 'number';

    return isPoll
        && (
            !questionPoll
            || answersNumberCorrect
            || checkAnswerText
            || checkQuizPollCorrect
        );
};
